import React, { useReducer, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { colors } from "../../common/colors";
import {
  checkFirstNameCases,
  getLocalPhoto,
  isValidZipCode,
} from "./ReferralsHelpers";
import ErrorScreen from "./ErrorScreen";
import Picklist from "../../common/Picklist";
const EmptyUserCircle = require("../../images/icons/user-circle.png");

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  background: linear-gradient(to bottom right, #98e2ae, #0681fc);
  padding-top: 20vh;
  justify-content: center;
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid blue;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = styled.button`
  padding: 10px 40px;
  margin: 30px;
  background-color: white;
  color: blue;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    border-radius: 5px;
    background-color: ${(props) =>
      props.invalid ? colors.warningred : colors.primary};
  }
`;

const Section = styled.section`
  text-align: center;
  padding: 20px;
`;

const Text = styled.span`
  display: block;
  font-size: ${(props) => props.fontSize || "1em"};
  margin-bottom: ${(props) => props.marginBottom || "20px"};
  color: ${colors.white};
`;
const BoldText = styled.span`
  display: block;
  font-size: ${(props) => props.fontSize || "1em"};
  margin-bottom: ${(props) => props.marginBottom || "20px"};
  color: ${colors.white};
  font-weight: 700;
`;
const InputLabel = styled.span`
  margin-bottom: 5px;
  color: ${colors.white};
  align-self: flex-start;
`;

const FadeInDiv = styled.div`
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  animation: ${({ fadeIn }) => (fadeIn ? fadeInAnimation : "")} 0.7s ease-in;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  align-self: center;
  justify-self: center;
  background-color: ${colors.white};
`;

const ButtonStyled = styled.button`
  padding: 10px 40px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.limegreen};
  color: white;
  color: ${({ fontColor }) => fontColor || colors.white};
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const ButtonNoBackground = styled.button`
  padding: 10px 40px;
  background: ${({ backgroundColor }) => backgroundColor || "none"};
  color: ${colors.white};
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const ECButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ECImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const YesNoButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
`;
const SummarySection = styled.section`
  text-align: left;
`;

const SummaryItem = styled.div`
  margin-bottom: 15px;
`;

const SummaryTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: ${colors.white};
  font-size: 22px;
`;

const SummaryText = styled.div`
  color: ${colors.white};
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  flex: 1;
  gap: 30px;
`;

const InputField = ({ label, value, onChange, invalid }) => (
  <InputFieldContainer invalid={invalid || false}>
    <InputLabel>{label}</InputLabel>
    <input type="text" value={value} onChange={onChange} />
  </InputFieldContainer>
);

const initialState = {
  step: 0,
  formData: {
    name: "",
    lastName: "",
    phone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    additionalComments: "",
  },
  showSpinner: false,
  invalidFields: {},
  referEC: true,
  networkError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "incrementStep":
      return { ...state, step: state.step + 1 };
    case "incrementStepTwice":
      return { ...state, step: state.step + 2 };
    case "decrementStep":
      if (state.step === 6 && !localStorage.getItem("ecFirstName")) {
        return { ...state, step: state.step - 2 };
      }
      return { ...state, step: state.step - 1 };
    case "setState":
      return { ...state, ...action.data };
    case "setFormData":
      return { ...state, formData: { ...state.formData, ...action.data } };
    case "setInvalidFields":
      return {
        ...state,
        invalidFields: { ...state.invalidFields, ...action.data },
      };
    case "setReferEC":
      return { ...state, referEC: action.data, step: state.step + 1 };
    default:
      return state;
  }
};

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const postReferral = async (pin, data, referEC) => {
  const url = `https://damp-ridge-21682.herokuapp.com/react-referral/refer-a-friend`;
  const payload = {
    firstName: data.name,
    lastName: data.lastName,
    phone: data.phone,
    email: data.email,
    addressLn1: data.addressLine1,
    addressLn2: data.addressLine2,
    city: data.city,
    state: data.state,
    zip: data.zipCode,
    additionalNotes: data.additionalComments,
    referEC,
  };
  try {
    const response = await axios.post(url, payload, {
      headers: {
        pin: pin,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting referral:", error);
    throw error;
  }
};

export default function NewReferralView({ dismissAction }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fadeIn, setFadeIn] = useState(false);
  const ecFirstName = localStorage.getItem("ecFirstName");
  const ecLastName = localStorage.getItem("ecLastName");
  const ecImageURL = localStorage.getItem("ecImageURL");
  useEffect(() => {
    setFadeIn(true);
  }, []);
  const errorHeader = "Referral Failed to Send";
  const errorBody =
    "It looks like we're experiencing connectivity issues - please try again later.";
  const handleInputChange = (field) => (e) => {
    let value = e.target.value;
    if (field === "email") {
      dispatch({
        type: "setInvalidFields",
        data: { email: !isValidEmail(value) },
      });
    } else if (field === "phone") {
      const cleaned = value.replace(/\D/g, "");
      if (cleaned.length <= 10) {
        let formatted = cleaned;
        if (cleaned.length > 3 && cleaned.length <= 6) {
          formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`;
        } else if (cleaned.length > 6) {
          formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
            3,
            6
          )}-${cleaned.slice(6, 10)}`;
        }
        dispatch({ type: "setFormData", data: { [field]: formatted } });
      }
      return;
    } else if (field === "zipCode") {
      value = value.replace(/[^\d-]/g, "");
      const parts = value.split("-");
      if (parts.length > 1) {
        value = `${parts[0].slice(0, 5)}-${parts
          .slice(1)
          .join("")
          .slice(0, 4)}`;
      } else {
        value = value.slice(0, 5);
      }
      dispatch({ type: "setFormData", data: { [field]: value } });
      dispatch({
        type: "setInvalidFields",
        data: { [field]: !isValidZipCode(value) },
      });
      return;
    } else if (field === "state") {
      dispatch({ type: "setFormData", data: { [field]: value } });
      dispatch({ type: "setInvalidFields", data: { [field]: value === "" } });
      return;
    }
    dispatch({ type: "setFormData", data: { [field]: value } });
    dispatch({ type: "setInvalidFields", data: { [field]: false } });
  };

  const handleNext = () => {
    const { step, formData } = state;

    if (step === 4) {
      if (!ecFirstName || !checkFirstNameCases(ecFirstName)) {
        dispatch({ type: "incrementStepTwice" });
      } else {
        dispatch({ type: "incrementStep" });
      }
      return;
    }

    const currentConfig = inputConfigurations[step];
    const newInvalidFields = {};
    let hasInvalidFields = false;

    currentConfig.forEach(({ field, required, component }) => {
      if (required) {
        if (field === "email" && !isValidEmail(formData[field])) {
          newInvalidFields[field] = true;
          hasInvalidFields = true;
        } else if (
          field === "phone" &&
          formData[field].replace(/\D/g, "").length < 10
        ) {
          newInvalidFields[field] = true;
          hasInvalidFields = true;
        } else if (component === "picklist" && formData[field] === "") {
          newInvalidFields[field] = true;
          hasInvalidFields = true;
        } else if (field === "zipCode" && !isValidZipCode(formData[field])) {
          newInvalidFields[field] = true;
          hasInvalidFields = true;
        } else if (!formData[field].trim()) {
          newInvalidFields[field] = true;
          hasInvalidFields = true;
        }
      }
    });

    if (hasInvalidFields) {
      dispatch({ type: "setInvalidFields", data: newInvalidFields });
    } else {
      dispatch({ type: "incrementStep" });
    }
  };

  const USER_PIN = localStorage.getItem("EverlightPIN");
  const API_PIN =
    USER_PIN && USER_PIN.startsWith("CS-") ? USER_PIN.substring(3) : USER_PIN;
  const handleCheckAvailability = async () => {
    dispatch({ type: "setState", data: { showSpinner: true } });

    try {
      if (API_PIN) {
        await postReferral(API_PIN, state.formData, state.referEC);
        dispatch({ type: "incrementStep" });
      }
    } catch (error) {
      console.error("Error checking availability:", error);
      dispatch({ type: "setState", data: { networkError: true } });
    } finally {
      dispatch({ type: "setState", data: { showSpinner: false } });
    }
  };

  const inputConfigurations = {
    1: [
      { label: "First Name*", field: "name", required: true },
      { label: "Last Name*", field: "lastName", required: true },
    ],
    2: [
      { label: "Phone*", field: "phone", required: true },
      { label: "Email*", field: "email", required: true },
    ],
    3: [
      { label: "Address Line 1*", field: "addressLine1", required: true },
      { label: "Address Line 2", field: "addressLine2", required: false },
      { label: "City/Town*", field: "city", required: true },
      {
        label: "State*",
        field: "state",
        required: true,
        component: "picklist",
        options: [
          { value: "", label: "Select a State" },
          { value: "Minnesota", label: "Minnesota" },
          { value: "Nebraska", label: "Nebraska" },
          { value: "Wisconsin", label: "Wisconsin" },
        ],
      },

      { label: "Zip/Post code*", field: "zipCode", required: true },
    ],
  };

  const renderCurrentStep = () => {
    const { step, formData, invalidFields, networkError } = state;

    if (networkError) {
      return (
        <FadeInDiv fadeIn={fadeIn}>
          <ErrorScreen
            header={errorHeader}
            body={errorBody}
            dismissAction={dismissAction}
          />
        </FadeInDiv>
      );
    }

    switch (step) {
      case 0:
        return (
          <FadeInDiv fadeIn={fadeIn}>
            <Section>
              <Text fontSize="2em">
                Welcome to the Everlight Solar Referral Program
              </Text>
              <Text>
                Refer a friend to Everlight Solar and make money for doing so!
              </Text>
              <Button onClick={() => dispatch({ type: "incrementStep" })}>
                Let's get started!
              </Button>
            </Section>
          </FadeInDiv>
        );
      case 1:
      case 2:
      case 3:
        return (
          <FadeInDiv fadeIn={fadeIn}>
            <Section>
              <Text fontSize="1.5em">
                {step === 1
                  ? "Who would you like to refer?"
                  : step === 2
                  ? "How can we contact them?"
                  : "Where do they live?"}
              </Text>
              {inputConfigurations[step].map(
                ({ label, field, component, options }) => {
                  if (component === "picklist") {
                    return (
                      <InputFieldContainer
                        key={field}
                        invalid={invalidFields[field]}
                      >
                        <InputLabel>{label}</InputLabel>
                        <Picklist
                          options={options}
                          value={formData[field]}
                          onChange={(value) =>
                            handleInputChange(field)({ target: { value } })
                          }
                          invalid={invalidFields[field]}
                        />
                      </InputFieldContainer>
                    );
                  }
                  return (
                    <InputField
                      key={field}
                      label={label}
                      value={formData[field]}
                      onChange={handleInputChange(field)}
                      invalid={invalidFields[field]}
                    />
                  );
                }
              )}
              <Button onClick={() => dispatch({ type: "decrementStep" })}>
                Back
              </Button>
              <Button onClick={handleNext}>Next</Button>
            </Section>
          </FadeInDiv>
        );
      case 4:
        return (
          <FadeInDiv fadeIn={fadeIn}>
            <Section>
              <Text fontSize="1.5em">Any additional comments?</Text>
              <InputField
                label="Any additional comments?"
                value={formData.additionalComments}
                onChange={handleInputChange("additionalComments")}
                invalid={false}
              />
              <Button onClick={() => dispatch({ type: "decrementStep" })}>
                Back
              </Button>
              <Button onClick={handleNext}>Next</Button>
            </Section>
          </FadeInDiv>
        );
      case 5:
        return (
          <FadeInDiv fadeIn={fadeIn}>
            <Section>
              <BoldText fontSize="2.5em" marginBottom="30px">
                Refer your energy consultant?
              </BoldText>
              <ECImageContainer>
                {ecImageURL ? (
                  <ProfileImage
                    src={ecImageURL}
                    alt="ConsultantURL"
                    loading="lazy"
                  />
                ) : ecFirstName ? (
                  <ProfileImage
                    src={
                      ecFirstName
                        ? getLocalPhoto({
                            firstName: ecFirstName,
                            lastName: ecLastName,
                          })
                        : EmptyUserCircle
                    }
                    alt="Consultant"
                  />
                ) : null}
              </ECImageContainer>
              <Text fontSize="1.5em" marginBottom="10px">
                {`${ecFirstName} ${ecLastName}`}
              </Text>
              <ECButtonsContainer>
                <ButtonStyled
                  onClick={() => dispatch({ type: "decrementStep" })}
                  backgroundColor={colors.gray}
                >
                  Back
                </ButtonStyled>
                <YesNoButtons>
                  <ButtonNoBackground
                    onClick={() => dispatch({ type: "setReferEC", data: true })}
                  >
                    No
                  </ButtonNoBackground>
                  <ButtonStyled
                    onClick={() => dispatch({ type: "setReferEC", data: true })}
                  >
                    Yes
                  </ButtonStyled>
                </YesNoButtons>
              </ECButtonsContainer>
            </Section>
          </FadeInDiv>
        );

      case 6:
        const addressParts = [
          formData.addressLine1,
          formData.addressLine2,
          formData.city,
          formData.state,
          formData.zipCode,
        ].filter(Boolean);

        const address = addressParts.join(", ");

        return (
          <FadeInDiv fadeIn={fadeIn}>
            <BoldText fontSize="2.5em" marginBottom="10px">
              Referral Summary
            </BoldText>
            <SummarySection>
              <SummaryItem>
                <SummaryTitle>Name:</SummaryTitle>
                <SummaryText>{`${formData.name} ${formData.lastName}`}</SummaryText>
              </SummaryItem>
              <SummaryItem>
                <SummaryTitle>Phone:</SummaryTitle>
                <SummaryText>{formData.phone}</SummaryText>
              </SummaryItem>
              <SummaryItem>
                <SummaryTitle>Email:</SummaryTitle>
                <SummaryText>{formData.email}</SummaryText>
              </SummaryItem>
              <SummaryItem>
                <SummaryTitle>Address:</SummaryTitle>
                <SummaryText>{address}</SummaryText>
              </SummaryItem>
              {formData.additionalComments && (
                <SummaryItem>
                  <SummaryTitle>Comments:</SummaryTitle>
                  <SummaryText>{formData.additionalComments}</SummaryText>
                </SummaryItem>
              )}
              <ButtonRow>
                <ButtonNoBackground
                  onClick={() => dispatch({ type: "decrementStep" })}
                >
                  Back
                </ButtonNoBackground>
                <ButtonStyled
                  backgroundColor={colors.white}
                  fontColor={colors.ctablue}
                  onClick={handleCheckAvailability}
                >
                  Check for availability
                </ButtonStyled>
              </ButtonRow>
            </SummarySection>
          </FadeInDiv>
        );
      case 7:
        return (
          <FadeInDiv fadeIn={fadeIn}>
            <Section>
              <BoldText fontSize="clamp(16px,6vw, 40px)">
                Your referral is on the way!
              </BoldText>
              <Text>Thank you for referring a friend to Everlight Solar.</Text>
              <Button
                onClick={() => {
                  dismissAction();
                }}
              >
                Done
              </Button>
            </Section>
          </FadeInDiv>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      {state.showSpinner ? <Spinner /> : renderCurrentStep()}
    </Container>
  );
}
