import React from 'react';
import { Link } from 'react-router-dom';
import './PopupHomeInsurance.css';
import UploadButton from './UploadButton';


const PopupHomeInsurance = (props) => {

    const toggleMessageTimer = () => { setTimeout(() => { props.toggle() }, 100) }

    return (
        <div className="PopupHomeInsurance overlay">
                <div className="Popup-content center-text">
                    <h4 className='bold'>Important!</h4>
                    <p>
                        Your utility company requires proof of insurance on your home to satisfy their interconnection requirements.
                    </p>
                    <div className='margin20px'></div>
                    <div className='uploadButtonOverlay'>
                        <div className="verticalDivide"></div>
                        <button className='blueText' onClick={props.toggle}>Cancel</button>
                        <button onChange={toggleMessageTimer} data-testid='insurance-upload-button-wrapper'>
                            <UploadButton text={'Continue'} sfData={props.sfData} setCompletedInsurance={props.setCompletedInsurance} updateSFdata={props.updateSFdata} />
                        </button>
                </div>
            </div>
        </div>
    );
}

export default PopupHomeInsurance;