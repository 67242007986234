import React from 'react';
import '../App.css';

import { formatMoney } from '../functions/FunctionsInGeneral';
import { barsGreen, apDefault, apBlank, apOptions, prepAP, prepAPYear } from '../functions/FunctionsEnergyProduction'

import BarChart from '../BarChart';
import TimeBar from '../TimeBar';
import InstallDateSurvey from '../InstallDateSurvey';
import HOASurveyForm from '../HOASurveyForm';
import AlertBanner from '../AlertBanner';


import { dateToEpochSec, epochNowSec, labels, empOptions, empOptionsDefault, empDefault, empOptionsMedium } from '../functions/FunctionsEnergyUsage'

const EnergyUsagePage = (props) => {
    return (
        <>
            <div className='chart-page-container'>
                {props.showInstallDateSurvey ? <InstallDateSurvey installData={props.sfData} setShowInstallDateSurvey={props.setShowInstallDateSurvey} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} /> : null}

                {/* {props.showHOASurvey ? <HOASurveyForm setShowHOASurvey={props.setShowHOASurvey} accountId={props.accountId} accountIdv3={props.accountId} updateSFdata={props.updateSFdata} sfData={props.sfData} /> : null} */}

                {props.alertData.text ? <AlertBanner alertData={props.alertData} setShowHOASurvey={props.setShowHOASurvey} setShowInstallDateSurvey={props.setShowInstallDateSurvey} updateSFdata={props.updateSFdata} /> : null}

                <h1 className="page-title">Your Home Energy Use (kWh)</h1>

                {
                props.empData.length != 0
                ?
                    (!props.showOverlay && !props.chartsAreUpdating)
                        ?
                            props.empData == props.empDefault
                            ?
                            <BarChart data={props.empDefault} options={empOptionsDefault} chartsAreUpdating={props.chartsAreUpdating} />
                            :
                            <BarChart data={props.empData} options={empOptions} chartsAreUpdating={props.chartsAreUpdating} />
                        :
                        <BarChart data={ props.empDataFlat } options={empOptionsMedium} chartsAreUpdating={props.chartsAreUpdating}/>
                :
                null
                }

                <TimeBar timeWindow={props.timeWindow} setTimeWindow={props.setTimeWindow} />
            </div>
            {
                props.empDataReady && !props.showOverlay
                    ?
                    null
                    :
                    <>
                        <div className="box-center bg-gray-200 pageOverlay" style={props.empDataReady ? { opacity: "1" } : { opacity: "0.9" }}>
                        </div>
                        <div className="box-center pageOverlay">
                            <div className="pageOverlayTextContainer">
                                <h4 className="opacity-100 pageOverlayText empNoDataText">
                                    {
                                        (props.sfData.monitoringDeviceId == null || props.sfData.monitoringDeviceId == "") && (props.sfData.siteAnalysisCompletionDate == null)
                                            ||
                                            (props.sfData.monitoringDeviceId != null && props.sfData.monitoringDeviceId != "") && (props.sfData.siteAnalysisCompletionDate != null) && !props.empDataReady
                                            &&
                                            (Math.abs(epochNowSec() - dateToEpochSec(new Date(props.sfData.siteAnalysisCompletionDate))) <= 86400)
                                            ?
                                            <>
                                                <div className="textBlue">
                                                    Your energy usage will show here soon after your Site Analysis!
                                                </div>
                                                <>
                                                    {
                                                        props.sfData.twentyFiveYearSavings
                                                            ?
                                                            <><br />Based on your past bills, your 25-year est. savings with Everlight: <br /> <strong className="greenText">{formatMoney(props.sfData.twentyFiveYearSavings)}</strong></>
                                                            :
                                                            null
                                                    }
                                                </>
                                            </>
                                            :
                                            (props.sfData.monitoringDeviceId != null && props.sfData.monitoringDeviceId != "") && (props.sfData.siteAnalysisCompletionDate != null) && !props.empDataReady
                                                &&
                                                ((Math.abs(epochNowSec() - dateToEpochSec(new Date(props.sfData.siteAnalysisCompletionDate))) > 86400))
                                                ||
                                                (props.sfData.monitoringDeviceId == null || props.sfData.monitoringDeviceId == "") && (props.sfData.siteAnalysisCompletionDate != null) // No device ID, but SA filled out? This is USER ERROR.
                                                ?
                                                <>
                                                    <div className="textBlue">
                                                        With an upgrade, your home energy use will show here!
                                                    </div>
                                                    <br />
                                                    Please reach out <a href='mailto:Everlight%20Solar%20Customer%20Success<everlightcustomersuccess+everlightappreppage@p-2iqgpkl93uw06bw6hwtioiotuvbi2mpblfv4uf6u2vwko38cmg.1u-11vnmuaq.usa552.case.salesforce.com>?subject=General%20Reach-out%20from%20the%20Everlight%20App&body=(Insert%20Inquiry%20Here)' className="blueLink">here</a> to choose the best upgrade for your home.
                                                </>
                                                :
                                                <>
                                                    <div className="textBlue">
                                                        Your energy usage will show here soon after your Site Analysis!
                                                    </div>
                                                    <>
                                                        {
                                                            props.sfData.twentyFiveYearSavings
                                                                ?
                                                                <><br />Based on your past bills, your 25-year est. savings with Everlight: <br /> <strong className="greenText">{formatMoney(props.sfData.twentyFiveYearSavings)}</strong></>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                </>

                                    }

                                </h4>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default EnergyUsagePage