import styled from "styled-components";
import { colors } from "../../common/colors";

const BarsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -2;
`;

const Bar = styled.div`
  width: 12%;
  background-color: ${colors.ctablue};
  opacity: ${(props) => (110 - props.height) / 100};
`;

const BackgroundBars = () => {
  const bars = [80, 83, 86, 89, 92, 95, 98];

  return (
    <BarsContainer>
      {bars.map((height, index) => (
        <Bar
          key={index}
          height={height}
          style={{
            height: `${height}vh`,
            marginTop: `${100 - height}vh`,
          }}
        />
      ))}
    </BarsContainer>
  );
};

export default BackgroundBars;
