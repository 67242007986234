import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { cssVar } from './functions/FunctionsInGeneral.js';

import { useWindowWidth } from './functions/useWindowWidth.js';


const TimeBarAP = (props) => {
  const m = useRef();
  const y = useRef();

const windowWidth = useWindowWidth()

  useEffect(() => {
    // console.log("- - - - - useEffect 26 - Production Chart Timebar UI Update-r")
    if (props.timeWindowAP == "month") {
      
        m.current.style.background = 'white';
        y.current.style.background = "";
      
    }
    else {
      
      m.current.style.background = "";
      y.current.style.background = 'white';
    
    }
    

  }, [props.timeWindowAP]);

  return (
    <div className="TimeBar center">

      <button ref={m} className="TimeBarItem" onClick={() => { props.setTimeWindowAP("month") }}>
        Month
      </button>

      <button ref={y} className="TimeBarItem" onClick={() => { props.setTimeWindowAP("year") }}>
        Year
      </button>
      { /*
        <div className="TimeBarItem box-center hover1">
          <div className="box-center"><p>{props.timeWindow}</p></div>
        </div>
      */ }

    </div>
  );
}

export default TimeBarAP;