import React, { useState } from "react";
import styled from "styled-components";
import EnergyUsagePage from "./EnergyUsagePage";
import EnergyProductionPage from "./EnergyProductionPage";
import HouseWhiteImage from "../images/icons/HouseWhite.svg";
import { colors } from "../common/colors";
const HouseGreenImage = require("../images/icons/HouseGreen.png");
const LightningWhiteImage = require("../images/icons/lightningWhite.png");
const LightningBlueImage = require("../images/icons/lightningBlue.png");

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 10vh;
`;

const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 3%;
  right: 20px;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
`;

const SwitchButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 13px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  width: 100px;
  background-color: ${colors.white};
  height: 45px;
  cursor: pointer;
  transition: background-color 0.4s;

  .cover {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    background-color: ${({ isProduction }) =>
      isProduction ? "#4CAF50" : colors.ctablue};
    border-radius: 10px;
    width: calc(50% - 3px);
    transition: transform 0.4s;
    transform: ${({ isProduction }) =>
      isProduction ? "translateX(100%)" : "translateX(0)"};
    z-index: 1;
    display: flex;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    width: 20%;
    z-index: 2;
    color: white;
    font-size: 16px;
  }
`;

const HouseWhite = styled.img`
  width: 25px;
  z-index: 2;
  align-self: center;
  justify-self: center;
`;

const HouseGreen = styled.img`
  width: 35px;
  z-index: 2;
  align-self: center;
  justify-self: center;
  padding-right: 3px;
`;

const Lightning = styled.img`
  width: 20px;
  z-index: 2;
  align-self: center;
  justify-self: center;
  margin-right: 3px;
`;

const ToggleText = styled.span`
  margin-top: 10px;
  font-size: 12px;
  color: #2196f3;
  font-weight: 700;
  position: absolute;
  top: 50px;
  cursor: pointer;
`;

const EnergyMonitoringPage = (props) => {
  const [isProductionPage, setIsProductionPage] = useState(true);

  return (
    <Container>
      <ToggleSwitchContainer>
        <SwitchButton
          isProduction={isProductionPage}
          onClick={() => setIsProductionPage(!isProductionPage)}
        >
          <div className="cover" />
          <Lightning
            src={isProductionPage ? LightningBlueImage : LightningWhiteImage}
            loading='lazy'
          />
          {isProductionPage ? (
            <HouseWhite src={HouseWhiteImage} alt="HouseWhite" loading='lazy'/>
          ) : (
            <HouseGreen src={HouseGreenImage} alt="HouseGreen" loading='lazy'/>
          )}
        </SwitchButton>
        <ToggleText onClick={() => setIsProductionPage(!isProductionPage)}>
          Switch to {isProductionPage ? "Usage" : "Production"}
        </ToggleText>
      </ToggleSwitchContainer>
      <ChartContainer>
        {isProductionPage ? (
          <EnergyProductionPage
            accountId={props.accountId}
            sfData={props.sfData}
            updateSFdata={props.updateSFdata}
            showOverlay={props.showOverlay}
            ecuId={props.ecuId}
            apData={props.apData}
            apDataYear={props.apDataYear}
            apDataTrimmed={props.apDataTrimmed}
            apDefault={props.apDefault}
            apBlank={props.apBlank}
            apCallFailure={props.apCallFailure}
            timeWindowAP={props.timeWindowAP}
            setTimeWindowAP={props.setTimeWindowAP}
            apSystemsYearOffset={props.apSystemsYearOffset}
            setApSystemsYearOffset={props.setApSystemsYearOffset}
            apOptions={props.apOptions}
            alertData={props.alertData}
            showInstallDateSurvey={props.showInstallDateSurvey}
            setShowInstallDateSurvey={props.setShowInstallDateSurvey}
            showHOASurvey={props.showHOASurvey}
            setShowHOASurvey={props.setShowHOASurvey}
            userIsOnMobilePhone={props.userIsOnMobilePhone}
            chartsAreUpdating={props.chartsAreUpdating}
          />
        ) : (
          <EnergyUsagePage
            accountId={props.accountId}
            sfData={props.sfData}
            updateSFdata={props.updateSFdata}
            showOverlay={props.showOverlay}
            manufactureId={props.manufactureId}
            empData={props.empData}
            empDataReady={props.empDataReady}
            empDefault={props.empDefault}
            timeWindow={props.timeWindow}
            setTimeWindow={props.setTimeWindow}
            alertData={props.alertData}
            showInstallDateSurvey={props.showInstallDateSurvey}
            setShowInstallDateSurvey={props.setShowInstallDateSurvey}
            showHOASurvey={props.showHOASurvey}
            setShowHOASurvey={props.setShowHOASurvey}
            chartsAreUpdating={props.chartsAreUpdating}
            empDataFlat={props.empDataFlat}
          />
        )}
      </ChartContainer>
    </Container>
  );
};

export default EnergyMonitoringPage;
