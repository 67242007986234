import React, { useEffect, useState } from "react";
import styled from "styled-components";
import iPhoneRightArrowGray from '../../images/icons/iPhoneRIghtArrowGray.svg';

const TicketContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    & > div:first-child {
        border-radius: 20px 20px 0 0;
    }
`

const BackButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(212,212,216);
    border-bottom: 1px solid rgb(243, 242, 249);
    cursor: pointer;
`

const CloseText = styled.p`
    width: 100%;
    text-align: center;
    margin: 10px 0;
`

const LeftArrow = styled.img`
    margin: 5px 10px;
    right: 0;
    width: 7px;
    filter: brightness(0);
    opacity: 0.3;
    transform: rotate(-180deg);
`

const DetailsContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
    background: rgb(212,212,216);
    border-radius: 20px;
    padding: 30px;

    p {
        text-align: left;
        margin-bottom: 20px;
    }

    & > p:last-child {
        margin-bottom: 0;
    }
`

const CreatedTicket = (props) => {
    const [ticketToBeShown, setTicketToBeShown] = useState(null);
    const [projectAddress, setProjectAddress] = useState(null);

    useEffect(() => {
        let ticket = null;
        if (props.supportTickets) {
            ticket = props.supportTickets[props.selectedTicket];
            setTicketToBeShown(ticket);
        }

        if (props.sfDataAll) {
            const opportunity = props.sfDataAll.find(opportunity => opportunity.opportunityId === ticket.Opportunity__c);
            setProjectAddress(opportunity.projectAddress);
        }
    }, [props.supportTickets, props.selectedTicket]);

    return (
        ticketToBeShown
            ? (
                <TicketContainer>
                    <BackButton onClick={() => props.setSelectedTicket(null)}>
                        <LeftArrow src={iPhoneRightArrowGray} loading='lazy'/>
                        <CloseText>Close</CloseText>
                    </BackButton>

                    <DetailsContainer>
                        <p>Project Address: {projectAddress}</p>
                        <p>Type of Issue: {ticketToBeShown.Type}</p>
                        <p>Ticket Description: {ticketToBeShown.Description_From_Customer__c}</p>
                    </DetailsContainer>
                </TicketContainer>
            )
            : null
    );
};

export default CreatedTicket;