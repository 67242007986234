import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import EverlightLogo from "../images/EverlightLogoBlack.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.99);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  opacity: 0;
  animation: ${fadeIn} 3s forwards;
`;

const PopupContainer = styled.div`
  background: white;
  padding: 4vw;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90vw;
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  justify-self: center;
  display: flex;
  flex: 1;
`;

const StyledLogo = styled.img`
  max-width: 40vh;
  max-height: 40vw;
  object-fit: contain;
  align-self: flex-end;
`;

const Title = styled.h1`
  font-size: 4vh;
`;

const Subtitle = styled.p`
  font-size: 2vh;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UpgradeButton = styled.a`
  background-color: #44dc72;
  color: white;
  font-weight: bold;
  padding: 2vw;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 2vh;
`;

const DismissButton = styled.button`
  background-color: transparent;
  color: grey;
  border: none;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 6vh;
  margin-top: 6vh;
`;

export default function UpgradePopup() {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    sessionStorage.setItem("upgradePopupShown", "true");
    setVisible(false);
  };

  return (
    <>
      {visible ? (
        <PopupOverlay>
          <PopupContainer id="popup-container">
            <LogoContainer>
              <StyledLogo src={EverlightLogo} alt="Everlight Logo" loading='lazy'/>
            </LogoContainer>
            <Title>Time to update your app</Title>
            <Subtitle>Critical Update Required</Subtitle>
            <ButtonContainer>
              <UpgradeButton href="https://play.google.com/store/apps/details?id=io.ionic.EverlightAndroidApp&hl=en_US&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                Update Now
              </UpgradeButton>
              <DismissButton onClick={handleClose}>
                remind me later
              </DismissButton>
            </ButtonContainer>
          </PopupContainer>
        </PopupOverlay>
      ) : null}
    </>
  );
}
