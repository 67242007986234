import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import '../App.css';
import CSRInfo from '../components/supportpage/CSRInfo'
import RequestForm from '../components/supportpage/RequestForm'
import TextMyEC from '../components/supportpage/TextMyEC';
import CreatedTicketView from '../components/supportpage/CreatedTicketView';
import ConfirmOverlay from '../components/supportpage/ConfirmOverlay';
import Loading from '../components/supportpage/Loading';
import { retrieveSupportTicketData } from '../functions/SupportTickets';

const SupportPanel = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
	background: rgb(243, 242, 249);
	z-index: 0;
  padding: 20px 40px 0 40px;

  @media screen and (max-width: 600px) {
    height: auto;
    padding-bottom: 90px;
  }
`

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TicketContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  min-height: 550px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-clip: padding-box;
  background: rgb(243, 242, 249);

  & > div:first-child {
      border-radius: 20px 20px 0 0;
  }

  &::after {
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      background: linear-gradient(to right, rgb(57, 194, 93), rgb(101, 184, 81));
      content: '';
      z-index: -1;
      border-radius: 23px;
  }
`

const Spacer = styled.div`
  height: 100px;
`

const SupportPage = (props) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [confirmOverlayActive, setConfirmOverlayActive] = useState(false);
  const [sendButtonActive, setSendButtonActive] = useState(false);
  const [loadingSupport, setLoadingSupport] = useState(false);
  const [supportTickets, setSupportTickets] = useState(null);

  useEffect(() => {
    retrieveSupportTicketData(props.accountId)
      .then((data) => {
        setSupportTickets(data);
        console.log('Support Tickets:', data);
      })
      .catch(() => {
        setSupportTickets([]);
      });
  }, []);

  const [issueType, setIssueType] = useState(() => {
    const formIssueType = localStorage.getItem(`formIssueType${props.selectedAddress}`);
    if (formIssueType) {
      return formIssueType;
    }
    else {
      return 'None';
    }
  });

  const [description, setDescription] = useState(() => {
    const formDescription = localStorage.getItem(`formDescription${props.selectedAddress}`);
    if (formDescription) {
      return formDescription;
    }
    else {
      return '';
    }
  });

  return (
    <SupportPanel>
      {
        confirmOverlayActive
          ? <ConfirmOverlay
            selectedAddress={props.selectedAddress}
            setConfirmOverlayActive={setConfirmOverlayActive}
            sfData={props.sfData}
            projectAddress={props.sfData.projectAddress}
            setIssueType={setIssueType}
            setDescription={setDescription}
            issueType={issueType}
            description={description}
            setLoadingSupport={setLoadingSupport}
            accountId={props.accountId}
            setSupportTickets={setSupportTickets}
          />
          : null
      }

      {supportTickets && !loadingSupport
      ? <ContentsContainer>
        <CSRInfo sfData={props.sfData} />

        <TicketContainer>

          {
            selectedTicket === null
              ? <RequestForm
                sfDataAll={props.sfDataAll}
                sfData={props.sfData}
                selectedAddress={props.selectedAddress}
                setSelectedAddress={props.setSelectedAddress}
                setSelectedTicket={setSelectedTicket}
                setConfirmOverlayActive={setConfirmOverlayActive}
                confirmOverlayActive={confirmOverlayActive}
                setSendButtonActive={setSendButtonActive}
                sendButtonActive={sendButtonActive}
                setIssueType={setIssueType}
                issueType={issueType}
                setDescription={setDescription}
                description={description}
                supportTickets={supportTickets}
              />
              : <CreatedTicketView
                sfDataAll={props.sfDataAll}
                selectedTicket={selectedTicket}
                setSelectedTicket={setSelectedTicket}
                supportTickets={supportTickets}
              />
          }

        </TicketContainer>

        {
          props.sfData.energyConsultantFirstName !== null || props.sfData.energyConsultantPhoneNumber !== null
            ? <TextMyEC sfData={props.sfData} />
            : <Spacer />
        }
      </ContentsContainer>
      : <Loading />
      }
    </SupportPanel>
  );
};

export default SupportPage;