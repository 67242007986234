import React from "react";
import styled from "styled-components";
import { postSupportTicket } from "../../functions/SupportTickets";

const ConfirmOverlayContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999999999;
    padding: 75px 0;
`

const DetailsContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
    background: rgb(212,212,216);
    border-radius: 20px;
    padding: 30px;

    p {
        text-align: left;
        margin-bottom: 20px;
    }

    & > p:last-child {
        margin-bottom: 0;
    }
`

const ConfirmButton = styled.div`
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(65,97,53);
    cursor: pointer;
    border-radius: 15px;
    color: white;
    font-size: 20px;
`


const ConfirmOverlay = (props) => {
    
    const handleConfirmedSubmit = () => {
        props.setLoadingSupport(true);
        props.setConfirmOverlayActive(false);
        postSupportTicket(props.accountId, props.sfData.opportunityId, props.issueType, props.description)
            .then((response) => {
                console.log('New Ticket:', response);
                if (response) {
                    props.setConfirmOverlayActive(false);
                    props.setIssueType('None');
                    props.setDescription('');
                    localStorage.removeItem(`formIssueType${props.selectedAddress}`);
                    localStorage.removeItem(`formDescription${props.selectedAddress}`);

                    props.setSupportTickets(response);
                    props.setLoadingSupport(false);
                }
                else {
                    props.setConfirmOverlayActive(false);
                    props.setLoadingSupport(false);

                    alert('There was an issue submitting your ticket... Please try again later, or call your dedicated CSR.');
                }
            })
            .catch((error) => {
                alert('There was an issue submitting your ticket... Please try again later, or call your dedicated CSR.');
                console.log('New Ticket Error: ' + error);
                props.setConfirmOverlayActive(false);
                props.setLoadingSupport(false);
            })
    };

    return (
        <ConfirmOverlayContainer>
            <DetailsContainer>
                <p>Project Address: {props.projectAddress}</p>
                <p>Type of Issue: {props.issueType}</p>
                <p>Ticket Description: {props.description}</p>
            </DetailsContainer>

            <ConfirmButton onClick={handleConfirmedSubmit}>
                Confirm
            </ConfirmButton>
        </ConfirmOverlayContainer>
    );
};

export default ConfirmOverlay;