import React from "react";
import styled from "styled-components";
import { colors } from "../../common/colors";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: ${colors.white};
  width: 100%;
`;

const ErrorHeader = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const ErrorBody = styled.p`
  font-size: 1.5em;
  text-align: center;
`;
const DismissButton = styled.button`
  display: flex;
  flex: 1;
  padding: 20px 30px;
  border-radius: 10px;
  background: ${colors.white};
  max-height: 40px;
  color: ${colors.ctablue};
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export default function ErrorScreen({ header, body, dismissAction }) {
  return (
    <ErrorContainer>
      <ErrorHeader>{header}</ErrorHeader>
      <ErrorBody>{body}</ErrorBody>
      <DismissButton onClick={() => dismissAction()}>Close</DismissButton>
    </ErrorContainer>
  );
}
