import React from "react";
import styled from "styled-components";
import customerSuccessRep from '../../images/customerSuccessRep.png';
import CirclePhone from '../../images/icons/CirclePhone.svg';

const CSRInfoContainer = styled.div`
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;

    @media only screen and (max-width: 400px) {
        padding: 20px 0 0 0;
    }
`;

const CSRInfoSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const RepImage = styled.img`
    width: 150px;
    border-radius: 100%;
    border: 1px solid darkgray;
    background-size: 100%
`;

const RepName = styled.h2`
    color: #000000;
    font-size: 30px;
    font-family: BlinkMacSystemFont, -apple-system;
	font-weight: 700;
    text-align: left;

    @media only screen and (max-width: 400px) {
        font-size: 20px;
    }
`;

const RepDescription = styled.p`
    color: #000000;
    font-size: 13px;
    font-family: BlinkMacSystemFont, -apple-system;
    font-weight: 500;
    text-align: left;

    @media only screen and (max-width: 400px) {
        font-size: 10px;
    }
`;

const CallButton = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgb(212,212,216);
    padding: 10px;
    border-radius: 10px;
    color: #000000;
    font-size: 20px;
    font-family: BlinkMacSystemFont, -apple-system;
    font-weight: 400;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    white-space: nowrap;

    @media only screen and (max-width: 400px) {
        font-size: 15px;
    }
`

const PhoneIcon = styled.img`
    width: 30px;
    margin-right: 10px;

    @media only screen and (max-width: 400px) {
        width: 25px;
    }
`;

const CSRInfo = (props) => {
    const phoneNumber = "tel:" + props.sfData.csrPhone;

    return (
        <CSRInfoContainer>
            <CSRInfoSection>
                <RepImage
                    src={
                        props.sfData.customerSuccessRepImageURL !== "/file-asset/FINALSupportREpOVal?v=1"
                        ? props.sfData.customerSuccessRepImageURL
                        : customerSuccessRep
                    }
                    loading='lazy'
                />
            </CSRInfoSection>

            <CSRInfoSection>
                <RepName>{props.sfData.csrFirstName ? props.sfData.csrFirstName : 'CSR First Name'}</RepName>
                <RepDescription>Your Dedicated Customer Success Representative</RepDescription>
                <CallButton
                    onClick={() => {
                        window.open(phoneNumber, "_blank");
                    }}
                >
                    <PhoneIcon src={CirclePhone} loading='lazy'/>
                    Call Now
                </CallButton>
            </CSRInfoSection>
        </CSRInfoContainer>
    );
};

export default CSRInfo;