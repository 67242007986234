import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../common/colors";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.99);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  opacity: 0;
  animation: ${fadeIn} 3s forwards;
`;

const PopupContainer = styled.div`
  border-radius: 10px;
  background-color:${colors.white}
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 85vw;
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -0.5vh;
  right: 1vw;
  border-radius: 30px;
  font-size: 2vh;
  cursor: pointer;
  color: black;
  padding: 5px;
  &:hover {
    color: red;
  }
`;

export default function MarketingPopup({ marketingURL, updatedDate }) {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    localStorage.setItem("marketingPopupSeenDate", updatedDate);
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <PopupOverlay>
      <PopupContainer id="popup-container">
        <CloseButton onClick={handleClose}>X</CloseButton>
        <ImageContainer>
          <StyledImage src={marketingURL} alt="Marketing Content" loading='lazy'/>
        </ImageContainer>
      </PopupContainer>
    </PopupOverlay>
  );
}
