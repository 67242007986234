import React, { useReducer, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { colors } from "../../common/colors";
import ErrorScreen from "./ErrorScreen";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 128, 0, 0.4), #34c75b);
`;

const Title = styled.h1`
  margin-top: 60px;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  color: ${colors.white};
`;

const FadeInDiv = styled.div`
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  animation: ${({ fadeIn }) => (fadeIn ? fadeInAnimation : "")} 0.7s ease-in;
`;

const QuestionOne = styled.div`
  padding: 60px;
  text-align: center;
  max-width: 100%;
`;

const QuestionTwo = styled.div`
  padding: 20px;
  text-align: center;
  max-width: 100%;
`;

const QuestionText = styled.p`
  font-size: 1.25rem;
  color: ${colors.white};
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.8);
  color: #34c75b;
  font-weight: 700;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  width: 65%;
  cursor: pointer;
  transform: ${({ collapse }) => (collapse ? "scale(0.01)" : "scale(1)")};
  opacity: ${({ collapse }) => (collapse ? 0 : 1)};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &:active {
    transform: scale(0.95);
  }
`;

const TextInput = styled.textarea`
  min-height: 300px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  resize: none;
  transform: ${({ collapse }) => (collapse ? "scale(0.01)" : "scale(1)")};
  opacity: ${({ collapse }) => (collapse ? 0 : 1)};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid green;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 50px 0;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SubmissionMessage = styled.div`
  padding: 20px;
  text-align: center;
  max-width: 100%;
`;

const initialState = {
  describeTextInput: "",
  showQuestionOne: true,
  showQuestionTwo: false,
  showSpinner: false,
  showSubmissionMessage: false,
  fadeIn: false,
  collapse: false,
  networkError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setState":
      return { ...state, ...action.data };
    default:
      return state;
  }
};
const POST_URL = `https://damp-ridge-21682.herokuapp.com/react-referral/preferred-customer`;
const storedContactID = localStorage.getItem("contactID");
export default function PreferredCustomerApplication({ dismissAction }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "setState", data: { fadeIn: true } });
  }, []);

  const handleYesButtonClick = () => {
    dispatch({
      type: "setState",
      data: { showQuestionOne: false, showQuestionTwo: true },
    });
  };

  const handleReviewNavigation = (url) => {
    window.open(url, "_blank");
  };

  const handleSubmit = async () => {
    dispatch({ type: "setState", data: { collapse: true } });
    setTimeout(async () => {
      dispatch({
        type: "setState",
        data: { showQuestionTwo: false, showSpinner: true },
      });

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(
          POST_URL,
          {
            customerExperience: state.describeTextInput,
          },
          {
            headers: {
              contactID: storedContactID,
            },
          }
        );

        dispatch({
          type: "setState",
          data: { showSpinner: false, showSubmissionMessage: true },
        });
      } catch (error) {
        dispatch({
          type: "setState",
          data: { showSpinner: false, networkError: true },
        });
      }
    }, 500);
  };

  const GOOGLE_REVIEW_URL =
    "https://www.google.com/search?q=everlight+solar+la+vista";

  return (
    <Container>
      {state.networkError ? (
        <ErrorScreen
          header="Submission Failed"
          body="There was an error submitting your application. Please try again later."
          dismissAction={dismissAction}
        />
      ) : (
        <>
          <Title>Apply to be a Preferred Customer</Title>

          {state.showQuestionOne && (
            <FadeInDiv fadeIn={state.fadeIn}>
              <QuestionOne>
                <QuestionText>
                  Have you given Everlight Solar a Google review?
                </QuestionText>
                <ButtonContainer>
                  <StyledButton onClick={handleYesButtonClick}>
                    Yes, I have.
                  </StyledButton>
                  <StyledButton
                    onClick={() => handleReviewNavigation(GOOGLE_REVIEW_URL)}
                  >
                    No, take me there!
                  </StyledButton>
                </ButtonContainer>
              </QuestionOne>
            </FadeInDiv>
          )}

          {state.showQuestionTwo && (
            <FadeInDiv fadeIn={state.showQuestionTwo}>
              <QuestionTwo>
                <QuestionText>
                  Please describe your experience with Everlight:
                </QuestionText>
                <TextInput
                  value={state.describeTextInput}
                  onChange={(e) =>
                    dispatch({
                      type: "setState",
                      data: { describeTextInput: e.target.value },
                    })
                  }
                  collapse={state.collapse}
                />
                <StyledButton onClick={handleSubmit} collapse={state.collapse}>
                  Submit
                </StyledButton>
              </QuestionTwo>
            </FadeInDiv>
          )}

          {state.showSpinner && (
            <FadeInDiv fadeIn={state.showSpinner}>
              <Spinner />
            </FadeInDiv>
          )}

          {state.showSubmissionMessage && (
            <FadeInDiv fadeIn={state.showSubmissionMessage}>
              <SubmissionMessage>
                <QuestionText>Your application has been submitted</QuestionText>
                <StyledButton onClick={dismissAction}>Woohoo!</StyledButton>
              </SubmissionMessage>
            </FadeInDiv>
          )}
        </>
      )}
    </Container>
  );
}
