import React from 'react';
import { Link } from 'react-router-dom';
import EverlightLogo from "./images/EverlightLogoBlack.png";
import EverlightLogoGray from "./images/EverlightLogoGray.png";
import EverlightLogoGreen from "./images/EverlightLogoGreen.png";
import BatteryGray from "./images/icons/EverlightBatteryGray.png";
import BatteryGreen from "./images/icons/EverlightBatteryGreen.png";
import BoltGray from "./images/icons/EverlightBoltGray.png";
import BoltGreen from "./images/icons/EverlightBoltGreen.png";
import DollarGray from "./images/icons/EverlightDollarGray.png";
import DollarGreen from "./images/icons/EverlightDollarGreen.png";
import HouseGray from "./images/icons/HouseGray.png";
import HouseGreen from "./images/icons/HouseGreen.png";
import PanelGray from "./images/icons/PanelGray.png";
import PanelGreen from "./images/icons/PanelGreen.png";
import WalletGray from '../src/images/icons/WalletGray.png';
import WalletGreen from '../src/images/icons/WalletGreen.png';
import './NavBar.css';
import styled from 'styled-components'

import { useWindowWidth } from './functions/useWindowWidth';


const colors = {
	"--navGray": "rgb(162, 162, 162)",
	"--navGreen": "rgb(133, 187, 101)",
}

const color = (name) => {
	return colors.name;
}

const NavBar = (props) =>
{
    const windowWidth = useWindowWidth();
  return(
        window.location.pathname != '/' && (windowWidth < 600 || props.userIsOnMobilePhone)
        ?
            <nav className="NavBar">
                    <Link to="/account">
                        <div className="NavItem hover1">
                            <img src={window.location.pathname != "/account" ? EverlightLogoGray : EverlightLogoGreen} loading='lazy' alt="Everlight Solar rising sun logo"/>
                            <p style={window.location.pathname != "/account" ? { color: color("--navGray")} : { color: color("--navGreen")}}>Account</p>
                        </div>
                    </Link>
                    {/* <Link to="/energy">
                        <div className="NavItem hover1">
                            <img src={window.location.pathname != "/energy" ? BoltGray : BoltGreen} alt="Lightning bolt"/>
                            <p style={window.location.pathname != "/energy" ? { color: color("--navGray")} : { color: color("--navGreen")}}>Energy Usage</p>
                        </div>
                    </Link>
                    <Link to="/solar">
                        <div className="NavItem hover1">
                            <img className='nav-img-house' src={window.location.pathname != "/solar" ? HouseGray : HouseGreen} alt="Sunny House"/>
                            <p style={window.location.pathname != "/solar" ? { color: color("--navGray")} : { color: color("--navGreen")}}>Solar Production</p>
                        </div>
                    </Link> */}
                    <Link to="/monitoring">
                        <div className="NavItem hover1">
                            <img className='nav-img-house' src={window.location.pathname != "/monitoring" ? HouseGray : HouseGreen} loading='lazy' alt="Sunny House"/>
                            <p style={window.location.pathname != "/monitoring" ? { color: color("--navGray")} : { color: color("--navGreen")}}>Monitoring</p>
                        </div>
                    </Link>
                    <Link to="/referrals">
                <div className="NavItem hover1">
                    <img className='nav-img-referrals' src={window.location.pathname != "/referrals" ? WalletGray : WalletGreen} loading='lazy' alt="Referrals" style={{ width: '33px', height: '33px', marginTop:'5px', marginBottom:'5px' }} />
                    <p style={window.location.pathname !== "/referrals" ? { color: 'var(--navGray)' } : { color: 'var(--navGreen)' }}>Rewards Wallet</p>
                </div>
            </Link>
                    <Link to="/savings">
                        <div className="NavItem hover1">
                            {
                                !props.commissionedDate
                                ?
                                <>
                                    <img src={window.location.pathname != "/savings" ? DollarGray : DollarGreen} loading='lazy' alt="Money Sign"/>
                                    <p style={window.location.pathname != "/savings" ? { color: color("--navGray")} : { color: color("--navGreen")}}>Savings</p>
                                </>
                                :
                                <>
                                    <img src={window.location.pathname != "/savings" ? PanelGray : PanelGreen} loading='lazy' alt="Panel"/>
                                    <p style={window.location.pathname != "/savings" ? { color: color("--navGray")} : { color: color("--navGreen")}}>System</p>
                                </>
                            }
                        </div>
                    </Link>
                <div className="navBarBackground"></div>
            </nav>
            :
            null
  );
}

export default NavBar;