import React from "react"
import map from '../images/map.png'
import moment from "moment";
import Shield from '../images/icons/shieldCheck.svg'
import BadgeIcon from '../images/icons/PlusGreen.svg';
import houseDefault from '../images/house copy.png'
import CircleChart from '../CircleChart';
import { safeRead, safeReadFull, formatMoney } from '../functions/FunctionsInGeneral';
import EverlightLogoGreen from "../images/EverlightLogoGreen.png";
import EverlightLogoGray from "../images/EverlightLogoGray.png";
import EverlightLogo from "../images/EverlightLogo.svg";
import TeslaBattery from '../images/TeslaBattery.png'
import styled from 'styled-components';
import ThreeButtons from "../ThreeButtons"
import EverlightBackground from '../images/EverlightSolarPanels.jpg';
import CompletedUpgradeBadge from "../CompletedUpgradeBadge";
import UpgradeTracker from "../UpgradeTracker";
import { useWindowWidth } from "../functions/useWindowWidth";
import SystemPageMapKit from "../SystemPageMapKit";
import TreesImage from '../images/icons/Solid Trees.svg'
import { colors } from '../common/colors';
const InfoIcon = require ("../images/icons/infoIcon.png")

const SystemPageContainer = styled.div`
  background: url(${EverlightBackground}) no-repeat center center fixed;
  background-size: cover;
  
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  h1 {
    text-align: center;
    font-weight: 600;
    padding-top: 15px;
    color: var(--navGray);
    font-size: 18px;
  }

  @media only screen and (max-width: 599px){
    background: none
    
  }

`

const SystemInfo = styled.section`
  position: relative;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
  height: 200px;
  margin-top:50px;

  .system-info-address {
    color: var(--pullTabLink);
    position: relative;
    top: -175px
  }
  .system-info-size {
    color: black;
    position: relative;
    top: -175px
  }
`

const LogoBackdrop = styled.img`
  width: 300px;
  margin-top: -60px;
  opacity: 0.3;
`

const BadgeContainer = styled.ul`
  width: fit-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-bottom: 2rem;
`
const TreesContainer=styled.div`
display:flex;
width:100%;
height:70px;
z-index:3;
flex-direction:column;
align-items:center;
justify-content:flex-end;
background: #74bd59;
border-radius:10px;`

const TitleText = styled.span`
font-weight:bold;
color:${colors.white};
font-size:20px;
`

const TitleContainer= styled.div`
display:flex;
flex-direction:row;
align-items:center;
gap:10px;
`
const SubtitleContainer = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
gap:10px;`
const Info = styled.img`
width:20px;
margin-bottom:3px;
`
const SubtitleText=styled.span`
font-weight:700;
color:${colors.white};
font-size:14px;`
const Trees = styled.img`
width: 35px;
height: 35px;
object-fit: contain;
filter: invert(1);
margin-bottom:5px;
`;  
const PerYear = styled.div`
display:flex;
flex-direction:column;
margin-bottom:5px;`
const PerYearText=styled.span`
font-weight:700;
color:${colors.white};
line-height: 15px;
font-size:15px;`
const EPAButton = styled.button`
flex-direction:row;
align-items:center;
justify-content:center;
display:flex;
gap:5px;`

const SystemPage = (props) => {



  const MOCK_UPGRADE_LIST = [
    {
      upgrade: 'Critter Guard',
      badge: Shield
    },
    {
      upgrade: 'Panel Upgrade',
      badge: BadgeIcon
    },
    {
      upgrade: 'Power Generator',
      badge: BadgeIcon
    },
    {
      upgrade: 'Tesla Battery',
      badge: BadgeIcon
    },
    {
      upgrade: 'Tesla Wall Charger',
      badge: BadgeIcon
    },



  ]

  const MOCK_UPGRADE_OPP_DATA = {
    upgradeStatus: 'Permitting & Engineering',
    upgradeDetails: `${props.sfData.numberOfPanels} panels, Critter Guard`,
    upgradeAddress: `${props.sfData.projectAddress}`
  }

  const windowWidth = useWindowWidth()

  function getTrees(kwh) {
    return Math.floor(kwh * 0.011551)
}
  return (
    <>
      {windowWidth > 600
        ?
        <div className='referIFrame savingsFrame'>
          <div className="savingsFrameTitle">My Everlight Solar Energy System</div>
          <div className='systemsHeader'>
            <div className='entireSavingsSectiion'>
              <div className="savingsSection">
                <>
                {props?.sfData?.twentyFiveYearSavings > 0 ? 
                <div className="topBar">
                  <div className="bigText">{formatMoney(props.sfData.twentyFiveYearSavings)}</div>
                  <div className="label">Est. 25-YEAR SAVINGS</div>
                  </div>
                      : 
                      <TreesContainer>
                      <TitleContainer>
                          <TitleText>System Offset {getTrees(props?.sfData?.firstYearEstimatedProductionKwh)}</TitleText>
                          <Trees src = {TreesImage} alt = "trees" loading='lazy'/>
                          <PerYear>
                              <PerYearText>Per</PerYearText>
                              <PerYearText>Year</PerYearText>
                          </PerYear>
                      </TitleContainer>
                      <SubtitleContainer>
                      <EPAButton as="a" href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator" target="_blank" rel="noopener noreferrer">
                          <Info src={InfoIcon} alt="info" loading='lazy'/>
                          <SubtitleText>EPA.gov</SubtitleText>
                       </EPAButton>
                  </SubtitleContainer>
                  </TreesContainer>
                  }
                  </>
                
                <div className="margin10px"></div>
                <div className="chartSection">
                  <div className="leftText">Est.<br/>Minimum</div>
                  <div className="circleChart"><CircleChart ratio={safeRead(props.sfData.estimatedOffset, 0.95)} userIsOnMobilePhone={props.userIsOnMobilePhone}/></div>
                  <p>
                    {
                      props.sfData.estimatedOffset == null
                        ?
                        95
                        :
                        parseFloat(safeRead(props.sfData.estimatedOffset, 0.95)).toFixed(2) * 100
                    }
                    %
                  </p>
                  <div className="rightText">Solar Offset</div>
                </div>
              </div>

              <div className='systemInfoSection'>
                <p class="systeminfoaddress">{props.sfData.projectAddress}</p>
                <p class="systeminfosize">System Size: {props.sfData.projectDCsystemSize}</p>
                <div class="systeminfo">
                  <div class='systeminfopanels'><p>Panels: {props.sfData.numberOfPanels}</p>
                    <p>Inverters: {props.sfData.numberOfInverters}</p>
                  </div>
                  <p>Commissioned Date: {moment(props.sfData.commissionedDate).format("MMM D, YYYY")}</p>
                  <p>Registered To: {props.sfData.accountLastName}</p>
                </div>

                <div className="systemsPagePrimary">Primary: {props.sfData.primaryCustomerContact}</div>
                <div className="systemsPageContact">Contact: {props.sfData.nonPrimaryCustomerContact}</div>
              </div>
            </div>

            <div className= {props.pendingUpgrades.length > 0 ? 'trackerSection' : 'statusSection'}>
            {props.pendingUpgrades.length > 0 ? (
            <UpgradeTracker pendingUpgrades={props.pendingUpgrades} sfData={props.sfData}/>
          ) : (
            <>
              {props.sfData.energyProductionDeviceId == null || (props.sfData.energyProductionDeviceId != null && props.apCallFailure) ? (
                <>
                  <img src={EverlightLogoGray} loading='lazy'/>
                  <div>
                    <div className='systemStatus'>Status: <br/><div style={{ color: 'gray' }}>Offline</div></div>
                    <div>Updated: {moment().format("MM/DD/YY, h:mm A")}</div>
                  </div>
                </>
              ) : (
                <>
                  <img src={EverlightLogoGreen} loading='lazy'/>
                  <div>
                    <div className='systemStatus'>Status: <br/><div>Online</div></div>
                    <div>Updated: {moment().format("MM/DD/YY, h:mm A")}</div>
                  </div>
                </>
              )}
            </>
          )}

            {/* {props.pendingUpgrades.length > 0 ? null :
              <div>Updated: {moment().format("MM/DD/YY, h:mm A")}</div>
            } */}
            </div>
          </div>
          <div className="secondSection">
            <img 
                src={props.sfData.currentDesign3DRenderUrl ? props.sfData.currentDesign3DRenderUrl : EverlightLogo}
                className='savingsHouse'
                loading='lazy'
            />

            <div className='mapPlaceholder'>
              <SystemPageMapKit
                verifiedProjectLatitude={props.sfData.verifiedProjectLatitude}
                verifiedProjectLongitude={props.sfData.verifiedProjectLongitude}
                projectAddress={props.sfData.projectAddress}
                projectState={props.sfData.projectState}
              />
            </div>

          </div>

        </div>
        :

        <SystemPageContainer>
      <SystemPageMapKit
        verifiedProjectLatitude={props.sfData.verifiedProjectLatitude}
        verifiedProjectLongitude={props.sfData.verifiedProjectLongitude}
        projectAddress={props.sfData.projectAddress}
        projectState={props.sfData.projectState}
      />
      <div className={props.pendingUpgrades.length > 0 ? "trackerMobileView": "systemMobileView"}>
      <h1>Your Everlight Solar System</h1>

      <div className='statusSection'>
      {props.pendingUpgrades.length > 0 ? (
            <UpgradeTracker pendingUpgrades={props.pendingUpgrades} sfData={props.sfData}/>
          ) : (
            <>
              {props.sfData.energyProductionDeviceId == null || (props.sfData.energyProductionDeviceId != null && props.apCallFailure) ? (
                <>
                  <img src={EverlightLogoGray} loading='lazy'/>
                  <div>
                    <div className='systemStatus'>System Status: <br/><div style={{ color: 'gray' }}>Offline</div></div>
                    <div>Updated: {moment().format("MM/DD/YY, h:mm A")}</div>
                  </div>
                </>
              ) : (
                <>
                  <img src={EverlightLogoGreen} loading='lazy'/>
                  <div>
                    <div className='systemStatus'>System Status: <br/><div>Online</div></div>
                    <div>Updated: {moment().format("MM/DD/YY, h:mm A")}</div>
                  </div>
                </>
              )}
            </>
          )}

              
            </div>

            <div className='systemInfoSectionMobile'>
                <p class="systeminfoaddressMobile">{props.sfData.projectAddress}</p>
                <p class="systeminfosize">System Size: {props.sfData.projectDCsystemSize}</p>
                <div class="systeminfo">
                  <div class='systeminfopanels'><p>Panels: {props.sfData.numberOfPanels}</p>
                    <p>Inverters: {props.sfData.numberOfInverters}</p>
                  </div>
                  <p>Commissioned Date: {moment(props.sfData.commissionedDate).format("MMM D, YYYY")}</p>
                  <p>Registered To: {props.sfData.accountLastName}</p>
                </div>
              </div>
 </div>
    </SystemPageContainer>
      }</>


  )
}

export default SystemPage