import axios from "axios";

const EmptyUserCircle = require("../../images/icons/user-circle.png");
export const getLocalPhoto = ({ firstName, lastName }) => {
  let result = EmptyUserCircle;
  if (!firstName) return result;
  else {
    if (firstName.includes("Aaron")) {
      result = require("../../images/ECLocalPhotos/AaronGodwin.jpeg");
    } else if (firstName.includes("Graham")) {
      result = require("../../images/ECLocalPhotos/GrahamGunderson.jpeg");
    } else if (firstName.includes("Hayden")) {
      result = require("../../images/ECLocalPhotos/HaydenLacy.jpeg");
    } else if (firstName.includes("Tommy") || firstName.includes("HB")) {
      result = require("../../images/ECLocalPhotos/TommyFagen.jpeg");
    } else if (firstName.includes("Jacob") || firstName.includes("Jake")) {
      if (lastName && lastName.includes("Baker")) {
        result = require("../../images/ECLocalPhotos/JacobBaker.jpeg");
      } else {
        result = require("../../images/ECLocalPhotos/JacobWoods.jpeg");
      }
    } else if (firstName.includes("Kevin")) {
      result = require("../../images/ECLocalPhotos/KevinKvern.jpeg");
    } else if (firstName.includes("Kyle")) {
      result = require("../../images/ECLocalPhotos/KyleCollins.jpeg");
    } else if (firstName.includes("Mark")) {
      result = require("../../images/ECLocalPhotos/MarkNelson.jpeg");
    } else if (firstName.includes("Max")) {
      result = require("../../images/ECLocalPhotos/MaxNelson.jpeg");
    } else if (firstName.includes("Nicholas") || firstName === "Nic") {
      result = require("../../images/ECLocalPhotos/NicholasSemaan.jpeg");
    } else if (firstName === "Nick") {
      result = require("../../images/ECLocalPhotos/NickCarnagey.jpeg");
    }
    return result;
  }
};

export const checkFirstNameCases = (firstName) => {
  const cases = [
    "Aaron",
    "Graham",
    "Hayden",
    "Tommy",
    "HB",
    "Jacob",
    "Jake",
    "Kevin",
    "Kyle",
    "Mark",
    "Max",
    "Nicholas",
    "Nic",
    "Nick",
  ];
  return cases.some((nameCase) => firstName.includes(nameCase));
};

export const fetchContactsData = async (CONTACTS_URL, storedContactID) => {
  try {
    const response = await axios.get(CONTACTS_URL, {
      headers: {
        contactID: storedContactID,
      },
    });
    return response?.data[0];
  } catch (error) {
    console.error("Error fetching contacts:", error);
    throw error;
  }
};

export const fetchPreferredCustomerStatus = async (
  PREFERRED_CUSTOMER_URL,
  storedContactID
) => {
  try {
    const response = await axios.get(PREFERRED_CUSTOMER_URL, {
      headers: {
        contactID: storedContactID,
      },
    });
    return response.data.status;
  } catch (error) {
    console.error("Error fetching preferred customer status:", error);
    throw error;
  }
};

export const fetchRewards = async (API_PIN) => {
  const API_URL = `https://damp-ridge-21682.herokuapp.com/react-referral/referrals`;
  try {
    const response = await axios.get(API_URL, {
      headers: {
        pin: API_PIN,
      },
    });
    const rewardsData = response.data.map((reward, index) => ({
      ...reward,
      delay: 0.1 * index,
      referralStatus:
        reward.referralStatus === "Payment Sent"
          ? "On the way"
          : reward.referralStatus === "Payment Received"
          ? "Paid"
          : reward.referralStatus,
    }));

    const cashOnTheWay = rewardsData
      .filter((reward) => reward.referralStatus === "On the way")
      .reduce((sum, reward) => {
        const amount = parseFloat(reward.rewardAmount.replace(/[$,]/g, ""));
        return sum + (isNaN(amount) ? 0 : amount);
      }, 0);

    return { rewardsData, cashOnTheWay };
  } catch (error) {
    console.error("Error fetching rewards:", error);
    throw error;
  }
};

export const isValidZipCode = (zipCode) => {
  const zipCodeRegex = /^\d{5}(-\d{4})?$/;
  return zipCodeRegex.test(zipCode);
};
