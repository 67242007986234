import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import './SavingsPage.css';
import CircleChart from '../CircleChart';
import StageSelector from '../StageSelector';
import { act } from 'react-dom/test-utils';
import InstallDateSurvey from '../InstallDateSurvey';
import HOASurveyForm from '../HOASurveyForm';
import ThreeButtons from '../ThreeButtons';
import ThreeButtonsOnlyOne from '../ThreeButtonsOnlyOne';
import EverlightLogo from "../images/EverlightLogoBlack.png";
import UploadButton from '../UploadButton';
import AlertBanner from '../AlertBanner';
import GreenCheck from '../images/icons/GreenCheckBadge.png';
import Milestone from '../Milestone';
import { determineStageTitle, computeCurrentPhase } from '../functions/FunctionsForTracker';
import { safeRead, safeReadFull, formatMoney } from '../functions/FunctionsInGeneral';
import SystemPage from './SystemPage';
import Popup from '../Popup';
import PopupHomeInsurance from '../PopupHomeInsurance';
import WiFiSurvey from '../WiFiSurvey';
import styled from 'styled-components'
import TreesImage from '../images/icons/Solid Trees.svg'
import { colors } from '../common/colors';
const InfoIcon = require ("../images/icons/infoIcon.png")
const data =
{
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
const TreesContainer=styled.div`
    display:flex;
    width:100%;
    height:100px;
    z-index:3;
    flex-direction:column;
    align-items:center;
    justify-content:flex-end;`

const TitleText = styled.span`
font-weight:bold;
color:${colors.white};
font-size:clamp(30px, 7vw,40px);
`

const TitleContainer= styled.div`
display:flex;
flex-direction:row;
align-items:center;
gap:10px;
`
const SubtitleContainer = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
gap:10px;`
const Info = styled.img`
width:20px;
`
const SubtitleText=styled.span`
font-weight:700;
color:${colors.white}`
const Trees = styled.img`
    width: 35px;
    height: 35px;
    object-fit: contain;
    filter: invert(1);
    margin-bottom:5px;
`;  
const PerYear = styled.div`
display:flex;
flex-direction:column;
margin-bottom:5px;`
const PerYearText=styled.span`
font-weight:700;
color:${colors.white};
line-height: 15px;
font-size:15px;`
const SystemExpectation = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
margin-top:30px;`
const SystemExpectationText = styled.span`
line-height:20px;
font-size:20px;`
const TreesText = styled.span`
font-weight:bold;
color:${colors.limegreen};
font-size:40px;`
const EPAButton = styled.button`
flex-direction:row;
align-items:center;
justify-content:center;
display:flex;
gap:5px;`


const options = {
    legend: {
        display: false,
        position: "right"
    },
    elements: {
        arc: {
            borderWidth: 0
        }
    }
};

function SavingsPage(props) {
    const stageNames = ['Info & Engineering', 'Permitting', 'Scheduling & Install', 'Inspections & Activations'];
    const stageDescriptions = [
        ['This stage is all about gathering required info, site photos, and completing the system engineering used to generate CAD and ENG diagrams for permits.'],
        ['We have submitted permit applications with both your permitting municipality and utility company. There is no action required on your part. Permit approvals typically take 3-4 weeks for completion; however, they can take up to 8 weeks, depending on the project size and permitting municipality at hand. Once permit approvals have been obtained, we will be reaching out to schedule installation!'],
    ]

    function composeMilestones() {
        return [
            [
                {
                    name: 'Property Inspection Scheduled',
                    complete: (safeRead(props.sfData.siteAnalysisDate, null) != null),
                    type: 'external'
                },
                {
                    name: 'Property Inspection Completed',
                    complete: (

                        (safeRead(props.sfData.siteAnalysisDate, null) != null)
                        &&
                        (safeRead(props.sfData.siteAnalysisCompletionDate, null) != null)
                    ),
                    type: 'external'
                },
                {
                    name: 'Structural Engineering Review',
                    complete: (safeRead(props.sfData.structuralEngineeringComplete, false)),
                    type: 'external',
                },
                {

                    name:
                        (safeRead(props.sfData.customerSignedUtilityPermit, false)) === true
                            ?
                            'Customer-Signed Utility Application Submission'
                            :
                            '- Net Metering Application (Sent through DocuSign)'

                    ,
                    complete: (safeRead(props.sfData.customerSignedUtilityPermit, false)),
                    type: 'other',
                },
                {
                    name: 'HOA / ACC Survey Completion',
                    complete: props.completedHOA,
                    type: 'survey',
                    clickAction: () => { props.setShowHOASurvey(!props.showHOASurvey) },
                    submissionCheck: 'HOASurvey'
                },
                {
                    name: 'Home Insurance Upload',
                    complete: props.completedInsurance,
                    type: 'file upload with popup',
                    submissionCheck: 'insuranceUpload',
                    clickAction: () => { toggleHomeInsurancePopup() },
                },
                {
                    name: props.sfData.customerNetworkName ?
                        'Wi-Fi Network Confirmed' :
                        'Confirm Wi-Fi Network',
                    complete: props.completedWiFi,
                    type: 'wifi-survey',
                    submissionCheck: 'wifiSurvey',
                    clickAction: () => { props.setShowWiFiSurvey(!props.showWiFiSurvey) }
                }
            ],
            [
                {
                    name: 'Utility Application Approved',
                    complete: (safeRead(props.sfData.utilityApplicationApproved, false)),
                    type: 'external'
                },
                {
                    name: 'Local Government Permits Approved',
                    complete: (safeRead(props.sfData.localGovPermitsApproved, false)),
                    type: 'external'
                },
            ],
            [
                // ORIGINAL DATE
                {

                    name:
                        !props.completedInstallSurvey && !props.rejectedInstallSurvey
                            ? 'Install Date Survey'
                            :
                            'Install at ' + props.sfData.installationScheduleDateText,
                    complete: props.completedInstallSurvey,
                    type: 'survey',
                    clickAction:
                        !props.sfData.installationScheduleDate
                            ?
                            () => { setShowInstallPopup(!showInstallPopup) }
                            :
                            !props.sfData.customerConfirmedInstallDate && props.sfData.customerHasCompletedInstallSurvey
                                ?
                                null
                                :
                                () => { props.setShowInstallDateSurvey(!props.showInstallDateSurvey) },


                    //() => { !props.sfData.installationScheduleDate ? setShowInstallPopup(!showInstallPopup) : props.setShowInstallDateSurvey(!props.showInstallDateSurvey) },
                    submissionCheck: 'installDateSurvey'
                },
                // RETURN DATE
                props.sfData.installReturnDate != null

                    ?
                    {
                        name:
                            !props.completedReturnInstallSurvey && !props.rejectedReturnInstallSurvey
                                ? 'Install Return Date Survey'
                                :
                                'Return Install at ' + props.sfData.installReturnDateText,
                        complete: props.completedReturnInstallSurvey,
                        type: 'survey',
                        clickAction: props.sfData.customerHasCompletedInstallSurvey && !props.sfData.customerConfirmedReturnInstallDate ? null : () => { props.setShowInstallDateSurvey(!props.showInstallDateSurvey) },
                        submissionCheck: 'installDateSurvey'
                    }
                    :
                    {},
                {
                    name: 'Install Date Confirmed',
                    complete: props.completedInstallSurvey,
                    type: 'external',
                    hideUntilCompleted: true,
                },
                {
                    name: 'Return Install Date Confirmed',
                    complete: props.completedReturnInstallSurvey,
                    type: 'external',
                    hideUntilCompleted: true,
                }
            ],
            [
                {
                    name: 'City & County Inspections',
                    complete: (safeRead(props.sfData.electricalInspectionPassed, false)),
                    type: 'external',
                },
                {
                    name: 'Utility Interconnection',
                    complete: (safeRead(props.sfData.utilityCommissionApproved, false)),
                    type: 'external',
                },
                {
                    name: 'Monitoring Verification',
                    complete: (safeRead(props.sfData.monitoringAccountCreated, false)),
                    type: 'external',
                },
                {
                    name: 'System Activation',
                    complete: (safeRead(props.sfData.commissionedDate, false)),
                    type: 'external',
                },
            ]
        ];
    }

    // Likely not effective to use a class for this yet. JSON is fine.
    const [milestonesMock, setMilestonesMock] = useState(
        composeMilestones()
    );

    const [showHomeInsurance, setShowHomeInsurance] = useState(false);

    function toggleHomeInsurancePopup() {
        setShowHomeInsurance(!showHomeInsurance)
    }

    const [showInstallPopup, setShowInstallPopup] = useState(false);

    const formatDate = (date) => {
        const jsDate = new Date(date);
        return (jsDate.toLocaleString('en-US', { timeStyle: 'short' }) +
            ', ' +
            jsDate.toLocaleString('en-US', { dateStyle: 'medium' })
        );
    }

    useEffect(() => {
        // console.log("- - - - - useEffect 33 - set milestones mock (is this still needed?)")
        setMilestonesMock(composeMilestones())
    }, [props.completedHOA, props.completedInstallSurvey, props.rejectedInstallSurvey, props.completedReturnInstallSurvey, props.rejectedReturnInstallSurvey, props.completedInsurance, props.completedWiFi]);

    const [activeStage, setActiveStage] = useState(1);
    const [stageColor, setStageColor] = useState();

    const [sfDataFormatted, setsfDataFormatted] = useState({});

    const [stageName, setStageName] = useState('');
    const [currentPhase, setCurrentPhase] = useState(1);
    const [MOCK_STAGES, SET_MOCK_STAGES] = useState([
        {
            name: stageNames[0],
            complete: true,
            current: false
        },
        {
            name: stageNames[1],
            complete: true,
            current: false
        },
        {
            name: stageNames[2],
            complete: false,
            current: false
        },
        {
            name: stageNames[3],
            complete: false,
            current: false
        },
    ]
    );

    useEffect(() => {
        if (props.sfData.id != null) {

            let newestStage = MOCK_STAGES[activeStage - 1]
            if (newestStage.current)
                setStageColor('current');
            else if (newestStage.complete)
                setStageColor('completed');
            else
                setStageColor('future');
        }
    }, [activeStage, props.sfData, currentPhase]);

    useEffect(() => {

        if (props.sfData.id != null) {

            let newestStage = MOCK_STAGES[activeStage - 1]
            if (newestStage.current)
                setStageColor('current');
            else if (newestStage.complete)
                setStageColor('completed');
            else
                setStageColor('future');
        }
    }, [activeStage]);

    useEffect(() => {

    }, [props.sfData])

    useEffect(() => {

        if (props.sfData.id != null) {

            setStageName(parseInt(activeStage));
            let phaseToAssign = computeCurrentPhase(props.sfData.oppStageFormula)
            setCurrentPhase(phaseToAssign)
            setActiveStage(phaseToAssign + 1);

            for (let i = 0; i < MOCK_STAGES.length; i++) {
                if (i < phaseToAssign) {
                    MOCK_STAGES[i].current = false
                    MOCK_STAGES[i].complete = true
                    MOCK_STAGES[i].future = false
                }
                else if (i == phaseToAssign) {
                    MOCK_STAGES[i].current = true
                    MOCK_STAGES[i].complete = false
                    MOCK_STAGES[i].future = false
                }
                else {
                    MOCK_STAGES[i].current = false
                    MOCK_STAGES[i].complete = false
                    MOCK_STAGES[i].future = true
                }
            }
            // TODO: see why this is happening every time the /Savings page loads & how we can make it only do this when the sfData call happens
        }
    }, [props.sfData, props.completedWiFi]);

    class StageObject {
        constructor(stageName, stageText) {
            this.stageName = stageName;
            this.stageText = stageText;
        }
    }

    let mockStageData = [
        new StageObject("Install Part 1", "This stage is all about gathering required info, site photos, and completing the system engineering used to generate CAD and ENG diagrams for permits"),
        new StageObject("Install Part 2", "We have submitted permit applications with both your permitting municipality and utility company. There is no action required on your part. Permit approvals typically take 3-4 weeks for completion, however, they can take up to 8 weeks; depending on the project size and permitting municipality at hand. Once permit approvals have been obtained, we will be reaching out to schedule installation!"),
        new StageObject("Install Part 3", ""),
        new StageObject("Install Part 4", "")
    ]

    function renderMilestones(complete) {
        milestonesMock[parseInt(activeStage) - 1].sort(
            (a, b) => {
                if (!a.complete && b.complete)
                    return 1
                if (a.complete && !b.complete)
                    return -1
                return 0
            })
        return (
            milestonesMock[parseInt(activeStage) - 1].map((milestone) => (
                (milestone.complete == complete && milestone.type != 'external')
                    ||
                    (complete && milestone.type == 'external' && (!milestone.hideUntilCompleted || milestone.complete)) // If complete is true, then we are in the MILESTONES div
                    ?
                    <Milestone
                        name={milestone.name}
                        complete={milestone.complete}
                        type={milestone.type}
                        sfData={props.sfData}
                        updateSFdata={props.updateSFdata}
                        clickAction={milestone.clickAction}
                        submissionCheck={milestone.submissionCheck}
                        completedHOA={props.completedHOA}
                        completedWiFi={props.completedWiFi}
                        completedInstallSurvey={props.completedInstallSurvey}
                        rejectedInstallSurvey={props.rejectedInstallSurvey}
                        completedReturnInstallSurvey={props.completedReturnInstallSurvey}
                        rejectedReturnInstallSurvey={props.rejectedReturnInstallSurvey}
                        installSurveyReady={props.installSurveyReady}
                        completedInsurance={props.completedInsurance}
                        setCompletedInsurance={props.setCompletedInsurance}
                        accountIdv3={props.accountIdv3}
                        currentPhase={currentPhase}
                        stageColor={stageColor}
                    />
                    :
                    null
            ))
        );
    }

    function milestonesAllCompleted() {
        for (let i = 0; i < milestonesMock[parseInt(activeStage) - 1].length; i++) {
            if (!milestonesMock[parseInt(activeStage) - 1][i].complete && milestonesMock[parseInt(activeStage) - 1][i].type != 'external' && milestonesMock[parseInt(activeStage) - 1][i].name != null)
                return false;
        }
        return true;
    }

    function renderProgressDots() {
        // Count number of completed milestones
        let completed = 0
        for (let i = 0; i < milestonesMock[parseInt(activeStage) - 1].length; i++) {
            if (milestonesMock[parseInt(activeStage) - 1][i].complete)
                completed += 1
        }
        // Divide by total number of milestones
        let ratio = completed / milestonesMock[parseInt(activeStage) - 1].length
        let dots = [];
        for (let i = 0; i < 6; i++) {
            if ((i / 6) < ratio)
                dots.unshift(true)
            else
                dots.push(false)
        }
        return (
            dots.map((dot) => (
                dot
                    ?
                    <div className="dotComplete"></div>
                    :
                    <div className="dotIncomplete"></div>
            ))
        );
    }

    function getTrees(kwh) {
        return Math.floor(kwh * 0.011551)
    }

    return (
        <>
            {
                (props.sfData.id != null)
                    ?
                    (props.sfData.oppStageFormula != 'Lost' && (props.sfData.projectStatus == null || props.sfData.projectStatus != 'Cancelled')) // TODO: inquire about which project status to look for for "CANCELLED" page
                        ?
                        <>
                            {
                                (props.sfData.commissionedDate == null)
                                    ?
                                    <div className='savings-page-container'>
                                        {
                                            props.showInstallDateSurvey ? <InstallDateSurvey installData={props.installData} setShowInstallDateSurvey={props.setShowInstallDateSurvey} accountIdv3={props.accountIdv3} setCompletedInstallSurvey={props.setCompletedInstallSurvey} rejectedInstallSurvey={props.rejectedInstallSurvey} setRejectedInstallSurvey={props.setRejectedInstallSurvey}
                                                completedReturnInstallSurvey={props.completedReturnInstallSurvey}
                                                setCompletedReturnInstallSurvey={props.setCompletedReturnInstallSurvey}
                                                rejectedReturnInstallSurvey={props.rejectedReturnInstallSurvey}
                                                setRejectedReturnInstallSurvey={props.setRejectedReturnInstallSurvey}
                                                setInstallSurveyReady={props.setInstallSurveyReady}
                                            /> : null
                                        }

                                        {showInstallPopup && <Popup
                                            text={!props.sfData.installationScheduleDate
                                                ?
                                                <>This Survey will be available when your project is ready for scheduling confirmation.</>
                                                :
                                                <>Your {props.sfData.installReturnDate && <>return</>} install date is confirmed for <br /><span className="blueDateText">{props.sfData.installReturnDateText || props.sfData.installationScheduleDateText}</span>. <br /> <br /> If you have any concerns or needs please reach out to your dedicated customer success representative.</>}
                                            buttonText="OK" visible={() => setShowInstallPopup(!showInstallPopup)} />}

                                        {props.showHOASurvey ? <HOASurveyForm setShowHOASurvey={props.setShowHOASurvey} accountId={props.accountId} accountIdv3={props.accountIdv3} setCompletedHOA={props.setCompletedHOA} sfData={props.sfData} userIsOnMobilePhone={props.userIsOnMobilePhone} /> : null}

                                        {props.showWiFiSurvey &&
                                            <WiFiSurvey
                                                setShowWiFiSurvey={props.setShowWiFiSurvey}
                                                accountId={props.accountIdv3}
                                                completedWiFi={props.completedWiFi}
                                                setCompletedWiFi={props.setCompletedWiFi}
                                                sfData={props.sfData}
                                                userIsOnMobilePhone={props.userIsOnMobilePhone}
                                                updateSFdata={props.updateSFdata}
                                            />
                                        }

                                        {props.alertData.text ? <AlertBanner alertData={props.alertData} setShowHOASurvey={props.setShowHOASurvey} setShowInstallDateSurvey={props.setShowInstallDateSurvey} /> : null}

                                        <div className="topBar">
                                            {props?.sfData?.twentyFiveYearSavings > 0 ? 
                                            <>
                                            <div className="bigText">{formatMoney(props.sfData.twentyFiveYearSavings)}</div>
                                            <div className="label">Est. 25-YEAR SAVINGS</div>
                                            </>
                                            :
                                            <TreesContainer>
                                                <TitleContainer>
                                                    <TitleText>System Offset {getTrees(props?.sfData?.firstYearEstimatedProductionKwh)}</TitleText>
                                                    <Trees src = {TreesImage} alt = "trees" loading='lazy'/>
                                                    <PerYear>
                                                        <PerYearText>Per</PerYearText>
                                                        <PerYearText>Year</PerYearText>
                                                    </PerYear>
                                                </TitleContainer>
                                                <SubtitleContainer>
                                                <EPAButton as="a" href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator" target="_blank" rel="noopener noreferrer">
                                                    <Info src={InfoIcon} alt="info" loading='lazy'/>
                                                    <SubtitleText>EPA.gov</SubtitleText>
                                                </EPAButton>
                                            </SubtitleContainer>
                                            </TreesContainer>}
                                        </div>
                                        <div className="margin10px"></div>
                                        <div className="chartSection">
                                            <div className="leftText">Est. Minimum</div>
                                            <div className="circleChart"><CircleChart ratio={safeRead(props.sfData.estimatedOffset, 0.95)} userIsOnMobilePhone={props.userIsOnMobilePhone} /></div>
                                            <p>
                                                {
                                                    props.sfData.estimatedOffset == null
                                                        ?
                                                        95
                                                        :
                                                        parseFloat(safeRead(props.sfData.estimatedOffset, 0.95)).toFixed(2) * 100
                                                }

                                                %
                                            </p>
                                            <div className="rightText">Solar Offset</div>

                                        </div>
                                        <div className="StageSelectorBlock"><StageSelector activeStage={activeStage} setActiveStage={setActiveStage} MOCK_STAGES={MOCK_STAGES} /></div>
                                        <section className='stage-section-mask' id={stageColor}>
                                            <div className="stageSection" id={stageColor}>
                                                <div className="stageSectionTop">
                                                    <div className="stageName">
                                                        {
                                                            <>
                                                                <div className="name">
                                                                    {stageNames[parseInt(activeStage) - 1].toUpperCase()}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="progressDots">
                                                        {renderProgressDots()}
                                                    </div>
                                                    <div className="stageName marginAdded">
                                                        <div className="label">
                                                            {
                                                                determineStageTitle(props.sfData.oppStageFormula, props.sfData.ecuId, props.sfData.siteAnalysisDate, currentPhase, parseInt(activeStage) - 1)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="stageSectionMiddle">
                                                    <div className="milestones marginAdded">
                                                        <div className="label">MILESTONES</div>
                                                        { // We can use functions here - this is so awesome.
                                                            renderMilestones(true)
                                                        }
                                                    </div>
                                                    <div className="toDo marginAdded">
                                                        <div className="label">MY TO DO LIST</div>
                                                        {
                                                            milestonesAllCompleted()
                                                                ?
                                                                <div className="toDoMessage">
                                                                    - Thanks, your side is done for now! We’ll reach out if we need anything.
                                                                    {
                                                                        currentPhase == 3 && props.sfData.energyProductionDeviceId != null
                                                                            ?
                                                                            <> And thanks much for staying patient while we wait with you for these other organizations to do their part so we can turn on your new system!</>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                :
                                                                renderMilestones(false)
                                                        }
                                                    </div>
                                                    {
                                                        stageDescriptions[+activeStage - 1] ?
                                                            <div className="stage-description marginAdded">
                                                                <div className="label">ABOUT THIS STAGE</div>
                                                                {stageDescriptions[+activeStage - 1].map((paragraph, i) => {
                                                                    return <p key={'stageDescriptionP' + i}>{paragraph}</p>
                                                                })}
                                                            </div> :
                                                            null
                                                    }
                                                    <div className="notes marginAdded" style={{ display: "none" }}>
                                                        <div class="label">Notes</div>
                                                        <p>Reminder this could take weeks</p>
                                                    </div>

                                                </div>
                                                <div className="addressAndSize">
                                                    {safeRead(props.sfData.projectAddress, "") + " - "}
                                                    {props.sfData.projectDCsystemSize + " System"}
                                                </div>
                                                {showHomeInsurance && <PopupHomeInsurance toggle={toggleHomeInsurancePopup} setCompletedInsurance={props.setCompletedInsurance} sfData={props.sfData} />}
                                            </div>
                                        </section>
                                    </div>
                                    :
                                    <SystemPage
                                    accountIdv3={props.accountIdv3}
                                    sfData={props.sfData}
                                    upgradeData={props.upgradeData}
                                    pendingUpgrades={props.pendingUpgrades}
					                completedUpgrades={props.completedUpgrades}
                                    userIsOnMobilePhone={props.userIsOnMobilePhone}
                                    />
                            }
                        </>
                        :
                        <div className='savings-page-container2'>
                            <div className="topBarRed2">
                                <div className="bigText">CANCELLED</div>

                            </div>
                            <div className="margin20px"></div>
                            <div className="grayBigLabel">Your Everlight Solar System</div>
                            <div className="twentyfive">
                            {props?.sfData?.twentyFiveYearSavings > 0 ? 
                                <>  
                                    <div className="label3">Est. 25-year savings with<br />Everlight</div>
                                    <div className="dollars">${props.sfData.twentyFiveYearSavings.substring(0, props.sfData.twentyFiveYearSavings.indexOf('.'))}</div>
                                </>
                            :   <SystemExpectation>
                                    <SystemExpectationText>System expects to annually offset what</SystemExpectationText>
                                    <TreesText>{getTrees(props?.sfData?.firstYearEstimatedProductionKwh)} Trees</TreesText>
                                    <SystemExpectationText>do in a ten year span.</SystemExpectationText>
                                    <SystemExpectationText>But you'll do it every year!</SystemExpectationText>
                                </SystemExpectation>
                   }
                                </div>
                            <div className="margin20px"></div>
                            <div className="niceFlexBox">
                                <div className="chartSection chartSectionCancelled" style={{ height: "auto" }}>
                                    <div className="circleChart2"><CircleChart ratio={safeRead(props.sfData.estimatedOffset, 0.95)} userIsOnMobilePhone={props.userIsOnMobilePhone} /></div>
                                    <p className="bigCenterText2">{props.sfData.estimatedOffset == null
                                        ?
                                        95
                                        :
                                        parseFloat(safeRead(props.sfData.estimatedOffset, 0.95)).toFixed(2) * 100}%</p>
                                </div>
                                <div className="margin20px"></div>
                                <div className="getBackSavingsBox">
                                    <ThreeButtonsOnlyOne />
                                </div>
                            </div>
                            <div className="margin100px"></div>
                        </div>
                    :
                    null
            }
        </>
    )
}

export default SavingsPage;