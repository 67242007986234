import React, { useRef, useState } from "react";
import './HOASurveyForm.css';
import { useWindowWidth } from "./functions/useWindowWidth";

import * as Constants from './functions/Constants'

const Checkmark = () => {
  return (
    <svg className='survey-check' fill='#74BD59' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role='img'>
      <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  )
}

const HOASurveyForm = (props) => {
  const radioButtonYes = useRef();
  const radioButtonNo = useRef();
  const companyInput = useRef();
  const emailInput = useRef();
  const phoneInput = useRef();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showExtendedForm, setShowExtendedForm] = useState(false);
  const [surveyFinished, setSurveyFinished] = useState(false);

  // Form validation error messages
  const [errorMember, setErrorMember] = useState('');
  const [errorCompany, setErrorCompany] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');

  // Form validation valid input indicator
  const [validCompany, setValidCompany] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);

  const [footer, setFooter] = useState(false)
  const onRadioChange = () => {
    setSubmitDisabled(false);
    setErrorMember('');
    setShowExtendedForm(radioButtonYes.current.checked);
    setFooter(true)
    // Reset Form Errors / Checkmarks
    setErrorCompany('');
    setErrorEmail('');
    setErrorPhone('');
    setValidCompany(false);
    setValidEmail(false);
    setValidPhone(false);
  }

  const onCancelClick = (e) => {
    e.preventDefault();
    props.setShowHOASurvey(false);
  }

  const emailRegEx = new RegExp('\\S+@\\S+\\.\\S{2,3}');
  const phoneRegEx = new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$');

  const onSubmitClick = (e) => {
    e.preventDefault();

    if (surveyFinished) {
      props.setShowHOASurvey(false);
      return;
    }

    if (!radioButtonYes.current.checked && !radioButtonNo.current.checked) {
      setErrorMember('Required: please indicate whether or not you are a member of an HOA')
      return;
    }

    let errorFlag = false;

    if (showExtendedForm) {
      if (!companyInput.current.value) {
        setErrorCompany('Required: please enter the company name of your HOA.')
        errorFlag = true;
      }
      if (emailInput.current.value) {
        if (!emailRegEx.test(emailInput.current.value)) {
          setErrorEmail('Please enter an email address in valid email address format, e.g. hoa@example.com');
          errorFlag = true;
        }
      }
      if (phoneInput.current.value) {
        if (!phoneRegEx.test(phoneInput.current.value)) {
          setErrorPhone('Please enter a phone number in valid phone number format, e.g. (123) 456-7890 or 1234567890');
          errorFlag = true;
        }
      }
    }

    const formBody = {
      isMemberOfHoaOrAcc: radioButtonYes.current.checked,
      hoaOrAccCompanyName: companyInput.current ? companyInput.current.value : '',
      hoaOrAccPrimaryEmail: emailInput.current ? emailInput.current.value : '',
      hoaOrAccPrimaryPhone: phoneInput.current ? phoneInput.current.value : '',
    }

    const searchParams = new URLSearchParams({
      EverlightAppConnectPIN: props.accountIdv3,
      OpportunityId: props.sfData.opportunityId
    });

    if (radioButtonNo.current.checked || (radioButtonYes.current.checked && !errorFlag)) {
      setSurveyFinished(true);
      props.setCompletedHOA(true);
      fetch(`${Constants.JS_BACKEND_URL}/api/v1/survey/hoa?` + searchParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'AuthKey': 'f2a20b1c-cf3f-11ec-9d64-0242ac120002'
        },
        body: JSON.stringify(formBody)
      })
      .then(res => res.json())
      .then(res => {
        // POST call succeeded, but let's double check that against SF.
        setTimeout(() => {
            props.updateSFdata()
            .then((latestSFdata) => {
                if(latestSFdata.hoaAccStatus != "Approved") // Send back to rejected state if SF doesn't agree
                    props.setCompletedHOA(false)
            })
            .catch(() => {
                // TODO: error handling for if SF call fails
            })
        }, 3000)
      })
        .catch(error => {
          // TODO: error condition handling for POST request
          props.setCompletedHOA(false);
        });
    }
  };

  const onCompanyInput = () => {
    setErrorCompany(companyInput.current.value ? '' : 'Required: please enter the company name of your HOA.');
    setValidCompany(!!companyInput.current.value);
  }

  const onEmailInput = () => {
    setErrorEmail('');
    setValidEmail(emailRegEx.test(emailInput.current.value));
  }

  const onPhoneInput = () => {
    setErrorPhone('');
    setValidPhone(phoneRegEx.test(phoneInput.current.value));
  }

  const windowWidth = useWindowWidth()

  return (
    <div className="HOA-survey-container">
      <div className="HOA-survey-overlay" onClick={onCancelClick}></div>
      <form className="HOA-survey">
        <h1 className="survey-heading">HOA Survey</h1>
        {surveyFinished ?
          <section className="HOA-finished-page">
            <p>Thank you for completing the HOA survey!</p>
          </section>
          :
          <section>
            <p><span className='required-asterisk' title='required'>* </span>Are you a member of a Homeowners Association (HOA)?</p>
            <div>
              <input type='radio' id='HOA-member-yes' name='HOA-member-bool' value={true} onInput={onRadioChange} ref={radioButtonYes} />
              <label htmlFor='HOA-member-yes'>Yes</label>
            </div>
            <div>
              <input type='radio' id='HOA-member-no' name='HOA-member-bool' value={false} onInput={onRadioChange} ref={radioButtonNo} />
              <label htmlFor='HOA-member-no'>No</label>
            </div>
            {errorMember ? <div className='survey-error'>{errorMember}</div> : null}
            {showExtendedForm
              ? (
                <div className="HOA-survey-extended">
                  <div>
                    <label htmlFor='HOA-company'><span className='required-asterisk' title='required'>* </span>HOA Company Name</label>
                    <div className='survey-input-group'>
                      <input ref={companyInput} type='text' id='HOA-company' name='HOACompany' onInput={onCompanyInput}></input>
                      {validCompany ? <Checkmark /> : null}
                    </div>
                    {errorCompany ? <div className='survey-error'>{errorCompany}</div> : null}
                  </div>
                  <div>
                    <label htmlFor='HOA-email'>HOA Primary Point of Contact Email</label>
                    <div className='survey-input-group'>
                      <input ref={emailInput} type='email' id='HOA-email' name='HOAEmail' placeholder='hoa@example.com' onInput={onEmailInput}></input>
                      {validEmail ? <Checkmark /> : null}
                    </div>
                    {errorEmail ? <div className='survey-error'>{errorEmail}</div> : null}
                  </div>
                  <div>
                    <label htmlFor='HOA-phone'>HOA Primary Point of Contact Phone</label>
                    <div className="survey-input-group">
                      <input ref={phoneInput} type='tel' id='HOA-phone' name='HOAPhone' placeholder='555-555-5555' onInput={onPhoneInput}></input>
                      {validPhone ? <Checkmark /> : null}
                    </div>
                    {errorPhone ? <div className='survey-error'>{errorPhone}</div> : null}
                  </div>
                </div>
              )
              : null}
          </section>
        }
  {windowWidth < 600 || props.userIsOnMobilePhone
  ?
  <footer>
          {surveyFinished ? null : <button id="HOA-survey-cancel-button" onClick={onCancelClick}>Cancel</button>}
          <button id="HOA-survey-submit-button" onClick={onSubmitClick} disabled={submitDisabled}>Finish</button>
        </footer> 
        : 
        footer ? 
        <footer>
          {surveyFinished ? null : <button id="HOA-survey-cancel-button" onClick={onCancelClick}>Cancel</button>}
          <button id="HOA-survey-submit-button" onClick={onSubmitClick} disabled={submitDisabled}>Finish</button>
        </footer> 
        : 
        null
        }
        
      </form>
    </div>
  )
}

export default HOASurveyForm