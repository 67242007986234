import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import '../LoginPage.css';

import EverlightLogoAnimated from "../images/EverlightLogoAnimated.gif";
import NotCustomerTab from '../NotCustomerTab';
import { useWindowWidth } from '../functions/useWindowWidth';
import parseCSLogin from '../functions/parseCSLogin';

function LoginPage(props) {
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [showBadLogin, setShowBadLogin] = useState(false);
	const [showPullTab, setShowPullTab] = useState(true);
	const inputRef = useRef();
	const nav = useNavigate();


	const windowWidth = useWindowWidth()

	function verifyLogin(e) {
		e.preventDefault();
		const inputValue = inputRef.current.value;
		if (!inputValue)
			setShowBadLogin(true);
		else {
			props.updateSFdata()
				.then(() => {
					setShowPullTab(false);
					props.setSfCallFinished(true);
					props.setShowOverlay(true);
					props.setOverlayText("Logging you in...");
					localStorage.setItem("EverlightPIN", inputValue);
					windowWidth < 600 || props.userIsOnMobilePhone ? nav("/savings") : nav("/account")

				})
				.catch(() => {
					setShowBadLogin(true);
				})
			// nav("/energy");
		}
	}

	useEffect(() => {
		if (showBadLogin) {
            // console.log("- - - - - useEffect 32 - handle 'PIN not recognized' effect.")
			localStorage.removeItem("EverlightPIN");
			setTimeout(() => {
				setShowBadLogin(false);
			}, "2000");
		}
	}, [showBadLogin]);

	const onLoginInput = (e) => {
		setSubmitDisabled(e.target.value.length < 15);
	}

	return (
		<div className="login-container">
			<header className="login-header">
				<img src={EverlightLogoAnimated} className="login-logo" width={"100%"} alt="Everlight rising sun logo" loading='lazy'/>
				<h1 className="login-title">Everlight Solar</h1>
			</header>
			<form className="login-form">
				<input
					type="text"
					placeholder="Enter your Everlight PIN"
					ref={inputRef}
					onChange={(e) => props.setAccountId(parseCSLogin(inputRef.current.value, props.setIsCSLogin))}
					onInput={onLoginInput}
				/>
				<input type="submit" id="submitButton" className="center hover3" value="SUBMIT" onClick={(e) => verifyLogin(e)} disabled={submitDisabled} />
				<div className="dropShadow1 hover4" id="badLogin" style={showBadLogin ? { opacity: '1' } : { opacity: '0' }} data-testid="login-error-message">
					<p style={{ marginTop: "10px", marginBottom: "20px", color: "red" }} className="center">PIN not recognized</p>
				</div>
			</form>
			{showPullTab ? <NotCustomerTab /> : null}
		</div>
	);
}

export default LoginPage;