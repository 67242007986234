import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../common/colors";
import Rewards from "./Rewards";
import NewReferral from "./NewReferral";
import PreferredCustomerApplication from "./PreferredCustomerApplication";
import BackgroundBars from "./BackgroundBars";
import Modal from "../../common/Modal";
import PushNotificationComponent from "../../common/MockNotification";

import {
  fetchRewards,
  fetchContactsData,
  fetchPreferredCustomerStatus,
} from "./ReferralsHelpers";

const PreferredBadgeGray = require("../../../src/images/Preferred-Badge_grey.png");
const PreferredBadgeBlue = require("../../../src/images/Preferred-Badge_blue.png");
const ReferAFriend = require("../../../src/images/ReferAFriendIcon.png");
const RightChevronGreen = require("../../images/icons/caret-right-green.png");
const RewardsPageBG = require("../../images/RewardsPageBG.png");

const gradientStyles = (color1, color2) => `
  background: linear-gradient(to right, ${color1} 0%, ${color2} 100%);
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid green;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 50px 0;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const BackgroundOverlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40%;
  background: #307467;
  z-index: -2;
  ${gradientStyles("#307567", "#489662")}
`;

const Background = styled.img`
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 20%;
`;

const TitleText = styled.span`
  font-size: clamp(50px, 20vw, 90px);
  color: ${colors.limegreen};
  margin-top: 50px;
  font-weight: 700;
`;

const SubtitleText = styled.span`
  font-size: 16px;
  color: ${colors.darkolive};
  margin-top: -30px;
  margin-left: 200px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: clamp(10px, 10vw, 60px);
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 30px;
  margin-top: 50px;
  padding-bottom: 20px;
  gap: clamp(10px, 4vw, 30px);
  width: 100%;
  box-sizing: border-box;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-right: 0px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-top: 40px;
  width: 100%;
  cursor: pointer;
`;

const DollarValue = styled.div`
  font-size: clamp(18px, 8vw, 40px);
  font-weight: 900;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: ${colors.darkolive};
  margin-top: -15px;
`;

const CashOnTheWay = styled.div`
  max-width: 150px;
  min-width: 140px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border: 3px dashed ${colors.mutedforest};
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
`;

const DollarSigns = styled.span`
  font-size: clamp(30px, 10vw, 50px);
  font-weight: 700;
  color: ${colors.mutedforest};
`;

const CashOnTheWayText = styled.span`
  font-weight: 700;
  color: ${colors.mutedforest};
  font-size: 20px;
  gap: 30px;
  text-align: right;
  display: flex;
`;

const Button = styled.button`
  border-radius: 20px;
  padding: 15px;
  text-align: center;
  color: white;
  background: ${colors.white};
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 50px;
  max-width: 200px;
  max-height: 160px;
  min-height: 160px;
`;

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: 200px;
`;

const ApplyBadgeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

const ApplyBadge = styled.img`
  max-width: 100px;
  height: auto;
  filter: invert(48%) sepia(18%) saturate(357%) hue-rotate(71deg)
    brightness(92%) contrast(87%);
  margin-bottom: 10px;
  z-index: 20;
  object-fit: contain;
`;

const ApplyTab = styled.div`
  ${gradientStyles(colors.darkgreen, colors.limegreen)}
  z-index: 0;
  border-radius: 20px 20px 0 0;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  max-width: 150px;
  height: 40px;
  justify-content: center;
  align-self: center;
  justify-self: center;
`;

const PreferredTab = styled.div`
  ${gradientStyles(colors.darkgreen, colors.limegreen)}
  z-index: 1;
  border-radius: 20px 20px 0 0;
  padding-top: 10px;
  padding-left: 22px;
  padding-right: 22px;
  max-width: 200px;
  min-width: 167px;
  height: 40px;
`;
const ReferTab = styled.div`
  ${gradientStyles(colors.ctablue, "#68b3fd")}
  z-index: 0;
  border-radius: 20px 20px 0 0;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 160px;
  height: 40px;
  display: flex;
`;

const PreferredBadge = styled.img`
  z-index: 0;
  display: flex;
  max-width: 100px;
  height: auto;
`;

const PreferredBackground = styled.div`
  ${gradientStyles(colors.darkgreen, colors.limegreen)}
  clip-path: inset(0 0 40% 0);
  position: absolute;
  z-index: 0;
  height: 18%;
  width: 167px;
`;

const ReferBadgeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
  height: 100%;
`;

const ReferBadge = styled.img`
  width: 70px;
  height: auto;
  margin-bottom: 10px;
  z-index: 1;
  object-fit: contain;
`;

const QuestionText = styled.span`
  font-weight: 700;
  color: ${colors.gray};
  font-size: clamp(12px, 2.4vw, 14px);
  white-space: nowrap;
  max-width: 130px;
  min-width: 130px;
`;

const CtaText = styled.span`
  font-weight: 700;
  color: ${colors.ctablue};
  font-size: clamp(15px, 3.8vw, 17px);
  max-width: 130px;
  min-width: 130px;
`;

const TabText = styled.span`
  font-weight: 700;
  color: white;
  font-size: clamp(13px, 3vw, 1px);
`;

const ReferralsPendingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronIcon = styled.img`
  margin-left: 20px;
  width: 40px;
  height: auto;
  justify-self: flex-end;
`;

const Padding = styled.span`
  color: ${colors.limegreen};
`;

const initialState = {
  showRewards: false,
  showPreferredApplication: false,
  showNewReferral: false,
  preferredCustomerStatus: "notApplied",
  totalEarned: "0",
  systemSavings: "0",
  referralsPending: "0",
  testimonialsPending: "0",
  perTestimonial: "100",
  perReferral: "250",
  cashOnTheWay: "0",
  loading: true,
  rewardsData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "rewards":
      return {
        ...state,
        showRewards: true,
        showNewReferral: false,
        showPreferredApplication: false,
      };
    case "newReferral":
      return {
        ...state,
        showRewards: false,
        showNewReferral: true,
        showPreferredApplication: false,
      };
    case "preferredApplication":
      return {
        ...state,
        showRewards: false,
        showNewReferral: false,
        showPreferredApplication: true,
      };
    case "clear":
      return {
        ...state,
        showRewards: false,
        showNewReferral: false,
        showPreferredApplication: false,
      };
    case "setState":
      return { ...state, ...action.data };
    case "setLoading":
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

export default function Wallet({ contactID, twentyFiveYearSavings }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storedContactID = contactID || localStorage.getItem("contactID");
  function referLink() {
    dispatch({ type: "clear" });
    dispatch({ type: "newReferral" });
  }

  const PREFERRED_CUSTOMER_URL = `https://damp-ridge-21682.herokuapp.com/react-referral/preferred-customer`;
  const CONTACTS_URL = `https://damp-ridge-21682.herokuapp.com/react-referral/wallet/contacts`;

  useEffect(() => {
    const fetchContacts = async () => {
      dispatch({ type: "setLoading", loading: true });
      try {
        const contactData = await fetchContactsData(
          CONTACTS_URL,
          storedContactID
        );
        const totalReferralMoneyEarned = parseFloat(
          contactData?.totalReferralMoneyEarned?.replace(/[$,]/g, "")
        );
        const totalReferralMoneyPaid = parseFloat(
          contactData?.totalReferralMoneyPaid?.replace(/[$,]/g, "")
        );
        const totalPreferredCustomerMoneyEarned = parseFloat(
          contactData?.totalPreferredCustomerMoneyEarned?.replace(/[$,]/g, "")
        );
        const totalPreferredCustomerMoneyPaid = parseFloat(
          contactData?.totalPreferredCustomerMoneyPaid?.replace(/[$,]/g, "")
        );

        let preferredCustomerStatus = contactData?.preferredCustomer
          ? "accepted"
          : "notApplied";

        dispatch({
          type: "setState",
          data: {
            preferredCustomerStatus,
            totalEarned:
              contactData?.totalRewardsEarned &&
              contactData?.totalRewardsEarned >= 0
                ? contactData?.totalRewardsEarned
                : "0",
            referralsPending:
              totalReferralMoneyEarned && totalReferralMoneyPaid
                ? Number(totalReferralMoneyEarned) -
                  Number(totalReferralMoneyPaid)
                : totalReferralMoneyEarned
                ? Number(totalReferralMoneyEarned)
                : "0",
            testimonialsPending:
              totalPreferredCustomerMoneyEarned &&
              totalPreferredCustomerMoneyPaid
                ? Number(totalPreferredCustomerMoneyEarned) -
                  Number(totalPreferredCustomerMoneyPaid)
                : totalPreferredCustomerMoneyEarned
                ? Number(totalPreferredCustomerMoneyEarned)
                : "0",
          },
        });

        if (
          contactData?.totalRewardsEarned == null ||
          contactData?.totalReferralMoneyEarned == null ||
          contactData?.totalReferralMoneyPaid == null
        ) {
          dispatch({ type: "showError" });
        }

        if (preferredCustomerStatus !== "accepted") {
          const status = await fetchPreferredCustomerStatus(
            PREFERRED_CUSTOMER_URL,
            storedContactID
          );
          preferredCustomerStatus = status;

          dispatch({
            type: "setState",
            data: { preferredCustomerStatus },
          });
        }
        const USER_PIN = localStorage.getItem("EverlightPIN");
        const API_PIN =
          USER_PIN && USER_PIN.startsWith("CS-")
            ? USER_PIN.substring(3)
            : USER_PIN;
        const { rewardsData, cashOnTheWay } = await fetchRewards(API_PIN);

        dispatch({
          type: "setState",
          data: {
            rewardsData,
            cashOnTheWay: cashOnTheWay,
            referralsPending:
              totalReferralMoneyEarned && totalReferralMoneyPaid
                ? Number(totalReferralMoneyEarned) -
                  Number(totalReferralMoneyPaid)
                : "0",
          },
        });
      } catch (error) {
        console.error(
          "Error fetching contacts, preferred customer status, or rewards:",
          error
        );
      }
      dispatch({ type: "setLoading", loading: false });
    };

    fetchContacts();
  }, [PREFERRED_CUSTOMER_URL, CONTACTS_URL, storedContactID]);

  useEffect(() => {
    const systemSavings =
      localStorage.getItem("25Year") || twentyFiveYearSavings;

    dispatch({ type: "setState", data: { systemSavings: systemSavings } });
  }, [twentyFiveYearSavings]);

  return (
    <Container>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <Background src={RewardsPageBG} alt="background" loading='lazy'/>
          <BackgroundBars />
          <BackgroundOverlay />
          <TitleText>
            $
            {state.totalEarned !== "0"
              ? state.totalEarned
              : Number(state?.systemSavings?.split(".")[0]) >= 0
              ? new Intl.NumberFormat("en-US").format(
                  Number(state?.systemSavings?.split(".")[0])
                )
              : "0"}
          </TitleText>
          <SubtitleText>Lifetime Rewards Est.</SubtitleText>
          <ContentContainer>
            <CashOnTheWay>
              <DollarSigns>
                {Number(state.cashOnTheWay) ? `$${state.cashOnTheWay}` : "$$$"}
              </DollarSigns>
              <CashOnTheWayText>
                {Number(state.cashOnTheWay)
                  ? "Cash on the way!"
                  : "Your cash on the way will live here"}
              </CashOnTheWayText>
            </CashOnTheWay>
            <LinkContainer>
              {state?.systemSavings > 0 ? (
                <>
                  <InfoContainer>
                    <DollarValue>
                      $
                      {state.totalEarned !== "0"
                        ? state.totalEarned
                        : twentyFiveYearSavings
                        ? new Intl.NumberFormat("en-US").format(
                            twentyFiveYearSavings
                          )
                        : new Intl.NumberFormat("en-US").format(
                            Number(state?.systemSavings?.split(".")[0])
                          )}
                    </DollarValue>

                    <Subtitle>System Savings</Subtitle>
                  </InfoContainer>
                  <InfoContainer onClick={() => dispatch({ type: "rewards" })}>
                    <ReferralsPendingContainer>
                      <DollarValue>
                        $
                        {state.referralsPending > 0
                          ? state.referralsPending
                          : 500}
                      </DollarValue>
                      <ChevronIcon
                        src={RightChevronGreen}
                        alt="Right Chevron"
                        loading='lazy'
                      />
                    </ReferralsPendingContainer>
                    <Subtitle>
                      {state.referralsPending > 0
                        ? "Referrals Pending"
                        : "Per Referral"}
                    </Subtitle>
                  </InfoContainer>
                  <InfoContainer onClick={() => dispatch({ type: "rewards" })}>
                    <ReferralsPendingContainer>
                      <DollarValue>
                        $
                        {state.testimonialsPending > 0
                          ? state.testimonialsPending
                          : state.perTestimonial}
                      </DollarValue>
                      <ChevronIcon
                        src={RightChevronGreen}
                        alt="Right Chevron"
                      />
                    </ReferralsPendingContainer>
                    <Subtitle>
                      {state.testimonialsPending > 0
                        ? "Testimonials Pending"
                        : "Per Testimonial"}
                    </Subtitle>
                  </InfoContainer>
                </>
              ) : (
                <>
                  <InfoContainer>
                    <Padding>{". "}</Padding>
                    <Padding>{". "}</Padding>
                  </InfoContainer>
                  <InfoContainer onClick={() => dispatch({ type: "rewards" })}>
                    <ReferralsPendingContainer>
                      <DollarValue>
                        $
                        {state.referralsPending > 0
                          ? state.referralsPending
                          : 500}
                      </DollarValue>
                      <ChevronIcon
                        src={RightChevronGreen}
                        alt="Right Chevron"
                        loading='lazy'
                      />
                    </ReferralsPendingContainer>
                    <Subtitle>
                      {state.referralsPending > 0
                        ? "Referrals Pending"
                        : "Per Referral"}
                    </Subtitle>
                  </InfoContainer>

                  <InfoContainer onClick={() => dispatch({ type: "rewards" })}>
                    <ReferralsPendingContainer>
                      <DollarValue>
                        $
                        {state.testimonialsPending > 0
                          ? state.testimonialsPending
                          : state.perTestimonial}
                      </DollarValue>
                      <ChevronIcon
                        src={RightChevronGreen}
                        alt="Right Chevron"
                        loading='lazy'
                      />
                    </ReferralsPendingContainer>
                    <Subtitle>
                      {state.testimonialsPending > 0
                        ? "Testimonials Pending"
                        : "Per Testimonial"}
                    </Subtitle>
                  </InfoContainer>
                </>
              )}
            </LinkContainer>
          </ContentContainer>
          <ButtonsContainer>
            {state.preferredCustomerStatus === "notApplied" ||
            state.preferredCustomerStatus === "closed" ||
            state.preferredCustomerStatus === "pending" ? (
              <>
                <TabButtonContainer>
                  <ApplyTab>
                    <TabText>$100 Testimonial</TabText>
                  </ApplyTab>
                  <Button
                    onClick={
                      state.preferredCustomerStatus !== "pending"
                        ? () => dispatch({ type: "preferredApplication" })
                        : () => null
                    }
                  >
                    <ApplyBadgeContainer>
                      <ApplyBadge
                        src={PreferredBadgeGray}
                        alt="Preferred Badge Gray"
                        loading='lazy'
                      />
                      <QuestionText>
                        {state.preferredCustomerStatus === "pending"
                          ? `Application Sent`
                          : `Loving   Everlight?`}
                      </QuestionText>
                      <CtaText>
                        {state.preferredCustomerStatus === "pending"
                          ? `STATUS PENDING`
                          : `APPLY TODAY`}
                      </CtaText>
                    </ApplyBadgeContainer>
                  </Button>
                </TabButtonContainer>
              </>
            ) : (
              <TabButtonContainer>
                <PreferredTab>
                  <TabText>$100 Testimonial</TabText>
                </PreferredTab>
                <PreferredBackground />
                <PreferredBadge
                  src={PreferredBadgeBlue}
                  alt="Preferred Badge Blue"
                  loading='lazy'
                />
              </TabButtonContainer>
            )}
            <TabButtonContainer>
              <ReferTab>
                <TabText>$500 Referral</TabText>
              </ReferTab>
              <Button onClick={() => dispatch({ type: "newReferral" })}>
                <ReferBadgeContainer>
                  <ReferBadge src={ReferAFriend} alt="Refer a Friend Badge" loading='lazy'/>
                  <QuestionText>EARN MONEY NOW</QuestionText>
                  <CtaText>REFER A FRIEND</CtaText>
                </ReferBadgeContainer>
              </Button>
            </TabButtonContainer>
          </ButtonsContainer>
          <Modal
            isOpen={state.showRewards}
            onClose={() => dispatch({ type: "clear" })}
          >
            <Rewards referLink={referLink} contactID={contactID} />
          </Modal>
          <Modal
            isOpen={state.showNewReferral}
            onClose={() => dispatch({ type: "clear" })}
          >
            <NewReferral dismissAction={() => dispatch({ type: "clear" })} />
          </Modal>
          <Modal
            isOpen={state.showPreferredApplication}
            onClose={() => dispatch({ type: "clear" })}
          >
            <PreferredCustomerApplication
              dismissAction={() => dispatch({ type: "clear" })}
            />
          </Modal>
        </>
      )}
    </Container>
  );
}
