import { useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications";
import { useNavigate } from "react-router-dom";

export const usePushNotifications = (sfData) => {
  const nav = useNavigate();
  const [androidToken, setAndroidToken] = useState(null);

  const registerPushNotifications = () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === "denied") {
            console.log("Push notifications permission denied");
          } else {
            console.log("Push notifications permission granted");
            registerDevice();
          }
        });
      } else {
        console.log("Push notifications permission already granted");
        registerDevice();
      }
    });
  };

  const registerDevice = async () => {
    const info = await Device.getId();
    const deviceId = info.identifier;
    const deviceInfo = await Device.getInfo();
    const platformVersion = deviceInfo.osVersion;
    PushNotifications.register();

    PushNotifications.addListener("registration", (token) => {
      console.log("AndroidTokenDeviceId set - " + token.value);
      setAndroidToken(token.value);
      sendDeviceTokenToServer(deviceId, token.value, "Android OS", platformVersion, sfData.primaryCustomerContactID);
    });

    PushNotifications.addListener("registrationError", (error) => {
      console.log("AndroidTokenDeviceId error " + error);
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log(JSON.stringify(notification));

        LocalNotifications.schedule({
          notifications: [
            {
              title: notification.data.title,
              body: notification.data.alert,
              extra: { link: notification.data._od },
              id: new Date().getUTCMilliseconds(),
            },
          ],
        });
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (response) => {
        console.log("Push Notification action performed:", JSON.stringify(response));
        console.log("response: ", response);
        await handlePushNotificationAction(response.notification.extra._od);
      }
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      async (response) => {
        console.log("Local Notification action performed:", JSON.stringify(response.notification.extra));
        await handlePushNotificationAction(response.notification.extra._od);
      }
    );
  };

  const generateRandomString = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2))
      .join('')
      .substring(0, length);
  };

  const sendDeviceTokenToServer = (deviceId, token, platform, platformVersion, contactKey) => {
    const randomString = generateRandomString(12);
    const updatedDeviceId = `${deviceId}${contactKey}${randomString}`;

    console.log("Sending device token to server:", updatedDeviceId, token, platform, platformVersion, contactKey);
    fetch('https://damp-ridge-21682.herokuapp.com/api/v1/register-device', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        deviceId: updatedDeviceId,
        deviceToken: token,
        platform: platform,
        platformVersion: platformVersion,
        contactKey: contactKey
      })
    })
    .then(response => {
      console.log('Server response status:', response.status);
      return response.json();
    })
    .then(data => console.log('Device registered with server:', data))
    .catch(error => console.error('Error registering device with server:', error));
  };

  const handlePushNotificationAction = async (link) => {
    console.log('Received link:', link);

    // Ensure the app is fully loaded before navigating
    await new Promise((resolve) => setTimeout(resolve, 500));

    if (link.includes("hoaSurvey")) {
      console.log('Navigating to /account with showHOASurvey');
      nav("/account", { state: { showHOASurvey: true } });
    } else if (link.includes("installSurvey")) {
      console.log('Navigating to /account with showInstallDateSurvey');
      nav("/account", { state: { showInstallDateSurvey: true } });
    } else if (isValidURL(link)) {
      console.log('Opening link in new tab:', link);
      window.open(link, "_blank");
    }
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return {
    registerPushNotifications,
  };
};
