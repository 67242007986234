import React from "react";
import styled from "styled-components";
import TextMyECGrayScale from '../../images/icons/TextMyECGrayScale.png';
import iPhoneRightArrowGray from '../../images/icons/iPhoneRIghtArrowGray.svg';

const TextMyECContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PhoneLink = styled.a`
    width: 100%;
`

const ContentsContainer = styled.div`
    padding: 10px 10px;
    border-radius: 16px;
    margin-top: 20px;
    color: #000;
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
`

const Section = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const MessagesIcon = styled.img`
    margin: 6px 12px;
    width: 30px;
    opacity: .7;
`

const MessageText = styled.p`
    font-size: 15px;
`

const ArrowIcon = styled.img`
    width: 10px;
`

const TextMyEC = (props) => {
    const ECPhoneNumber = "sms:" + props.sfData.energyConsultantPhoneNumber;

    return (
        <TextMyECContainer>
            <PhoneLink href={ECPhoneNumber}>
                <ContentsContainer>
                    <Section>
                        <MessagesIcon src={TextMyECGrayScale} loading='lazy'/>
                        <MessageText>Text {props.sfData.energyConsultantFirstName}, my Energy Consultant</MessageText>
                    </Section>
                    <ArrowIcon src={iPhoneRightArrowGray} loading='lazy'/>
                </ContentsContainer>
            </PhoneLink>
        </TextMyECContainer>
    )
};

export default TextMyEC;