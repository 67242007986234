import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import EverlightLogo from "./images/EverlightLogoBlack.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = (props) =>
{
  return (
    <div className="chart center">
        {
            props.chartsAreUpdating
            ?
            <>
                <Bar options={props.options} data={props.data} />
                <div className='refreshOverlay'>
                    <div className='refreshOverlayBox'>
                        <img src={EverlightLogo} loading='lazy'/>
                        <p>Refreshing your Data</p>
                    </div>
                </div>
            </>
            :
            <Bar options={props.options} data={props.data} />
        }
      
    </div>
  );
}

export default BarChart;
