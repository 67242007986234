export const colors = {
  primary: "#FFFFFF",
  white: "#FFFFFF",
  limegreen: "#75BC58",
  ctablue: "#0376EC",
  forestgreen: "#4A703F",
  preferredapplygreen: "##6FAE5B",
  darkgreen: "#428D3D",
  darkolive: "#0A4E04",
  mutedforest: "#4B723C",
  warningred: "#FFB4AB",
  gray: "#8F8E93",
};
