export default function parseCSLogin (EverlightPIN, setIsCSLogin) {
  const CS_PREFIX = 'cs-';
  if (!EverlightPIN) return '';
  if (EverlightPIN.toLowerCase().startsWith(CS_PREFIX)) {
      setIsCSLogin(true);
      return EverlightPIN.split('-')[1];
  } else {
      setIsCSLogin(false);
      return EverlightPIN;
  };
};
