import React, { useEffect } from "react";
import styled from "styled-components";
import SubmitTicketButton from "./SubmitTicketButton";
import iPhoneRightArrowGray from '../../images/icons/iPhoneRIghtArrowGray.svg';

const RequestFormContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
`

const CreatedTicket = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(212,212,216);
    border-bottom: 1px solid rgb(243, 242, 249);
    cursor: pointer;
`

const CreatedTicketDateText = styled.p`
    width: 100%;
    text-align: center;
    margin: 10px 0;
`

const RightArrow = styled.img`
    margin: 5px 10px;
    right: 0;
    width: 7px;
    filter: brightness(0);
    opacity: 0.3;
`

const CreateTicketHeader = styled.h2`
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
`

const AddressContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`

const AddressP = styled.p`
    font-size: 18px;
`

const AddressSelect = styled.select`
    font-size: 20px;
    background: none;
    color: rgb(0, 122, 255);
    margin-left: 5px;
    text-align: center;
    outline: none;
    cursor: pointer;
`

const IssueTypeSelect = styled.select`
    font-size: 18px;
    background: rgb(212,212,216);
    text-align: center;
    outline: none;
    padding: 6px 12px;
    border-radius: 100px;
    cursor: pointer;
`

const DescriptionContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`

const IssueDescription = styled.textarea`
    font-size: 20px;
    width: 90%;
    min-height: 125px;
    height: 100%;
    background: rgb(212,212,216);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    outline: none;
    padding: 12px;
    border-radius: 30px;
    margin: 10px 0;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    color: black;
    padding-top: 30px;
    resize: none;
`;

const StyledSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
        border-radius: 20px 20px 0 0;
    } 
`

const RequestForm = (props) => {
    const { issueType, setIssueType, description, setDescription, setSendButtonActive, selectedAddress } = props;

    const issueTypes = [
        'New Problem',
        'Everlight App',
        'Solar Monitoring Issue',
        'Panel Issue',
        'Inverter Issue',
        'Billing',
        'Other'
    ]

    const handleAddressChange = (e) => {
        const selectedAddressIndex = e.target.value;
        if (selectedAddressIndex !== props.selectedAddress) {
            props.setSelectedAddress(selectedAddressIndex);
        }
    };

    const handleIssueTypeChange = (e) => {
        const selectedIssueType = e.target.value;
        props.setIssueType(selectedIssueType);
        localStorage.setItem(`formIssueType${props.selectedAddress}`, selectedIssueType);
    };

    const handleDescriptionChange = (e) => {
        const description = e.target.value;
        props.setDescription(description);
        localStorage.setItem(`formDescription${props.selectedAddress}`, description);
    };

    const handleSubmit = () => {
        if (props.sendButtonActive) {
            props.setConfirmOverlayActive(true);
        }
    };

    // Validate data for submit
    useEffect(() => {
        const isDataValid = description.length > 0 && issueType !== null && issueType !== 'None';
        setSendButtonActive(isDataValid);
    }, [description, issueType, setSendButtonActive]);

    // Restore form data for each address
    useEffect(() => {
        setIssueType(() => {
            const formIssueType = localStorage.getItem(`formIssueType${selectedAddress}`);
            if (formIssueType) {
                return formIssueType;
            }
            else {
                return 'None';
            }
        })
        setDescription(() => {
            const formDescription = localStorage.getItem(`formDescription${selectedAddress}`);
            if (formDescription) {
                return formDescription;
            }
            else {
                return '';
            }
        })
    }, [selectedAddress, setIssueType, setDescription]);

    return (
        <RequestFormContainer>
            <StyledSection>
                {props.supportTickets
                ? props.supportTickets.map((ticket, index) => {
                    if (ticket.Opportunity__c !== props.sfData.opportunityId) {
                        return null;
                    }

                    const date = new Date(ticket.CreatedDate).toLocaleDateString('en-US');
                    return (
                        <CreatedTicket key={index} onClick={() => props.setSelectedTicket(index)}>
                            <CreatedTicketDateText>Ticket Created: {date}</CreatedTicketDateText>
                            <RightArrow src={iPhoneRightArrowGray} loading='lazy'/>
                        </CreatedTicket>
                    )
                })
                : null
                }
            </StyledSection>

            <StyledSection>
                <CreateTicketHeader>Create a Support Ticket</CreateTicketHeader>
                <AddressContainer>
                    <AddressP>For</AddressP>
                    <AddressSelect onChange={handleAddressChange} value={props.selectedAddress}>
                        {props.sfDataAll && props.sfDataAll != {} && props.sfDataAll[0] // Equivalent to checking for sfDataAll[0]
                            ? props.sfDataAll.map((opportunity, index) => (
                                <option value={index} key={index}>
                                    {opportunity.projectAddress}
                                </option>
                            ))
                            : null
                        }
                    </AddressSelect>
                </AddressContainer>
            </StyledSection>

            <StyledSection>
                <IssueTypeSelect onChange={handleIssueTypeChange} value={props.issueType}>
                    <option value={'None'}>Choose Issue Type</option>
                    {issueTypes.map((type, index) => {
                        return <option value={type} key={index}>{type}</option>
                    })}
                </IssueTypeSelect>
                <DescriptionContainer>
                    <IssueDescription
                        placeholder="Please describe the issue in detail"
                        value={props.description}
                        onChange={handleDescriptionChange}
                    />
                </DescriptionContainer>
            </StyledSection>

            <SubmitTicketButton active={props.sendButtonActive} handleSubmit={handleSubmit} />
        </RequestFormContainer>
    );
};

export default RequestForm;