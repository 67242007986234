import React, { useState } from 'react';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { StripeProvider, Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useEffect } from 'react';
import './PaymentForm.css'
import checkMark from './images/icons/CheckGreen.svg'



const cardElementOptions = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

function PaymentForm({ accountName, oppAddressLine1, togglePayment, props }) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [name, setName] = useState('');
    const [address, setAddress] = useState(props.selectedAddress ? props.selectedAddress : '');
    const [amount, setAmount] = useState('');
    const [selectedOption, setSelectedOption] = useState();
    const [description, setDescription] = useState('')
    const [confirmationNumber, setConfirmationNumber] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [confirmationScreen, setConfirmationScreen] = useState(false)
    const [paymentScreen, setPaymentScreen] = useState(true)

    const options = [
        {
            value: 0,
            label: '--None--',
            description: ''
        },
        {
            value: (props.sfData.numberOfPanels * 10) + (props.sfData.arrayCount * 100) + 275,
            label: 'Critter Guard',
            description: 'Adding a Critter Guard will prevent squirrels and other wildlife from climbing up and gaining access to your panels, subsequently causing damage.'
        },
        {
            value: 20000,
            label: 'Item 2 - $20.00',
            description: 'Example'
        },
        {
            value: 30000,
            label: 'Item 3 - $30.00',
            description: 'Example'
        },
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name,
                address: {
                    line1: address,
                },
            },
            metadata: {
                why: userInput
            }
        });

        if (error) {
            setError(error.message);
        } else {
            setError(null);
            try {
                // Send a charge request to the server
                const response = await axios.post('http://localhost:3500/create-charge', {
                    source: paymentMethod.id,
                    amount: amount,
                    selectedOption: selectedOption,
                    metadata: { why: userInput }

                });
                const clientSecret = response.data.client_secret;
                const result = await stripe.confirmCardPayment(clientSecret)
                if (response.status == 200) {
                    toggleConfirmation()
                }
                if (result.paymentIntent) {
                    setConfirmationNumber(result.paymentIntent.created)
                    // console.log(result.paymentIntent.id)
                    // console.log(result.paymentIntent)
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChange = (event) => {
        setError(event.error ? event.error.message : '');
        setCardComplete(event.complete);
    };

    const toggleConfirmation = () => {
        setPaymentScreen(!paymentScreen)
        setConfirmationScreen(!confirmationScreen)
    }

    const closeOut = () => {
        setConfirmationScreen(!confirmationScreen)
        togglePayment()

    }

    useEffect(() => {
        // console.log("- - - - - useEffect 20")
        function checkForData() {
            if (accountName) {
                setName(accountName)
            }
            if (oppAddressLine1 && props.sfv4data.length < 2) {
                setAddress(oppAddressLine1)
            }

        }
        checkForData()

    }, [accountName, oppAddressLine1])

    useEffect(() => {
        if (selectedOption) {
            // console.log("- - - - - useEffect 21")
            setAmount(selectedOption.value);
            setDescription(selectedOption.description)
            // console.log(selectedOption)
        }
    }, [selectedOption, props.selectedAddress])

    useEffect(() => {
        // console.log("- - - - - useEffect 22")
        setAmount(0);
        setDescription('')
    }, [address]);

    return (
        <div id='paymentFormWrap'>
            <div className='paymentFormPopup paymentFormoverlay'>

                {paymentScreen && (

                    <form onSubmit={handleSubmit} className="paymentFormPopup-content center-text">

                        <h4 className='bold'>Upgrade Your System</h4>
                        <div className='margin20px'></div>
                        <label className='paymentFormjustifyEven'>

                            <div style={{ paddingRight: '25px', display: 'flex' }}><div style={{ color: 'red' }}>*</div>Name:</div>
                            <input type="text" value={name} onChange={(event) => (setName(event.target.value))} style={{ textAlign: 'center', height: '30px', borderRadius: 10, width: '199px' }} required={true} />

                        </label>

                        <div className='margin10px'></div>

                        <label className='paymentFormjustifyEven'>

                            <div style={{ paddingRight: '11px', display: 'flex' }}>
                                <div style={{ color: 'red' }}>*</div>Address:</div>

                            {props.sfv4data.length > 1 ? (
                                <select
                                    value={address}
                                    onChange={(event) => { setAddress(event.target.value); props.setSelectedAddress(event.target.value) }}
                                    style={{ textAlign: 'center', height: '30px', borderRadius: 10 }}
                                    required={true}
                                >
                                    {props.sfv4data.map((opportunity, index) => (
                                        <option value={index} key={index}>
                                            {opportunity.projectAddress}
                                        </option>
                                    ))}
                                </select>) : (<input
                                    type="text"
                                    value={address}
                                    onChange={(event) => setAddress(event.target.value)}
                                    style={{ textAlign: 'center', height: '30px', borderRadius: 10, width: '199px' }}
                                    required={true} />)

                            }



                        </label>

                        <div className='margin10px'></div>

                        <label className='paymentFormjustifyEven'>

                            <div className='upgradeSection'><div style={{ color: 'red' }}>*</div>Upgrade:</div>

                            <select
                                value={selectedOption ? selectedOption.value : ""}
                                onChange={(event) => setSelectedOption(options.find(option => option.value === parseInt(event.target.value)))}
                                className='upgradeSelector'
                                required={true}
                            >
                                {options.map(option => (
                                    <option key={option.label} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>

                        </label>

                        <div className='margin10px'></div>

                        <label className='paymentFormjustifyEven'>

                            <div className='amountSection'>Amount</div>
                            <div className='amountInput'>{amount ? '$' + amount + '.00' : amount}</div>

                        </label>

                        <div className='margin10px'></div>

                        <div className='paymentFormjustifyEven'>

                            <div className='descriptionSection'>Description</div>
                            <div className='description'>{description}</div>

                        </div>

                        <div className='margin10px'></div>

                        <label className='paymentFormjustifyEven alignCenter'>

                            {/* <div style={{ paddingRight: '14px' }}>Why do you want an upgrade?</div> */}
                            <input type="text" value={userInput} maxLength={500} placeholder={'Why do you want an upgrade?'} onChange={(event) => setUserInput(event.target.value)} className='whyUpgradeInput' required={true} />

                        </label>
                        <div className='margin20px'></div>
                        <label>

                            <div className='margin10px'></div>
                            <CardElement options={cardElementOptions} onChange={handleChange} />
                        </label>

                        <div className='margin10px'></div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                            <button disabled={!stripe || !cardComplete} style={!stripe || !cardComplete ?
                                { backgroundColor: 'gray', width: '60px', height: '35px', borderRadius: 50, color: 'white', marginRight: '10px' } : { backgroundColor: 'rgb(8, 127, 249)', width: '60px', height: '35px', borderRadius: 50, color: 'white', marginRight: '10px' }}>Pay</button>

                            <button onClick={togglePayment} style={{ color: 'red', width: '60px' }}>Cancel</button>
                        </div>
                    </form>
                )}

                {confirmationScreen && (
                    <div className='paymentFormPopup paymentFormoverlay'>
                        <div className="paymentFormPopup-content center-text">
                            <img src={checkMark} loading='lazy'/>
                            <h4 className='bold'>You're All Set!</h4>
                            <h4>Confirmation #:</h4>
                            <div>{confirmationNumber}</div>
                            <button style={{ paddingTop: '10px', color: 'blue', fontWeight: 'bold' }} onClick={() => closeOut()}>Copy & Close</button>
                        </div>
                    </div>


                )}
            </div >




        </div>


    );
}



export default PaymentForm