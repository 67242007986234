function safeRead(value, fallback)
{
    if(value == null)
        return fallback
    return value
}

function safeReadFull(value, fallback, unallowedValues)
{
    if(value in unallowedValues)
        return fallback
    return value
}

let nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
    });

function formatMoney(money)
{
    return nf.format(money)
}

const cssVar = function(name)
{
	return window.getComputedStyle(document.getElementById("root")).getPropertyValue(name);
}

const monthNamesFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export const monthNames = monthNamesFull.map((name) => {
	return name.substring(0,3);
})

function formatDate2(dateString) 
{
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    let hours = date.getHours() - 1;
    let amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; // Convert to 12-hour format
    hours = hours.toString().padStart(2, '0');

    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${hours}:${minutes} ${amOrPm} on ${month}/${day}/${year}`;
    return formattedDate;
}

export { safeRead, safeReadFull, formatMoney, cssVar, formatDate2 }