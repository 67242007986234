import * as Constants from './Constants'

function retrieveSupportTicketData(pin)
{
    // console.log('! ! ! ! ! ! ! SUPPORT CASE DATA')
    return new Promise((resolve, reject) => {
        fetch(Constants.JS_BACKEND_LIVE_URL + '/supportTicketsRetrieve', {
            method: 'GET',
            headers: {
                'EverlightPIN': pin
            }
        })
        .then(response => {
            // console.log('& & & & & Finished retrieving support ticket data')
            // console.log(response.status)
            if(!response.ok)
                throw new Error(String(response.status))
            else
            {
                resolve(response.json())
            }
        })
        .catch(error => {
            // console.log('& & & & & Failed to retrieve support ticket data')
            reject(error)
        })
    })
    
}

function postSupportTicket(pin, oppId, type, description)
{
    return new Promise((resolve, reject) => {

    fetch(Constants.JS_BACKEND_LIVE_URL + '/supportTicketsCreate', {
        method: 'post',
        headers: {
            'EverlightPIN': pin,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "Status": "New",
            "AccountId": pin,
            "Opportunity__c": oppId,
            "Type": "Everlight App",
            "Origin": "Everlight App",
            "Install_Type__c": "Initial Install",
            "Description": description,
            "Reason": type,
            "iOS__c": false,
            "Android_Web__c": true,
            "App_Version__c": String(Constants.currentAppVersion),
            
        })
    })
    .then(response => {
        console.log(response.status)
        if(!response.ok)
            throw new Error(String(response.status))
        else
        {
            console.log('Support ticket added successfully')
            resolve(response.json())
        }
    })
    .catch(error => {
        console.log('Support ticket failed to add', error)
        reject(error)
    })

    })
}

export { retrieveSupportTicketData, postSupportTicket };