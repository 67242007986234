import React from 'react';
import { LocalNotifications } from '@capacitor/local-notifications';
import styled from 'styled-components';

const NotificationButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  position:absolute;
  top:20%;
  right:20%;
`;

const mockPushNotification = () => {
  const mockNotification = {
    id: "0:1718921738554977%0aea4256f9fd7ecd",
    data: {
      _h: "3kuJYBzYxIeYTrW/7U9ELwAAAAAA",
      _m: "ODc4OjExNDow",
      _r: "5a486cc2-25ae-41c3-b8a6-ea474c571a10",
      _mt: "1",
      _od: "everlightm",
      _sid: "SFMC",
      alert: "You've successfully registered for push notifications - we'll be in touch! ",
      sound: "default",
      title: "Welcome to Everlight Solar"
    }
  };

  // Simulate the Local Notification
  LocalNotifications.schedule({
    notifications: [
      {
        title: mockNotification.data.title,
        body: mockNotification.data.alert,
        id: new Date().getUTCMilliseconds(),
        extra: mockNotification.data,
      },
    ],
  });
};

const PushNotificationComponent = () => {
  return (
    <div>
      <NotificationButton onClick={mockPushNotification}>
        Send Mock Notification
      </NotificationButton>
    </div>
  );
};

export default PushNotificationComponent;
