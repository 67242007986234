import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PaperPlaneIcon } from '../../images/icons/PaperPlane.svg';

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    margin: 10px 0 30px 0;
    cursor: pointer;
    background: ${props => props.active ? 'linear-gradient(to right, rgba(57, 194, 93, 0.75), rgba(101, 184, 81, 0.75));' : 'none'};
    padding: 10px 20px;
    border-radius: 10px;
    width: 90%;
`;

const SendIcon = styled(PaperPlaneIcon)`
    width: 20px;
    margin-right: 10px;
    transform: rotate(-45deg);
    padding-bottom: 10px;
`;

const SubmitTicketButton = (props) => {
    return (
        <ButtonContainer
            active={props.active}
            onClick={props.handleSubmit}
        >
            <SendIcon />
            <p>Send & Create Support Ticket</p>
        </ButtonContainer>
    );
};

export default SubmitTicketButton;