import React from "react";
import styled, { keyframes } from "styled-components";
import Spinner from "../../images/icons/Spinner.svg";

const LoadingContainer = styled.div`
    @media screen and (min-width: 601px) 
    {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 9999999999;
    }

    @media screen and (max-width: 600px) 
    {
        position: fixed;
        display: grid;
        place-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(243, 242, 249);
    }
`

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

const LoadingImg = styled.img`
    width: 100px;
    height: 100px;
    opacity: 0.2;
    animation: ${rotate} 0.5s linear infinite;
`


const Loading = () => {
    return (
        <LoadingContainer>
            <LoadingImg src={Spinner} alt="Loading" loading='lazy'/>
        </LoadingContainer>
    );
};

export default Loading;