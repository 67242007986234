import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "./colors";
const PicklistContainer = styled.div`
  display: flex;
  flex: 1;
`;

const PicklistSelect = styled.select`
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  border-radius: 5px;
  font-size: 16px;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><path d="M0 0h12L6 6z" fill="%23000000"/></svg>');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 12px;
  background-color: ${(props) => (props.invalid ? colors.warningred : "white")};
  border: 1px solid ${(props) => (props.invalid ? colors.warningred : "#ccc")};
`;

const PicklistOption = styled.option`
  padding: 8px;
`;
const Picklist = ({ options, value, onChange, invalid }) => {
  const [selectedValue, setSelectedValue] = useState(value || "");
  console.log(invalid);
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <PicklistContainer>
      <PicklistSelect
        value={selectedValue}
        onChange={handleChange}
        invalid={invalid}
      >
        {options.map((option) => (
          <PicklistOption key={option.value} value={option.value}>
            {option.label}
          </PicklistOption>
        ))}
      </PicklistSelect>
    </PicklistContainer>
  );
};

export default Picklist;
