export const JS_BACKEND_LOCAL_URL = "http://localhost:3500";
export const JS_BACKEND_LIVE_URL = "https://damp-ridge-21682.herokuapp.com";

export const JS_BACKEND_URL = JS_BACKEND_LIVE_URL;

export const PY_BACKEND_URL =
  "https://everlightenergypythonbackend.herokuapp.com";

export const SF_ENHANCED_URL =
  "https://everlightsolar.my.salesforce-sites.com/hogtie/services/apexrest";
export const SF_ORIGINAL_URL =
  "https://everlight.secure.force.com/hogtie/services/apexrest";

export const currentAppVersion = "1.8.7";
