import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../App.css";
import "./AccountPage.css";
import EverlightLogo from "../images/EverlightLogo.svg";
import EverlightLogoGreen from "../images/EverlightLogoGreen.png";
import EverlightLogoBackground from "../images/EverlightLogoBlack.png";
import EverlightPadLock from "../images/icons/EverlightPadLock.svg";
import WalletGreen from "../images/icons/WalletGreen.png";
import EverlightPadLockGreen from "../images/icons/EverlightPadLockGreen.svg";
import EverlightPadLockGray from "../images/icons/EverlightPadLockGray.svg";
import EverlightMoneyBag from "../images/icons/EverlightMoneyBag.svg";
import EverlightMoneyBagGreen from "../images/icons/EverlightMoneyBagGreen.svg";
import EverlightMoneyBagGray from "../images/icons/EverlightMoneyBagGray.svg";
import customerSuccessRep from "../images/customerSuccessRep.png";
import iPhoneRightArrow from "../images/icons/iPhoneRIghtArrowGray.svg";
import iPhoneRightArrowWhite from "../images/icons/iPhoneRIghtArrowWhite.svg";
import UploadButton from "../UploadButton";
import HOASurveyForm from "../HOASurveyForm";
import InstallDateSurvey from "../InstallDateSurvey";
import AlertBanner from "../AlertBanner";
import CloudCheck from "../images/icons/CloudCheck.svg";
import CloudCheckGray from "../images/icons/CloudCheckGray.svg";
import WiFiGreen from "../images/icons/WiFiGreen.svg";
import WiFiRed from "../images/icons/WiFiRed.svg";
import Check from "../images/icons/Check.svg";
import CheckBlue from "../images/icons/CheckBlue.svg";
import UploadIcon from "../images/icons/arrow.svg";
import House from "../images/icons/House.svg";
import mappin from "../images/icons/mappin.svg";
import rocket from "../images/icons/rocket-solid.svg";
import arrowGray from "../images/icons/arrowGray.svg";
import arrowToLeft from "../images/icons/arrow-left-to-line-solid.svg";
import arrowFromRight from "../images/icons/arrow-right-from-line-solid.svg";
import Monitor from "../images/icons/monitor-waveform-solid.svg";
import MonitorGray from "../images/icons/monitorGray.svg";
import Panel from "../images/icons/PanelGreen.png";
import PanelGray from "../images/icons/PanelGray.png";
import Popup from "../Popup";
import { Capacitor } from "@capacitor/core";
import PaymentForm from "../PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useWindowWidth } from "../functions/useWindowWidth";
import SupportPage from "./SupportPage";
import SavingsPage from "./SavingsPage";
import SystemPage from "./SystemPage";
import EnergyUsagePage from "./EnergyUsagePage";
import EnergyProductionPage from "./EnergyProductionPage";
import EverlightBoltGray from "../images/icons/EverlightBoltGray.svg";
import EverlightBoltBlue from "../images/icons/EverlightBoltBlue.svg";
import HouseGray from "../images/icons/HouseGray.png";
import HouseGreen from "../images/icons/HouseGreen.png";
import houseDefault from "../images/house copy.png";
import CircleChart from "../CircleChart";
import {
  safeRead,
  safeReadFull,
  formatMoney,
  formatDate2,
} from "../functions/FunctionsInGeneral";
import moment from "moment";
import map from "../images/map.png";
import WiFiSurvey from "../WiFiSurvey";
import UpgradePopup from "../components/UpgradePopup";
import MarketingPopup from "../components/MarketingPopup";
import axios from "axios";
import Wallet from "../components/referrals/Wallet";
import styled from "styled-components";
import * as Constants from "../functions/Constants";
import { colors } from "../common/colors";
const RocketIcon = require("../images/icons/rocket-launch-fill.png");

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 33.2vw;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const WalletContainer = styled.div`
  position: absolute;
  top: 0;
  left: 33.2vw;
  width: 550px;
  height: 100%;
  background-color: ${colors.white};
  z-index: 200;
`;
const CollapsedWalletContainer = styled.div`
  position: absolute;
  top: 0;
  left: 100px;
  width: 550px;
  height: 100%;
  background-color: ${colors.white};
  z-index: 200;
`;
const StyledRocket = styled.img`
  flex: 1;
  display: flex;
  position: absolute;
`;

function AccountPage(props) {
  // const stripePromise = loadStripe(
  //   "pk_test_51MMyp9Hau8UBH2Kw2xc9tDcpAv6yoAXdFYoJF1Jjk8HfGNveeejrZd9VkvUV0fqymNavYNp3w8SmbUFYoyPqt8fQ00OsDwTYb2"
  // );

  const [showHelp, setShowHelp] = useState(false);
  const [showMessage, setMessage] = useState(false);
  const [surveyNotReady, setSurveyNotReady] = useState(false);
  const [fileUploadFailurePopup, showFileUploadFailurePopup] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentClass, setShowPaymentClass] = useState("upgradeSection");
  const [isRepWebView, setIsRepWebView] = useState(false);
  const [isReferWebView, setIsReferWebView] = useState(false);
  const [isWebsiteWebView, setIsWebsiteWebView] = useState(false);
  const [isWebView, setIsWebView] = useState(true);
  const [isNavView, setIsNavView] = useState(false);
  const [energyProductionPage, setEnergyProductionPage] = useState(true);
  const [systemsPage, setSystemsPage] = useState(false);
  const [savingsPage, setSavinngsPage] = useState(false);
  const [energyUsage, setEnergyUsage] = useState(false);
  const [boltLogo, setBoltLogo] = useState(EverlightBoltGray);
  const [productionLogo, setProductionLogo] = useState(HouseGreen);
  const [energyGraphTitle, setEnergyGraphTitle] = useState(
    "HOME ENERGY PRODUCTION (KWH)"
  );
  const [pinButtonClass, setPinButtonClass] = useState("infoSection");
  const [repButtonClass, setRepButtonClass] = useState("infoSection");
  const [referAFriendButtonClass, setReferAFriendButtonClass] =
    useState("infoSection");
  const [websiteClass, setWebsiteClass] = useState("infoSection");
  const [hoaClass, setHoaClass] = useState("uploadSection");
  const [wiFiClass, setWiFiClass] = useState("uploadSection");
  const [installDateClass, setInstallDateClass] = useState("uploadSection");
  const [uploadInsuranceClass, setUploadInsuranceClass] =
    useState("uploadSection");
  const [purchaseUpgradeClass, setPurchaseUgradeClass] =
    useState("uploadSection");
  const [energyMonitorClass, setEnergyMonitorClass] =
    useState("selectedMonitors");
  const [walletClass, setWalletClass] = useState("selectedMonitors");
  const [energySystemClass, setEnergySystemClass] = useState("solarSystem");
  const [usage, setUsage] = useState("grayText");
  const [production, setProduction] = useState("prodGreenText");
  const [navViewPadlock, setNavViewPadLock] = useState(EverlightPadLockGray);
  const [navViewDollarSign, setNavViewDollarSign] = useState(
    EverlightMoneyBagGray
  );
  const [navViewWebIcon, setNavViewWebIcon] = useState(EverlightLogo);
  const [navViewMonitor, setNavViewMonitor] = useState(Monitor);
  const [navViewPanel, setNavViewPanel] = useState(PanelGray);
  const [navViewPinClass, setNavViewPinClass] = useState("none");
  const [navViewRepClass, setNavViewRepClass] = useState("borderRadius15");
  const [navViewReferClass, setNavViewReferClass] = useState("none");
  const [navViewWebClass, setNavViewWebClass] = useState("none");
  const [navViewEnergyClass, setNavViewEnergyClass] = useState("rightBorder");
  const [navViewSystemsClass, setNavViewSystemsClass] = useState("none");
  const [showWallet, setShowWallet] = useState(false);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    setWalletClass(showWallet ? "selectedMonitors" : "monitors");
  }, [showWallet]);

  const handleRepWebView = () => {
    setIsRepWebView(!isRepWebView);
    setRepButtonClass(
      repButtonClass == "infoSection"
        ? "infoSection selectedInfoSectionNoRadius"
        : "infoSection"
    );
    setPinButtonClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setShowHelp(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setMessage(false);
    setNavViewPadLock(EverlightPadLockGray);

    setNavViewRepClass(
      navViewRepClass == "rightBorder borderRadius15"
        ? "borderRadius15"
        : "rightBorder borderRadius15"
    );
    setNavViewPinClass("none");
    setShowWallet(false);
    setShowPaymentClass("upgradeSection");
    setShowPayment(false);
    setWebsiteClass("infoSection");
    setIsWebsiteWebView(false);
  };

  const handleReferWebView = () => {
    setReferAFriendButtonClass(
      referAFriendButtonClass == "infoSection"
        ? "infoSection selectedInfoSectionNoRadius"
        : "infoSection"
    );
    setRepButtonClass("infoSection");
    setPinButtonClass("infoSection");
    setWebsiteClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setIsRepWebView(false);
    setShowHelp(false);
    setIsReferWebView(!isReferWebView);
    setIsWebsiteWebView(false);
    setSystemsPage(false);
    setEnergyProductionPage(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setShowPayment(false);
    setShowPaymentClass("upgradeSection");
    setNavViewDollarSign(
      navViewDollarSign == EverlightMoneyBagGray
        ? EverlightMoneyBagGreen
        : EverlightMoneyBagGray
    );
    setNavViewReferClass(
      navViewReferClass == "rightBorder" ? "none" : "rightBorder"
    );
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewWebIcon(EverlightLogo);
    setNavViewRepClass("borderRadius15");
    setNavViewPinClass("none");
    setNavViewWebClass("none");
    setNavViewEnergyClass("none");
    setNavViewMonitor(MonitorGray);
    setNavViewPanel(PanelGray);
    setNavViewSystemsClass("none");
    setEnergyMonitorClass("monitors");
    setEnergySystemClass("solarSystem");
    setShowWallet(false);
  };

  const handleWebsiteWebView = () => {
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setWebsiteClass(
        websiteClass == "infoSection"
          ? "infoSection selectedInfoSectionBottomRadius"
          : "infoSection"
      );
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      setReferAFriendButtonClass("infoSection");
      setHoaClass("uploadSection");
      setWiFiClass("uploadSection");
      setInstallDateClass("uploadSection");
      setUploadInsuranceClass("uploadSection");
      setPurchaseUgradeClass("uploadSection");
      setIsRepWebView(false);
      setShowHelp(false);
      setIsReferWebView(false);
      setIsWebsiteWebView(!isWebsiteWebView);
      setSystemsPage(false);
      setEnergyProductionPage(false);
      props.setShowHOASurvey(false);
      props.setShowWiFiSurvey(false);
      setSurveyNotReady(false);
      props.setShowInstallDateSurvey(false);
      setSavinngsPage(false);
      setShowPayment(false);
      setShowPaymentClass("upgradeSection");
      setNavViewWebIcon(
        navViewWebIcon == EverlightLogo ? EverlightLogoGreen : EverlightLogo
      );
      setNavViewWebClass(navViewWebClass == "rightBorder" ? "" : "rightBorder");
      setNavViewPadLock(EverlightPadLockGray);
      setNavViewDollarSign(EverlightMoneyBagGray);
      setNavViewRepClass("borderRadius15");
      setNavViewPinClass("none");
      setNavViewReferClass("none");
      setNavViewEnergyClass("none");
      setNavViewMonitor(MonitorGray);
      setNavViewPanel(PanelGray);
      setNavViewSystemsClass("none");
      setEnergyMonitorClass("monitors");
      setEnergySystemClass("solarSystem");
      setShowWallet(false);
    } else {
      return null;
    }
  };

  const handleWebView = () => {
    setIsWebView(!isWebView);
    setIsNavView(!isNavView);
  };

  const togglePopup = () => {
    // Your Everlight PIN View
    setShowHelp(!showHelp);
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setPinButtonClass(
        pinButtonClass == "infoSection"
          ? "infoSection selectedInfoSection"
          : "infoSection"
      );
      setRepButtonClass("infoSection");
      setHoaClass("uploadSection");
      setWiFiClass("uploadSection");
      setInstallDateClass("uploadSection");
      setUploadInsuranceClass("uploadSection");
      setPurchaseUgradeClass("uploadSection");
      setIsRepWebView(false);
      props.setShowHOASurvey(false);
      props.setShowWiFiSurvey(false);
      setSurveyNotReady(false);
      props.setShowInstallDateSurvey(false);
      setSavinngsPage(false);
      setMessage(false);
      setNavViewPadLock(
        navViewPadlock == EverlightPadLockGray
          ? EverlightPadLockGreen
          : EverlightPadLockGray
      );
      setNavViewPinClass(
        navViewPinClass == "rightBorder" ? "none" : "rightBorder"
      );
      setNavViewRepClass("borderRadius15");
      setShowWallet(false);
      setShowPaymentClass("upgradeSection");
      setShowPayment(false);
      setWebsiteClass("infoSection");
      setIsWebsiteWebView(false);
    } else {
      return null;
    }
  };

  const handleEnergyPage = () => {
    setEnergyMonitorClass(
      energyMonitorClass == "monitors" ? "selectedMonitors" : "monitors"
    );
    setEnergySystemClass("solarSystem");
    setEnergyProductionPage(true);
    setIsRepWebView(false);
    setIsReferWebView(false);
    setIsWebsiteWebView(false);
    setShowHelp(false);
    setSystemsPage(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setMessage(false);
    setShowPayment(false);
    setShowPaymentClass("upgradeSection");
    setNavViewEnergyClass(
      navViewEnergyClass == "rightBorder" ? "none" : "rightBorder"
    );
    setNavViewMonitor(navViewMonitor == MonitorGray ? Monitor : MonitorGray);
    setNavViewPanel(PanelGray);
    setNavViewSystemsClass("none");
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewPinClass("none");
    setNavViewDollarSign(EverlightMoneyBagGray);
    setNavViewWebIcon(EverlightLogo);
    setNavViewWebClass("none");
    setNavViewRepClass("borderRadius15");
    setNavViewReferClass("none");
    setWebsiteClass("infoSection");
    setRepButtonClass("infoSection");
    setPinButtonClass("infoSection");
    setReferAFriendButtonClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setShowWallet(false);
  };

  const handleRewardsWallet = () => {
    setEnergyMonitorClass(
      energyMonitorClass == "wallet" ? "selectedWallet" : "wallet"
    );
    setEnergySystemClass("solarSystem");
    setIsRepWebView(false);
    setIsReferWebView(false);
    setIsWebsiteWebView(false);
    setShowHelp(false);
    setSystemsPage(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setMessage(false);
    setShowPayment(false);
    setShowPaymentClass("upgradeSection");
    setNavViewEnergyClass(
      navViewEnergyClass == "rightBorder" ? "none" : "rightBorder"
    );
    setNavViewMonitor(navViewMonitor == MonitorGray ? Monitor : MonitorGray);
    setNavViewPanel(PanelGray);
    setNavViewSystemsClass("none");
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewPinClass("none");
    setNavViewDollarSign(EverlightMoneyBagGray);
    setNavViewWebIcon(EverlightLogo);
    setNavViewWebClass("none");
    setNavViewRepClass("borderRadius15");
    setNavViewReferClass("none");
    setWebsiteClass("infoSection");
    setRepButtonClass("infoSection");
    setPinButtonClass("infoSection");
    setReferAFriendButtonClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setShowWallet(!showWallet);
    setEnergyProductionPage(true);
  };
  const handleSystemsPage = () => {
    setEnergySystemClass(
      energySystemClass == "solarSystem" ? "selectedSolarSystem" : "solarSystem"
    );
    setEnergyMonitorClass("monitors");
    setSystemsPage(!systemsPage);
    setEnergyProductionPage(false);
    setIsRepWebView(false);
    setIsReferWebView(false);
    setIsWebsiteWebView(false);
    setShowHelp(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setMessage(false);
    setShowPayment(false);
    setShowPaymentClass("upgradeSection");
    setNavViewSystemsClass(
      navViewSystemsClass == "rightBorder" ? "none" : "rightBorder"
    );
    setNavViewPanel(navViewPanel == PanelGray ? Panel : PanelGray);
    setNavViewEnergyClass("none");
    setNavViewMonitor(MonitorGray);
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewPinClass("none");
    setNavViewDollarSign(EverlightMoneyBagGray);
    setNavViewWebIcon(EverlightLogo);
    setNavViewWebClass("none");
    setNavViewRepClass("borderRadius15");
    setNavViewReferClass("none");
    setWebsiteClass("infoSection");
    setRepButtonClass("infoSection");
    setPinButtonClass("infoSection");
    setReferAFriendButtonClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setShowWallet(false);
  };

  const handleSurveyPopup = () => {
    // HOA Survey Popup
    props.setShowHOASurvey(!props.showHOASurvey);
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setIsRepWebView(false);
      setShowHelp(false);
      setSurveyNotReady(false);
      props.setShowInstallDateSurvey(false);
      props.setShowWiFiSurvey(false);
      setSavinngsPage(false);
      setMessage(false);
      setHoaClass(
        hoaClass == "uploadSection" ? "selectedUploadSection" : "uploadSection"
      );
      setWiFiClass("uploadSection");
      setInstallDateClass("uploadSection");
      setUploadInsuranceClass("uploadSection");
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      setShowWallet(false);
    } else {
      return null;
    }
  };

  const handleWiFiPopup = () => {
    // Wi-Fi Form Popup
    props.setShowWiFiSurvey(!props.showWiFiSurvey);
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setIsRepWebView(false);
      setShowHelp(false);
      setSurveyNotReady(false);
      props.setShowHOASurvey(false);
      props.setShowInstallDateSurvey(false);
      setSavinngsPage(false);
      setMessage(false);
      setHoaClass("uploadSection");
      setWiFiClass(
        wiFiClass === "uploadSection"
          ? "selectedUploadSection"
          : "uploadSection"
      );
      setInstallDateClass("uploadSection");
      setUploadInsuranceClass("uploadSection");
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      setShowWallet(false);
    } else {
      return null;
    }
  };

  const handleInstallDatePopup = () => {
    // Install Survey Popup
    checkInstallSurvey();
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setIsRepWebView(false);
      setShowHelp(false);
      props.setShowHOASurvey(false);
      props.setShowWiFiSurvey(false);
      setSavinngsPage(false);
      setMessage(false);
      setHoaClass("uploadSection");
      setWiFiClass("uploadSection");
      setInstallDateClass(
        installDateClass == "uploadSection"
          ? "selectedUploadSection"
          : "uploadSection"
      );
      setUploadInsuranceClass("uploadSection");
      setPurchaseUgradeClass("uploadSection");
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      setShowWallet(false);
    } else {
      return null;
    }
  };

  const checkInstallSurvey = () => {
    if (props.installSurveyReady && props.sfData.installationScheduleDate) {
      props.setShowInstallDateSurvey(!props.showInstallDateSurvey);
    } else {
      if (buttonInstallClass == "red-bg-upload leftText") {
        return null;
      } else {
        toggleSurveyNotReady();
      }
    }
  };

  const toggleEnergyUsage = () => {
    setEnergyUsage(!energyUsage);
    setBoltLogo(
      boltLogo == EverlightBoltBlue ? EverlightBoltGray : EverlightBoltBlue
    );

    setProductionLogo(productionLogo == HouseGray ? HouseGreen : HouseGray);

    setEnergyGraphTitle(
      energyGraphTitle == "HOME ENERGY USAGE (KWH)"
        ? "HOME ENERGY PRODUCTION (KWH)"
        : "HOME ENERGY USAGE (KWH)"
    );

    setUsage(boltLogo == EverlightBoltGray ? "blueText" : "grayText");

    setProduction(productionLogo == HouseGray ? "prodGreenText" : "grayText");
  };

  useEffect(() => {
    if (windowWidth < 600 || props.userIsOnMobilePhone) {
      // console.log("- - - - - useEffect 29 - configure stuff for account page when appropriate, like mobile device.")
      setIsRepWebView(false);
      setShowHelp(false);
      setSurveyNotReady(false);
      setSavinngsPage(false);
      setMessage(false);
      setIsNavView(false);
      setIsWebView(true);
      setShowPayment(false);
      setShowPaymentClass("upgradeSection");
      setHoaClass("uploadSection");
      setWiFiClass("uploadSection");
      setInstallDateClass("uploadSection");
      setUploadInsuranceClass("uploadSection");
      setPurchaseUgradeClass("uploadSection");
      // setReferAFriendButtonClass('infoSection')
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      // setWebsiteClass('infoSection')
      setShowPaymentClass("upgradeSection");
      setShowWallet(false);
    }
  }, [windowWidth]);

  const toggleMessage = () => {
    // Insurance Popup
    setMessage(!showMessage);
    if (windowWidth > 600 && !props.userIsOnMobilePhone) {
      setUploadInsuranceClass(
        uploadInsuranceClass == "uploadSection"
          ? "selectedUploadSection"
          : "uploadSection"
      );
      setShowHelp(false);
      props.setShowHOASurvey(false);
      props.setShowInstallDateSurvey(false);
      props.setShowWiFiSurvey(false);
      setSavinngsPage(false);
      setSurveyNotReady(false);
      setHoaClass("uploadSection");
      setWiFiClass("uploadSection");
      setInstallDateClass("uploadSection");
      setPurchaseUgradeClass("uploadSection");
      setRepButtonClass("infoSection");
      setPinButtonClass("infoSection");
      setShowWallet(false);
    } else {
      return null;
    }
  };

  const toggleMessageTimer = () => {
    setTimeout(() => {
      setMessage(!showMessage);
    }, 100);
  };

  const toggleSurveyNotReady = () => {
    setSurveyNotReady(!surveyNotReady);
    setInstallDateClass(
      installDateClass == "uploadSection"
        ? "selectedUploadSection"
        : "uploadSection"
    );
  };

  const togglePayment = () => {
    setShowPayment(!showPayment);
    setShowPaymentClass(
      showPaymentClass == "upgradeSection"
        ? "upgradeSection selectedUpgradeSection"
        : "upgradeSection"
    );
    setReferAFriendButtonClass("infoSection");
    setRepButtonClass("infoSection");
    setPinButtonClass("infoSection");
    setWebsiteClass("infoSection");
    setHoaClass("uploadSection");
    setWiFiClass("uploadSection");
    setInstallDateClass("uploadSection");
    setUploadInsuranceClass("uploadSection");
    setPurchaseUgradeClass("uploadSection");
    setIsRepWebView(false);
    setShowHelp(false);
    setIsReferWebView(false);
    setIsWebsiteWebView(false);
    setSystemsPage(false);
    setEnergyProductionPage(false);
    props.setShowHOASurvey(false);
    props.setShowWiFiSurvey(false);
    setSurveyNotReady(false);
    props.setShowInstallDateSurvey(false);
    setSavinngsPage(false);
    setNavViewDollarSign(EverlightMoneyBagGray);
    setNavViewReferClass("rightBorder");
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewWebIcon(EverlightLogo);
    setNavViewRepClass("borderRadius15");
    setNavViewPinClass("none");
    setNavViewWebClass("none");
    setNavViewEnergyClass("none");
    setNavViewMonitor(MonitorGray);
    setNavViewPanel(PanelGray);
    setNavViewSystemsClass("none");
    setEnergyMonitorClass("monitors");
    setEnergySystemClass("solarSystem");
    setShowWallet(false);
  };

  if (showHelp) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  if (showMessage) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  if (surveyNotReady) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  var energyMonitorContent = (
    <div className="energyMonitorSection">
      <div className="energyTitle">Energy System & Monitoring</div>

      <div className="energyMonitorHeader">
        <div className="energyMonitorAddressSection">
          <img
            src={
              props.sfData.currentDesign3DRenderUrl
                ? props.sfData.currentDesign3DRenderUrl
                : EverlightLogo
            }
            className="whiteBackgroundWithRadius"
            alt="map"
          />
          <div>
            <div>{props.sfData.projectAddress}</div>
            <div>System Size: {props.sfData.projectDCsystemSize}</div>
          </div>
        </div>
        <img src={EverlightLogo} loading='lazy' />
      </div>

      <div className="energyGraphHeader">
        <div>{energyGraphTitle}</div>
        <div className="energyButtons">
          <button
            onClick={toggleEnergyUsage}
            className="boltButton"
            data-testid="energyUsageButton"
          >
            <img src={boltLogo} className="boltLogo" loading='lazy' />
            <div className={usage}>Usage</div>
          </button>
          <button
            onClick={toggleEnergyUsage}
            className="prodButton"
            data-testid="energyProductionButton"
          >
            <img src={productionLogo} className="prodLogo" loading='lazy' />
            <div className={production}>Production</div>
          </button>
        </div>
      </div>

      <div
        className={!energyUsage ? "energyGraphSection" : "energyGraphSection2"}
        style={!energyUsage ? { position: "relative", bottom: "85px" } : null}
      >
        {energyUsage ? (
          <EnergyUsagePage
            accountId={props.accountId}
            sfData={props.sfData}
            updateSFdata={props.updateSFdata}
            showOverlay={props.showOverlay}
            manufactureId={props.manufactureId}
            empData={props.empData}
            empDataReady={props.empDataReady}
            empDefault={props.empDefault}
            timeWindow={props.timeWindow}
            setTimeWindow={props.setTimeWindow}
            alertData={props.alertData}
            showInstallDateSurvey={props.showInstallDateSurvey}
            setShowInstallDateSurvey={props.setShowInstallDateSurvey}
            showHOASurvey={props.showHOASurvey}
            setShowHOASurvey={props.setShowHOASurvey}
            chartsAreUpdating={props.chartsAreUpdating}
            empDataFlat={props.empDataFlat}
          />
        ) : (
          <EnergyProductionPage
            alertData={props.alertData}
            sfData={props.sfData}
            showInstallDateSurvey={props.showInstallDateSurvey}
            setShowInstallDateSurvey={props.setShowInstallDateSurvey}
            showHOASurvey={props.showHOASurvey}
            setShowHOASurvey={props.setShowHOASurvey}
            updateSFdata={props.updateSFdata}
            showOverlay={props.showOverlay}
            apData={props.apData}
            apDataYear={props.apDataYear}
            apDataTrimmed={props.apDataTrimmed}
            apDefault={props.apDefault}
            apBlank={props.apBlank}
            apCallFailure={props.apCallFailure}
            timeWindowAP={props.timeWindowAP}
            setTimeWindowAP={props.setTimeWindowAP}
            apSystemsYearOffset={props.apSystemsYearOffset}
            setApSystemsYearOffset={props.setApSystemsYearOffset}
            apOptions={props.apOptions}
            ecuId={props.ecuId}
            userIsOnMobilePhone={props.userIsOnMobilePhone}
            chartsAreUpdating={props.chartsAreUpdating}
          />
        )}
      </div>
    </div>
  );
  let nav = useNavigate();

  function clearData() {
    props.setAccountId("");
    localStorage.clear();
    nav("/");
    window.location.reload();
  }

  function copyPIN() {
    if (!navigator.clipboard) {
      fallbackCopyPIN();
      return;
    }
    navigator.clipboard.writeText(props.accountId).then(
      function () {},
      function (err) {}
    );
    setShowHelp(!showHelp);
    setPinButtonClass("infoSection");
    setNavViewPadLock(EverlightPadLockGray);
    setNavViewPinClass("none");
  }

  function toggleAddressSwitcher() {
    props.setShowAddressSwitcher(!props.showAddressSwitcher);
  }

  function composeAddressSwitcher() {
    let selectedAddress = props.selectedAddress;
    return props.sfDataAll.map((opportunity, index) => (
      <>
        <div
          className="infoSection"
          onClick={() => {
            props.setSelectedAddress(index);
            setTimeout(() => {
              toggleAddressSwitcher();
            }, 500);
          }}
          style={{ cursor: "pointer" }}
        >
          {index == props.selectedAddress ? (
            <div className="infoSectionIcon">
              <img src={CheckBlue} className="infoSectionIconCheck" loading='lazy' />
            </div>
          ) : (
            <div className="infoSectionIcon"></div>
          )}
          <div className="textInfo">
            <div className="textInfoGroup">
              <h4
                className={
                  index == props.selectedAddress ? "selectedAddress" : null
                }
              >
                {opportunity.projectAddress} -{" "}
                {props.sfDataAll[index].projectDCsystemSize}
              </h4>
            </div>
          </div>
        </div>
        {index < props.sfDataAll.length - 1 ? (
          <div className="infoSectionDivider"></div>
        ) : null}
      </>
    ));
  }

  const [buttonInstallText, setButtonInstallText] = useState("");
  const [buttonInstallClass, setButtonInstallClass] =
    useState("gray-bg leftText");
  const [buttonInstallCheck, setButtonInstallCheck] = useState({
    src: UploadIcon,
    class: "uploadArrowIcon",
  });

  useEffect(() => {
    // TODO - let's not do this anymore LOL
    if (props.sfData.id) {
      // console.log("- - - - - useEffect 30")
      let sfv3copy = { ...props.sfData };
      if (props.completedInstallSurvey && !props.sfData.installReturnDate) {
        sfv3copy.customerHasCompletedInstallSurvey = true;
        sfv3copy.customerConfirmedInstallDate = true;
      }
      if (props.completedReturnInstallSurvey) {
        sfv3copy.customerHasCompletedInstallSurvey = true;
        sfv3copy.customerConfirmedReturnInstallDate = true;
      }
      if (props.rejectedInstallSurvey) {
        sfv3copy.customerHasCompletedInstallSurvey = true;
        sfv3copy.customerConfirmedInstallDate = false;
      }
      if (props.rejectedReturnInstallSurvey) {
        sfv3copy.customerHasCompletedInstallSurvey = true;
        sfv3copy.customerConfirmedReturnInstallDate = false;
      }
      props.setSfData(sfv3copy);
    }
  }, [
    props.completedInstallSurvey,
    props.rejectedInstallSurvey,
    props.completedReturnInstallSurvey,
    props.rejectedReturnInstallSurvey,
  ]);

  useEffect(() => {
    // console.log("- - - - - useEffect 31 - update install date button state")
    if (props.sfData.id) updateInstallButton();
  }, [props.sfData]);

  function updateInstallButton() {
    if (props.sfData.customerHasCompletedInstallSurvey) {
      if (props.sfData.installReturnDate == null) {
        if (props.sfData.customerConfirmedInstallDate) {
          setButtonInstallText("Install Date Survey Completed");
          setButtonInstallClass("leftText");
          setButtonInstallCheck({
            src: CloudCheck,
            class: "cloudCheckMark",
          });
        } else {
          setButtonInstallText({
            text: "Thanks, we'll reschedule soon",
            textClass: "blueText",
          });
          setButtonInstallClass(" leftText");
          setButtonInstallCheck({
            src: CloudCheckGray,
            class: "cloudCheckMark",
          });
        }
      } else {
        if (!!props.sfData.customerConfirmedReturnInstallDate) {
          setButtonInstallText("Install Return Date Survey Completed");
          setButtonInstallClass("leftText");
          setButtonInstallCheck({
            src: CloudCheck,
            class: "cloudCheckMark",
          });
        } else {
          setButtonInstallText(
            "Return Survey Submitted: We'll propose a new date soon"
          );
          setButtonInstallClass("leftText");
          setButtonInstallCheck({
            src: CheckBlue,
            class: "checkMark",
          });
        }
      }
    } else {
      if (!props.sfData.installationScheduleDate) {
        setButtonInstallClass("leftText");
        setButtonInstallText("Take Install Date Survey");
        setButtonInstallCheck({
          src: UploadIcon,
          class: "uploadArrowIcon2",
        });
      } else {
        setButtonInstallCheck({
          src: UploadIcon,
          class: "uploadArrowIcon2",
        });

        setButtonInstallClass(" uploadArrowIcon2");

        if (!props.sfData.installReturnDate) {
          setButtonInstallText("Take Install Date Survey");
        } else {
          setButtonInstallText("Take Return Install Date Survey");
        }
      }
    }
  }

  function fallbackCopyPIN() {
    var input = document.createElement("input");
    input.setAttribute("value", props.accountId);
    document.body.appendChild(input);
    input.focus();
    input.select();
    try {
      document.execCommand("copy");
    } catch (e) {}
    document.body.removeChild(input);
    setShowHelp(!showHelp);
  }

  const formatDate = (date) => {
    const jsDate = new Date(date);

    return (
      jsDate.toLocaleString("en-US", {
        timeStyle: "short",
        timeZone: "America/Chicago",
      }) +
      ", " +
      jsDate.toLocaleString("en-US", {
        dateStyle: "medium",
        timeZone: "America/Chicago",
      })
    );
  };
  const [popupContent, setPopupContent] = useState(null);
  const [popupStatus, setPopupStatus] = useState("idle");

  useEffect(() => {
    const fetchPopupContent = async () => {
      setPopupStatus("loading");
      try {
        const apiToken = "e225be30-27a5-44fd-993d-94de54723ca0";
        const { data } = await axios.get(
          `https://coda.io/apis/v1/docs/3o5aXw71UZ/tables/grid-sync-1054-Table-dynamic-aece30de540f07969758bf9fa594150647c7e919925435059df6b4a8e3db9d80/rows`,
          {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const rows = data.items;
        const formattedRows = rows.map((row) => ({
          id: row.id,
          index: row.index,
          renderPopup: row.values["c-YNA6PVS-BY"],
          marketingLink: row.values["c-pWIjF-syjl"],
          updatedDate: row.values["c-woueFW9rQw"],
          currentAndroidVersion: row.values["c-m-V6oP2ICX"],
        }));
        formattedRows.sort((a, b) => a.index - b.index);

        const mobileRows = formattedRows.filter(
          (row) =>
            row.id ===
            "i-6c9ca3ea9f67769d90d1daca604f7eb536a33d2e60a5618a9b45377071128579"
        );
        const wideRows = formattedRows.filter(
          (row) =>
            row.id ===
            "i-132b55d12ba4ee7d31c587ce8c2187a7f908370732e060fd3d8b8aa4dce1ff79"
        );
        const isAndroidTablet = () => {
          const userAgent = navigator.userAgent.toLowerCase();
          const isAndroid = /android/.test(userAgent);
          const isTablet = !/mobile/.test(userAgent);
          const minTabletWidth = 600;
          const isTabletSize = window.screen.width >= minTabletWidth;
          return isAndroid && isTablet && isTabletSize;
        };

        const contentToSet =
          isAndroid() && !isAndroidTablet() ? mobileRows : wideRows;
        setPopupContent(contentToSet);
        setPopupStatus("success");
      } catch (error) {
        setPopupStatus("error");
        console.error("Error fetching popup content:", error);
      }
    };

    fetchPopupContent();
  }, []);

  function compareVersions(currentVersion, newVersion) {
    if (!currentVersion || !newVersion) {
      console.error("One of the versions is undefined", {
        currentVersion,
        newVersion,
      });
      return false;
    }

    const v1 = currentVersion.split(".").map(Number);
    const v2 = newVersion.split(".").map(Number);

    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;
      if (num1 < num2) return true;
      if (num1 > num2) return false;
    }
    return false;
  }

  function isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  const shouldUpgrade =
    popupContent && popupContent[0]?.currentAndroidVersion
      ? compareVersions(
          Constants.currentAppVersion,
          popupContent[0]?.currentAndroidVersion
        )
      : false;

  const showMarketingPage = popupContent ? popupContent[0]?.renderPopup : false;

  const lastSeenDate = localStorage.getItem("marketingPopupSeenDate");
  const updatedDate = popupContent ? popupContent[0]?.updatedDate : null;
  const showMarketingPopup =
    showMarketingPage &&
    (!lastSeenDate || new Date(updatedDate) > new Date(lastSeenDate)) &&
    !shouldUpgrade;

  const showUpgradePopup =
    shouldUpgrade && !sessionStorage.getItem("upgradePopupShown");

  const contactID = props.sfData.primaryCustomerContactID;

  const storeReferralsData = (
    contactID,
    ecFirstName,

    ecLastName,
    ecImageURL,
    twentyFiveYearSavings
  ) => {
    if (contactID) {
      localStorage.setItem("contactID", contactID);
    }
    if (ecFirstName) {
      localStorage.setItem("ecFirstName", ecFirstName);
    }
    if (ecLastName) {
      localStorage.setItem("ecLastName", ecLastName);
    }
    if (ecImageURL) {
      localStorage.setItem("ecImageURL", ecImageURL);
    }
    if (twentyFiveYearSavings) {
      localStorage.setItem("25Year", twentyFiveYearSavings);
    }
  };

  useEffect(() => {
    const sfData = props.sfData;
    const sfDataAll = props.sfDataAll;
    const contactID = sfData.primaryCustomerContactID;
    const ecFirstName = sfData.energyConsultantFirstName;
    const ecLastName = sfData.energyConsultantLastName;
    const ecImageURL = sfData.energyConsultantImageURL;

    let twentyFiveYearSavings;
    if (sfDataAll.length > 1) {
      twentyFiveYearSavings = sfDataAll.reduce(
        (sum, item) => sum + Number(item.twentyFiveYearSavings),
        0
      );
    } else {
      twentyFiveYearSavings = sfData.twentyFiveYearSavings;
    }

    storeReferralsData(
      contactID,
      ecFirstName,
      ecLastName,
      ecImageURL,
      twentyFiveYearSavings
    );
  }, [props.sfData, props.sfDataAll]);

  return (
    <>
      {props.showInstallDateSurvey ? (
        <InstallDateSurvey
          installData={props.installData}
          setShowInstallDateSurvey={props.setShowInstallDateSurvey}
          accountIdv3={props.accountIdv3}
          completedInstallSurvey={props.completedInstallSurvey}
          setCompletedInstallSurvey={props.setCompletedInstallSurvey}
          rejectedInstallSurvey={props.rejectedInstallSurvey}
          setRejectedInstallSurvey={props.setRejectedInstallSurvey}
          completedReturnInstallSurvey={props.completedReturnInstallSurvey}
          setCompletedReturnInstallSurvey={
            props.setCompletedReturnInstallSurvey
          }
          rejectedReturnInstallSurvey={props.rejectedReturnInstallSurvey}
          setRejectedReturnInstallSurvey={props.setRejectedReturnInstallSurvey}
          setInstallSurveyReady={props.setInstallSurveyReady}
          updateSFdata={props.updateSFdata}
        />
      ) : null}
      {props.showHOASurvey && (
        <>
          <HOASurveyForm
            setShowHOASurvey={props.setShowHOASurvey}
            accountId={props.accountId}
            accountIdv3={props.accountIdv3}
            completedHOA={props.completedHOA}
            setCompletedHOA={props.setCompletedHOA}
            sfData={props.sfData}
            userIsOnMobilePhone={props.userIsOnMobilePhone}
          />
          <div className="account-page-blur-panel"></div>
        </>
      )}
      {props.showWiFiSurvey && (
        <>
          <WiFiSurvey
            setShowWiFiSurvey={props.setShowWiFiSurvey}
            accountId={props.accountId}
            completedWiFi={props.completedWiFi}
            setCompletedWiFi={props.setCompletedWiFi}
            sfData={props.sfData}
            setWiFiClass={setWiFiClass}
            updateSFdata={props.updateSFdata}
            userIsOnMobilePhone={props.userIsOnMobilePhone}
          />
          <div className="account-page-blur-panel"></div>
        </>
      )}
      {props.alertData.text ? (
        <AlertBanner
          alertData={props.alertData}
          setShowHOASurvey={props.setShowHOASurvey}
          setShowInstallDateSurvey={props.setShowInstallDateSurvey}
        />
      ) : null}
      {isWebView && (
        <>
          {showWallet && <Overlay onClick={handleRewardsWallet} />}
          {showWallet && contactID && (
            <WalletContainer>
              <Wallet contactID={contactID} />
            </WalletContainer>
          )}
          <div
            className={
              props.userIsOnMobilePhone
                ? "accountPageWrap box-center fontFix onMobile"
                : "accountPageWrap box-center fontFix notOnMobile"
            }
            style={{ overflowX: "hidden" }}
          >
            {popupStatus === "success" && (
              <>
                {showUpgradePopup && isAndroid() && (
                  <UpgradePopup
                    appStoreVersion={popupContent[0]?.currentAndroidVersion}
                  />
                )}
                {showMarketingPopup && (
                  <MarketingPopup
                    marketingURL={popupContent[0]?.marketingLink}
                    updatedDate={popupContent[0]?.updatedDate}
                  />
                )}
              </>
            )}

            <div className="margin15px"></div>
            {windowWidth > 600 && !props.userIsOnMobilePhone ? (
              <>
                <div className="collapseMenu" onClick={handleWebView}>
                  <div>
                    <img src={arrowToLeft} loading='lazy' />
                    <div>Collapse Menu</div>
                  </div>
                </div>

                <div className="accountNameSection">
                  <h2 className="accountLastName">
                    {props.sfData.accountLastName}
                  </h2>
                  <p className="lineHeight30 grayText customerContact">
                    {props.sfData.primaryCustomerContact}
                    <br />
                    {props.sfData.nonPrimaryCustomerContact}
                  </p>
                </div>

                <div className="monitorSection">
                  <div
                    className={energyMonitorClass}
                    onClick={handleEnergyPage}
                  >
                    <div className="monitorBtn">
                      <img src={Monitor} loading='lazy' />
                      <div>My Energy Monitors</div>
                    </div>
                    <div className="monitorSectionChevron">
                      <img src={iPhoneRightArrow} loading='lazy'/>
                    </div>
                  </div>
                  <div
                    className={energySystemClass}
                    onClick={handleSystemsPage}
                    data-testid="solarSystemAccountPageButton"
                  >
                    <div className="monitorBtn">
                      <img src={Panel} loading='lazy'/>
                      <div>
                        {props.sfData.commissionedDate == null
                          ? "My Everlight Project Tracker"
                          : "My Everlight Solar System"}
                      </div>
                    </div>
                    <div className="monitorSectionChevron">
                      <img src={iPhoneRightArrow} loading='lazy'/>
                    </div>
                  </div>
                  <div className={walletClass} onClick={handleRewardsWallet}>
                    <div className="monitorBtn">
                      <img src={WalletGreen} loading='lazy'/>
                      <div>My Rewards Wallet</div>
                    </div>
                    <div className="monitorSectionChevron">
                      <img src={iPhoneRightArrow} loading='lazy'/>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="accountNameSection">
                <h2 className="accountLastName">
                  {props.sfData.accountLastName}
                </h2>
                <p className="lineHeight30 grayText customerContact">
                  {props.sfData.primaryCustomerContact}
                  <br />
                  {props.sfData.nonPrimaryCustomerContact}
                </p>
              </div>
            )}
            <div className="quickActions">
              <div
                className={pinButtonClass}
                onClick={togglePopup}
                data-testid="pinModalButton"
              >
                <div className="infoSectionIcon">
                  <div className="gradientOnly padLockG"></div>
                </div>
                <div className="textInfo">
                  <div className="textInfoGroup">
                    <h4>Your Everlight PIN</h4>
                  </div>
                </div>
                <div className="infoSectionChevron">
                  <img src={iPhoneRightArrow} loading='lazy' />
                </div>
              </div>

              <div className="infoSectionDivider"></div>

              <div
                className={repButtonClass}
                onClick={
                  windowWidth > 600 && !props.userIsOnMobilePhone
                    ? handleRepWebView
                    : () => {
                        // window.open('/rep', "_self") funny - reopens the app
                        nav("/rep");
                      }
                }
                data-testid="repModalButton"
              >
                <div className="infoSectionIcon">
                  <img
                    src={
                      props.sfData.customerSuccessRepImageURL !==
                      "/file-asset/FINALSupportREpOVal?v=1"
                        ? props.sfData.customerSuccessRepImageURL
                        : customerSuccessRep
                    }
                    loading='lazy'
                    className="csrPic"
                  />
                </div>
                <div className="textInfo">
                  <div className="textInfoGroup">
                    <h4>Help & Support</h4>
                  </div>
                </div>

                <div className="infoSectionChevron">
                  <img src={iPhoneRightArrow} loading='lazy'/>
                </div>
              </div>

              <div className="infoSectionDivider"></div>

              <div className="infoSectionIcon">
                {windowWidth > 600 && !props.userIsOnMobilePhone ? (
                  <div className={showPaymentClass}>
                    <button className="infoSection" onClick={togglePayment}>
                      <div className="infoSectionIcon">
                        <StyledRocket src={RocketIcon} alt="rocket" loading='lazy'/>
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Purchase an Upgrade</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </button>
                  </div>
                ) : (
                  <div
                    className="infoSection"
                    onClick={() => {
                      window.open(
                        "https://everlightsolar.com/customer-support/"
                      );
                    }}
                  >
                    <div className="infoSectionIcon">
                      <StyledRocket src={RocketIcon} alt="rocket" />
                    </div>
                    <div className="textInfo">
                      <div className="textInfoGroup">
                        <h4>Info About Upgrades</h4>
                      </div>
                    </div>
                    <div className="infoSectionChevron">
                      <img src={iPhoneRightArrow} loading='lazy'/>
                    </div>
                  </div>
                )}
              </div>

              <div className="infoSectionDivider"></div>

              <div
                className={websiteClass}
                onClick={
                  windowWidth > 600 && !props.userIsOnMobilePhone
                    ? handleWebsiteWebView
                    : () => {
                        window.open("https://www.everlightsolar.com/");
                      }
                }
              >
                <div className="infoSectionIcon">
                  <div className="gradientOnly everlightLogoG"></div>
                </div>
                <div className="textInfo">
                  <div className="textInfoGroup">
                    <h4>Our Website</h4>
                  </div>
                </div>
                <div className="infoSectionChevron">
                  <img src={iPhoneRightArrow} loading='lazy'/>
                </div>
              </div>
            </div>
            <div className="appSelectionsTitle">
              <br />
              <h4>
                {props.showAddressSwitcher
                  ? "Please choose a system / address:"
                  : "Your Everlight Solar App Selections"}
              </h4>
            </div>
            <div className="oppInfoSection">
              {props.showAddressSwitcher ? (
                <>{composeAddressSwitcher()}</>
              ) : (
                <>
                  <div
                    className="infoSection"
                    onClick={
                      props.sfDataAll.length > 1 ? toggleAddressSwitcher : null
                    }
                    style={
                      props.sfDataAll.length > 1 ? { cursor: "pointer" } : {}
                    }
                  >
                    <div className="infoSectionIcon">
                      <img src={House} className="infoSectionIconBigger" loading='lazy'/>
                    </div>
                    <div className="textInfo">
                      <div className="textInfoGroup">
                        <h4>Energy Monitoring</h4>
                        <p>
                          {props.sfData.projectAddress} -{" "}
                          {props.sfData.projectDCsystemSize}
                        </p>
                      </div>
                    </div>
                    <div className="infoSectionChevron">
                      {props.sfDataAll.length > 1 ? (
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      ) : null}
                    </div>
                  </div>

                  <div className="infoSectionDivider"></div>

                  <div className="infoSection">
                    <div className="infoSectionIcon">
                      <img
                        src={mappin}
                        loading='lazy'
                        className="infoSectionIconSmaller addedPadding"
                      />
                    </div>
                    <div className="textInfo">
                      <div className="textInfoGroup">
                        <h4>Project Tracker</h4>
                        <p>{props.sfData.customerFacingStageName}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="appSelectionsTitle">
              <br />
              <h4>Project Actions & Uploads</h4>
            </div>
            <div className="quickActionsUpload">
              <button
                className={hoaClass}
                onClick={handleSurveyPopup}
                data-testid="hoaAccSurveyAccountPageButton"
              >
                <div className="infoSection">
                  {props.completedHOA ? (
                    <>
                      <div className="infoSectionIcon">
                        <img src={CloudCheck} loading='lazy'className="greenCloudSmaller" />
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>HOA Survey Completed</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="infoSectionIcon">
                        <img src={UploadIcon} loading='lazy' className="uploadIconSmaller" />
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Take HOA Survey</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </>
                  )}
                </div>
              </button>

              <div className="infoSectionDivider"></div>

              <button
                className={installDateClass}
                onClick={handleInstallDatePopup}
                data-testid="installSurveyAccountPageButton"
              >
                <div className="infoSection">
                  <div className="infoSectionIcon">
                    <img
                      src={buttonInstallCheck.src}
                      loading='lazy'
                      className={buttonInstallCheck.class}
                    />
                  </div>

                  <div className="textInfo">
                    <div
                      className={"textInfoGroup " + buttonInstallText.textClass}
                    >
                      <h4>{buttonInstallText.text || buttonInstallText}</h4>
                    </div>
                  </div>

                  <div className="infoSectionChevron">
                    <img src={iPhoneRightArrow} loading='lazy'/>
                  </div>
                </div>
              </button>

              <div className="infoSectionDivider"></div>

              <button
                className={uploadInsuranceClass}
                onClick={toggleMessage}
                data-testid="uploadInsuranceAccountPageButton"
              >
                <div className="infoSection">
                  {props.completedInsurance ? (
                    <>
                      <div className="infoSectionIcon">
                        <img src={CloudCheck} className="greenCloudSmaller" loading='lazy'/>
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Insurance Upload Completed</h4>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="infoSectionIcon">
                        <img src={UploadIcon} className="uploadIconSmaller" loading='lazy'/>
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Upload Home Insurance</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </>
                  )}
                </div>
              </button>

              <div className="infoSectionDivider"></div>

              <button
                className={wiFiClass}
                onClick={handleWiFiPopup}
                data-testid="wifiSurveyAccountPageButton"
              >
                <div className="infoSection">
                  {props.completedWiFi ? (
                    <>
                      <div className="infoSectionIcon">
                        <img src={WiFiGreen} className="greenCloudSmaller" loading='lazy'/>
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Update Wi-Fi Credentials</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="infoSectionIcon">
                        <img src={WiFiRed} className="uploadIconSmaller" loading='lazy'/>
                      </div>
                      <div className="textInfo">
                        <div className="textInfoGroup">
                          <h4>Update Wi-Fi Credentials</h4>
                        </div>
                      </div>
                      <div className="infoSectionChevron">
                        <img src={iPhoneRightArrow} loading='lazy'/>
                      </div>
                    </>
                  )}
                </div>
              </button>
            </div>
            <div className="margin40px"></div>
            <div className="updatedSafe box-center zIndexBackground">
              <div className="opacity-5 logoBigger">
                <img
                  src={EverlightLogoBackground}
                  loading='lazy'
                  classes="center"
                  width={"100%"}
                />
              </div>
            </div>
            {Capacitor.isNativePlatform() ? null : (
              <button
                className="box-center text-orange-600 clearSavedLogin"
                onClick={() => clearData()}
              >
                Log Out
              </button>
            )}
            <div className="grayText currentVersion">
              Current App Version: {props.currentAppVersion}
            </div>
          </div>
        </>
      )}

      {isNavView && (
        <>
          <div className="webNavSection">
            <div className="expandMenu" onClick={handleWebView}>
              <div>
                <img src={arrowFromRight} loading='lazy'/>
                <div>Expand</div>
              </div>
            </div>
            <div className="webNavBtns">
              <div className={navViewEnergyClass}>
                <img src={navViewMonitor} onClick={handleEnergyPage} loading='lazy'/>
              </div>

              <div className={navViewSystemsClass}>
                <img src={navViewPanel} onClick={handleSystemsPage} loading='lazy'/>
              </div>
              <div className={walletClass}>
                <img src={WalletGreen} onClick={handleRewardsWallet} loading='lazy'/>
              </div>

              <div className={navViewPinClass}>
                <img src={navViewPadlock} onClick={togglePopup} loading='lazy'/>
              </div>

              <div className={navViewRepClass}>
                <img
                  src={
                    props.sfData.customerSuccessRepImageURL !==
                    "/file-asset/FINALSupportREpOVal?v=1"
                      ? props.sfData.customerSuccessRepImageURL
                      : customerSuccessRep
                  }
                  loading='lazy'
                  onClick={handleRepWebView}
                />
              </div>
              <div className={navViewWebClass} id="thirtyByThirty">
                <img src={navViewWebIcon} onClick={handleWebsiteWebView} loading='lazy'/>
              </div>
            </div>
          </div>
          {showWallet && (
            <CollapsedWalletContainer>
              <Wallet contactID={contactID} />
            </CollapsedWalletContainer>
          )}
        </>
      )}

      {showHelp && isNavView && (
        <div className="Popup accountOverlay" id="navView">
          <div className="Popup-content center-text">
            <h4 className="bold">Access Across Devices</h4>
            <br />
            <div>
              1. Download the Everlight Solar App on another device
              <div className="margin10px"></div>
              2. When first launching the app, you'll see a prompt to enter your
              Everlight PIN
              <div className="margin10px"></div>
              3. Enter your Everlight PIN into that box to connect that device
              to your Everlight home energy system
              <div className="margin10px"></div>
              4. Now you can see the same live info in both places!
              <div className="margin10px"></div>
            </div>
            <div className="margin10px"></div>
            <button className="close-popup" onClick={copyPIN}>
              Copy PIN
            </button>
          </div>
          <div className="account-page-blur-panel"></div>
        </div>
      )}

      {isRepWebView && isNavView && (
        <div className="repPageWebView" id="navView">
          <SupportPage
            accountId={props.accountId}
            sfData={props.sfData}
            sfDataAll={props.sfDataAll}
            setSelectedAddress={props.setSelectedAddress}
            selectedAddress={props.selectedAddress}
          />
          <div className="account-page-blur-panel"></div>
        </div>
      )}

      {isReferWebView &&
        isNavView &&
        windowWidth > 600 &&
        !props.userIsOnMobilePhone && (
          <div className="referIFrame" id="navViewiFrame">
            <iframe src="https://www.everlightsolar.com/refer-a-friend/" />
          </div>
        )}

      {isWebsiteWebView &&
        isNavView &&
        windowWidth > 600 &&
        !props.userIsOnMobilePhone && (
          <div className="referIFrame" id="navViewiFrame">
            <iframe src="https://everlightsolar.com" />
          </div>
        )}

      {systemsPage &&
        windowWidth > 600 &&
        !props.userIsOnMobilePhone &&
        (props.sfData.commissionedDate == null ? (
          <div className=" savingsPage">
            <div className="savingsChartSection">
              <div className="circleChart">
                <CircleChart
                  ratio={safeRead(props.sfData.estimatedOffset, 0.95)}
                  userIsOnMobilePhone={props.userIsOnMobilePhone}
                />
              </div>
              <p>
                {props.sfData.estimatedOffset == null
                  ? 95
                  : parseFloat(
                      safeRead(props.sfData.estimatedOffset, 0.95)
                    ).toFixed(2) * 100}
                %
              </p>
            </div>
            <img src={houseDefault} className="savingsHouse" loading='lazy'/>
            <SavingsPage
              sfData={props.sfData}
              updateSFdata={props.updateSFdata}
              showHOASurvey={props.showHOASurvey}
              setShowHOASurvey={props.setShowHOASurvey}
              showInstallDateSurvey={props.showInstallDateSurvey}
              setShowInstallDateSurvey={props.setShowInstallDateSurvey}
              ecuId={props.ecuId}
              accountIdv3={props.accountId}
              completedHOA={props.completedHOA}
              setCompletedHOA={props.setCompletedHOA}
              installSurveyReady={props.installSurveyReady}
              completedInstallSurvey={props.completedInstallSurvey}
              setCompletedInstallSurvey={props.setCompletedInstallSurvey}
              rejectedInstallSurvey={props.rejectedInstallSurvey}
              setRejectedInstallSurvey={props.setRejectedInstallSurvey}
              completedReturnInstallSurvey={props.completedReturnInstallSurvey}
              setCompletedReturnInstallSurvey={
                props.setCompletedReturnInstallSurvey
              }
              rejectedReturnInstallSurvey={props.rejectedReturnInstallSurvey}
              setRejectedReturnInstallSurvey={
                props.setRejectedReturnInstallSurvey
              }
              setInstallSurveyReady={props.setInstallSurveyReady}
              completedInsurance={props.completedInsurance}
              setCompletedInsurance={props.setCompletedInsurance}
              completedNetMetering={props.completedNetMetering}
              setCompletedNetMetering={props.setCompletedNetMetering}
              alertData={props.alertData}
              installData={props.sfData}
              apCallFailure={props.apCallFailure}
              upgradeData={props.upgradeData}
              pendingUpgrades={props.pendingUpgrades}
              completedUpgrades={props.completedUpgrades}
              userIsOnMobilePhone={props.userIsOnMobilePhone}
            />
          </div>
        ) : (
          <SystemPage
            accountIdv3={props.accountIdv3}
            sfData={props.sfData}
            upgradeData={props.upgradeData}
            pendingUpgrades={props.pendingUpgrades}
            completedUpgrades={props.completedUpgrades}
            userIsOnMobilePhone={props.userIsOnMobilePhone}
          />
        ))}

      {energyProductionPage && <div>{energyMonitorContent}</div>}

      {energyProductionPage && isNavView && (
        <div id="navView">{energyMonitorContent}</div>
      )}

      {showHelp && isWebView && (
        <>
          <div className="Popup accountOverlay" id="pinWebView">
            <div className="Popup-content center-text">
              <h4 className="bold">Access Across Devices</h4>

              <br />
              <div>
                1. Download the Everlight Solar App on another device
                <div className="margin10px"></div>
                2. When first launching the app, you'll see a prompt to enter
                your Everlight PIN
                <div className="margin10px"></div>
                3. Enter your Everlight PIN into that box to connect that device
                to your Everlight home energy system
                <div className="margin10px"></div>
                4. Now you can see the same live info in both places!
                <div className="margin10px"></div>
              </div>
              <div className="margin10px"></div>
              {windowWidth > 600 && !props.userIsOnMobilePhone ? (
                <button className="close-popup" onClick={copyPIN}>
                  Copy PIN
                </button>
              ) : (
                <>
                  <button className="needFurtherAssistance">
                    <a
                      href="https://everlightsolar.com/customer-support/"
                      className="blueText"
                      target="_blank"
                    >
                      Further Assistance?
                    </a>
                  </button>
                  <div className="margin30px"></div>
                  <button className="close-popup" onClick={copyPIN}>
                    Copy PIN & Close
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="account-page-blur-panel"></div>
        </>
      )}

      {isRepWebView && isWebView && (
        <div className="repPageWebView">
          <SupportPage
            accountId={props.accountId}
            sfData={props.sfData}
            sfDataAll={props.sfDataAll}
            setSelectedAddress={props.setSelectedAddress}
            selectedAddress={props.selectedAddress}
          />
          <div className="account-page-blur-panel"></div>
        </div>
      )}

      {isReferWebView &&
        isWebView &&
        windowWidth > 600 &&
        !props.userIsOnMobilePhone && (
          <div className="referIFrame">
            <iframe src="https://www.everlightsolar.com/refer-a-friend/" />
          </div>
        )}

      {isWebsiteWebView &&
        isWebView &&
        windowWidth > 600 &&
        !props.userIsOnMobilePhone && (
          <div className="referIFrame">
            <iframe src="https://everlightsolar.com" />
          </div>
        )}

      {surveyNotReady && (
        <>
          <Popup
            text={
              !props.sfData.installationScheduleDate ? (
                <>
                  This Survey will be available when your project is ready for
                  scheduling confirmation.
                </>
              ) : (
                <>
                  <h1 className="installDateSurveyConfirmation">
                    Your install date is confirmed for{" "}
                    {props.sfData.installationScheduleDateText ||
                      props.sfData.installReturnDateText}
                  </h1>
                  <div className="installDateSurveyPopup">
                    If you have any concerns or needs, please reach out to your
                    dedicated customer success representative
                  </div>
                </>
              )
            }
            buttonText={"OK"}
            parentDivClass={"Popup overlay InstallPopup"}
            secondDivClass={"Popup-content center-text InstallDateSurvey"}
            visible={toggleSurveyNotReady}
          />
          <div className="account-page-blur-panel"></div>
        </>
      )}

      {showMessage && (
        <>
          <div className="Popup overlay uploadPopup">
            <div className="Popup-content center-text">
              <h4 className="bold">Important!</h4>
              <p>
                Your utility company requires proof of insurance on your home to
                satisfy their interconnection requirements.
              </p>
              <div className="margin20px"></div>
              <div className="uploadButtonOverlay">
                <div className="verticalDivide"></div>
                <button
                  className="blueText"
                  onClick={toggleMessage}
                  data-testid="account-page-insurance-cancel-button"
                >
                  Cancel
                </button>
                <button onChange={toggleMessageTimer}>
                  <UploadButton
                    text={"Continue"}
                    sfData={props.sfData}
                    accountId={props.accountId}
                    setCompletedInsurance={props.setCompletedInsurance}
                    showFailurePopup={showFileUploadFailurePopup}
                    updateSFdata={props.updateSFdata}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="account-page-blur-panel"></div>
        </>
      )}

      {showPayment && isWebView && (
        <div className="referIFrame">
          <iframe src="https://everlightsolar.com/customer-support/" />
        </div>
      )}

      {showPayment && isNavView && (
        <div className="referIFrame" id="navViewiFrame">
          <iframe src="https://everlightsolar.com/customer-support/" />
        </div>
      )}

      {fileUploadFailurePopup && (
        <Popup
          text={
            <>
              <h4 className="redText">File Size Limit Exceeded</h4>
              <br />
              Please upload a file smaller than 30mb and try again.
            </>
          }
          classText={"Popup-content center-text"}
          buttonText={"OK"}
          visible={() => {
            showFileUploadFailurePopup(false);
          }}
        />
      )}
    </>
  );
}

export default AccountPage;
