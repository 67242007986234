import React from "react";
import styled from "styled-components";
import { colors } from "./colors";
const XCircleIcon = require("../../src/images/icons/x-circle.png");

const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  left: 0px;
  top: 0px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalContent = styled.div`
  position: relative;
  background: white;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: ${colors.primary};
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
`;
const XCircle = styled.img``;

export default function Modal({ isOpen, onClose, children }) {
  return (
    <>
      {isOpen ? (
        <ModalOverlay>
          <ModalContent>
            <CloseButton onClick={onClose}>
              <XCircle src={XCircleIcon} alt="X Circle" loading='lazy'/>
            </CloseButton>
            {children}
          </ModalContent>
        </ModalOverlay>
      ) : null}
    </>
  );
}
