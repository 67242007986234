
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const Container = styled.div`
  background: linear-gradient(to bottom right, #87ceeb, #32cd32);
  padding: 20px;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
`;

const StatusFilterView = styled.div`
  margin-top: 20px;
  padding: 10px;
`;

const FilterLabel = styled.p`
  font-size: 1em;
  font-weight: bold;
`;

const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const FilterButton = styled.button`
  padding: 4px 8px;
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor || "#808080"};
`;

const RewardsList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 75vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-bottom:150px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RewardCard = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: ${({ delay }) => `${delay}s`};
`;

const RewardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RewardType = styled.p`
  font-size: 1.2em;
  font-weight: bold;
`;

const RewardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const RefereeName = styled.p`
  font-size: 1em;
`;

const RewardStatus = styled.p`
  padding: 4px 8px;
  border-radius: 12px;
  color: white;
  background-color: ${({ backgroundColor }) => backgroundColor || "#808080"};
  align-self: flex-start;
  justify-self: flex-start;
`;

const RewardDescription = styled.p`
  font-size: 1em;
  color: gray;
  margin-top: 8px;
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid green;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 50px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const NoRewardsButton =
  styled.button
  `
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const Message = styled.p`
  font-size: 1.2em;
  text-align: center;
  margin-top: 5px;
`;
const MessageHeader = styled.p`
  font-size: 2.2em;
  text-align: center;
  margin-top: 50px;
`;

export default function RewardsView({referLink}) {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [rewardsData, setRewardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const USER_PIN = localStorage.getItem("EverlightPIN");
  const API_PIN =
    USER_PIN && USER_PIN.startsWith("CS-") ? USER_PIN.substring(3) : USER_PIN;
  const API_URL = `https://damp-ridge-21682.herokuapp.com/react-referral/referrals`;
  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            pin: API_PIN,
          },
        });
        const dataWithDelay = response.data.map((reward, index) => ({
          ...reward,
          delay: 0.1 * index,
          referralStatus:
              reward.referralStatus === "Payment Sent"
                ? "On the way"
                : reward.referralStatus === "Payment Received"
                ? "Paid"
                : reward.referralStatus,
        }));
        setRewardsData(dataWithDelay);
      } catch (error) {
        console.error("Error fetching rewards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRewards();
  }, [API_PIN, API_URL]);

  const filteredRewards = selectedStatus
    ? rewardsData.filter((reward) => reward.referralStatus === selectedStatus)
    : rewardsData;

  const statusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#90EE90";
      case "On the way":
        return "#0000FF";
      case "Paid":
        return "#008000";
      case "Cancelled":
        return "#FF0000";
      default:
        return "#808080";
    }
  };

  return (
    <Container>
      <Title>Your Earnings</Title>
      <StatusFilterView>
        <FilterLabel>Filter by:</FilterLabel>
        <FilterButtons>
          {["Pending", "On the way", "Paid", "Cancelled"].map((status) => (
            <FilterButton
              key={status}
              backgroundColor={statusColor(status)}
              onClick={() =>
                setSelectedStatus(selectedStatus === status ? null : status)
              }
            >
              {status}
            </FilterButton>
          ))}
          {selectedStatus && (
            <FilterButton
              backgroundColor={statusColor("")}
              onClick={() => setSelectedStatus(null)}
            >
              Clear Filter
            </FilterButton>
          )}
        </FilterButtons>
      </StatusFilterView>
      {loading ? (
        <Spinner />
      ) : filteredRewards.length === 0 ? (
        selectedStatus ? (
          <Message>No {selectedStatus?.toLowerCase()} rewards found</Message>
        ) : (
          <NoRewardsButton onClick={()=>referLink()}>
            <MessageHeader>No Rewards Yet!</MessageHeader>
            <Message>Refer a friend and start earning today!</Message>
          </NoRewardsButton>
        )
      ) : (
        <RewardsList>
          {filteredRewards.map((reward) => (
            <RewardCard key={reward.accountId} delay={reward.delay}>
              <RewardHeader>
                <RewardType>{reward.rewardType || "N/A"}</RewardType>
              </RewardHeader>
              <RewardDetails>
                <RefereeName>
                  {reward.referredContact.firstName}{" "}
                  {reward.referredContact.lastName}
                </RefereeName>
                <RewardStatus
                  backgroundColor={statusColor(reward.referralStatus)}
                >
                  {reward.referralStatus}
                </RewardStatus>
              </RewardDetails>
              <RewardDescription>
                {reward.rewardAmount !== "0"
                  ? `Reward Amount: $${reward.rewardAmount}`
                  : "No reward amount specified."}
              </RewardDescription>
            </RewardCard>
          ))}
        </RewardsList>
      )}
    </Container>
  );
}