import styled from 'styled-components';
import Panel from './images/EverlightPanel.svg';
import CritterGuard from './images/CritterGuard.png'
import PowerGenerator from './images/PowerGenerator.png'
import Battery from './images/TeslaBattery.png'
import TeslaWallCharger from './images/TeslaWallCharger.png'
import { useEffect, useState } from 'react';

const Container = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;
  background: #9AC687;
  border-radius: 1.5rem;
  z-index: 2;
  width: fit-content;
  margin-top: 10%;

  img {
    width: 6.5rem;
    margin: 1.25rem 0rem 1rem 0rem;
    align-self: center;
  }

  @media only screen and (max-width: 390px){
    width: 95vw;
  }
`

const Main = styled.main`
  display: flex;
  flex-flow: column nowrap;
  padding: .5rem 0 .5rem .75rem;

  h2 {
    font-size: 1.75rem;
    padding: .25rem 1rem 0 0;
  }
  p {
    padding: .25rem 1rem .25rem 0;
    text-align: left;
  }
  @media only screen and (max-width: 390px){
    h2{
      padding: 0;
      font-size: 1.6rem;
      text-align: left;
    } 
  }
`

const LineSeparator = styled.div`
  background-color: rgb(118, 161,109);
  height: 1px;
`

const Address = styled.p`
  color: inherit;
`

const Description = styled.p`
  color: inherit;
`

const Status = styled.p`
  color: var(--pullTabLink);
  font-weight: 500;
`

const UpgradeTracker = (props) => {

  const [upgradeIcon, setUpgradeIcon] = useState()

  useEffect(() => {
    // console.log("- - - - - useEffect 27")
    const upgradeType = props.pendingUpgrades[0].Upgrade_Type_Picklist__c.split(';')[0];
  
    switch (upgradeType) {
      case 'Simple Upgrade':
        setUpgradeIcon(CritterGuard);
        break;
      case 'Panels+':
        setUpgradeIcon(Panel);
        break;
      case 'Battery+':
        setUpgradeIcon(Battery);
        break;
      case 'Generator+':
        setUpgradeIcon(PowerGenerator);
        break;
      case 'Wall Connector+':
        setUpgradeIcon(TeslaWallCharger);
        break;
      default:
        setUpgradeIcon(Panel)
        break;
    }
  }, []);
  return (
    <Container>
      <img src={upgradeIcon} loading='lazy' alt='solar panel' />
      <Main>
        <h2>Upgrade on the way</h2>
        <Address>{props.sfData.projectAddress}</Address>
        <LineSeparator />
        <Description>{props.pendingUpgrades[0].Upgrade_Type_Picklist__c.split(';')[0]}</Description>
        <LineSeparator />
        <Status>Status: {props.pendingUpgrades[0].StageName}</Status>
      </Main>
    </Container>
  )
}

export default UpgradeTracker